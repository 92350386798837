import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainModule } from '../main/main.module';
import { AbmRoutingModule } from './abm-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MainModule,
    AbmRoutingModule
  ]
})
export class AbmModule {
}
