import { Component, OnInit, ViewChild } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../../../shared/simple-logger.shared';
import { NgForm } from '@angular/forms';
import { BaseAppError } from "../../../../services/errors/base-app-error";
import { ModalsService } from "../../../../services/modals/modals.service";
import { UsersService } from "../../../../services/users/users.service";
import { PhoneValidatedModel } from '../../../../models/phone-validated.model';
import { Router } from "@angular/router";
import { ErrorsService } from "../../../../services/errors/errors.service";
import { ViewportScroller } from "@angular/common";
import { HelpersService } from "../../../../services/helpers/helpers.service";
import { SessionService } from "../../../../services/session/session.service";
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PhoneVerifyModalComponent } from '../../../main/components/phone-verify-modal/phone-verify-modal.component';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'register-page.component.ts',
  className: 'RegisterPageComponent',
  tagName: 'PAGES'
});
_LOGGER.debugVerbose('Loaded.');

/* Function facebook fbq*/
declare let fbq:Function;
/* var function Google*/
declare let gtag;

const _HOME_PAGE_URL = '/home',
  _LOGIN_PAGE_URL = '/login',
  _SESSION_TAG_REDIRECT_AFTER_REGISTER = 'redirectAfterRegister',
  _METHOD_START_MSG = 'Method start.';


/**
 * Register (SingUp) Page
 */
@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {
  /** Form controls. */
  @ViewChild('signUpForm') protected signUpForm: NgForm;

  /** Form data. */
  protected disableForm: boolean;
  protected userName: string;
  protected userSurname: string;
  protected userEmail: string;
  protected userRepeatEmail: string;
  protected userCellphone: string;
  protected userPassword: string;
  protected userRepeatPassword: string;

  protected redirectAfterRegister: string;

  constructor(
    private modalsService: ModalsService,
    private usersService: UsersService,
    private router: Router,
    private errorsService: ErrorsService,
    private viewportScroller: ViewportScroller,
    private helpersService: HelpersService,
    private sessionService: SessionService
  ) {
  }


  /* Presentation logic. */

  /**
   * Initializes form data.
   */
  private formInit(): void {
    this.disableForm = true;

    // No init needed (simple form).

    this.formReset();
  }

  /**
   * Resets the form.
   */
  private formReset(): void {
    if (this.signUpForm && this.signUpForm.pristine) {
      this.signUpForm.control.reset();
    }

    this.userName = null;
    this.onChangeUserName();
    this.userSurname = null;
    this.onChangeUserSurname();
    this.userEmail = null;
    this.onChangeUserEmail();
    this.userRepeatEmail = null;
    this.onChangeUserRepeatEmail();
    this.userCellphone = null;
    this.onChangeUserCellphone();
    this.userPassword = null;
    this.onChangeUserPassword();
    this.userRepeatPassword = null;
    this.onChangeUserRepeatPassword();

    this.disableForm = false;
  }

  /**
   * Returns TRUE if e-mail for Password Recovery is invalid.
   */
  protected get isEmailInvalid(): boolean {
    return !this.helpersService.isEmailValid(this.userEmail);
  }

  /**
   * Return TRUE if Cellphone is invalid
   */
  protected get isPhoneInvalid(): boolean {
    return !this.helpersService.isCellphoneValid(this.userCellphone);
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
    this.redirectAfterRegister = this.sessionService.getSessionData(_SESSION_TAG_REDIRECT_AFTER_REGISTER);
    this.sessionService.setSessionData(_SESSION_TAG_REDIRECT_AFTER_REGISTER, null);
    if (!this.redirectAfterRegister) {
      this.redirectAfterRegister = _HOME_PAGE_URL;
    }

    this.formInit();
  }

  /**
   * User changes Name data handler.
   */
  protected onChangeUserName(): void {
    // No action needed.
  }

  /**
   * User changes Surname data handler.
   */
  protected onChangeUserSurname(): void {
    // No action needed.
  }

  /**
   * User changes Email data handler.
   */
  protected onChangeUserEmail(): void {
    // No action needed.
  }

  /**
   * User changes RepeatEmail data handler.
   */
  protected onChangeUserRepeatEmail(): void {
    // No action needed.
  }

  /**
   * User changes Cellphone data handler.
   */
  protected onChangeUserCellphone(): void {
    // No action needed.
  }

  /**
   * User changes Password data handler.
   */
  protected onChangeUserPassword(): void {
    // No action needed.
  }

  /**
   * User changes RepeatPassword data handler.
   */
  protected onChangeUserRepeatPassword(): void {
    // No action needed.
  }

  /**
   * LogIn operation failure handler.
   */
  private onSignUpFail(error: any): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'onSignUpFail'
    });
    _logger.info(_METHOD_START_MSG);

    const appError: BaseAppError = this.errorsService.getAppError(error);
    _logger.error('Error:', appError.getMessage());

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            _logger.debug('Error modal closed with result:', result);
          })
          .catch(reason => {
            _logger.debug('Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
          });
      });
  }

  /**
   * SignUp submit handler.
   */
  protected onSignUpSubmit(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'onSignUpSubmit'
    });
    _logger.info(_METHOD_START_MSG);

    if (!this.userName || !this.userSurname || !this.userEmail || !this.userRepeatEmail || !this.userCellphone ||
      !this.userPassword || !this.userRepeatPassword) {
      this.onSignUpFail('Todos los campos son obligatorios.');
      return;
    }

    if (this.userEmail !== this.userRepeatEmail) {
      this.onSignUpFail('Los e-mails no coinciden.');
      return;
    }

    if (this.userPassword !== this.userRepeatPassword) {
      this.onSignUpFail('Las contraseñas no coinciden.');
      return;
    }

  /**
   * Se verifica si la contraseña cumple con los requisitos, de ser asi se procede con el envío del codigo.
   * En caso contrario se envía un mensaje de error en un modal.
   * Al menos 8 caracteres, una letra mayúscula, una minúscula y un número.
   */

   var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

   if(strongRegex.test(this.userPassword)) 
   {
     const verifyModal = this.modalsService.showCustomModal(PhoneVerifyModalComponent, 'phone-verify-modal', 'sm',
     true, 'modal-basic-title', false, 'static');
   verifyModal.componentInstance.phone = this.userCellphone;
   verifyModal.componentInstance.email = this.userEmail;

   verifyModal.result.then(result => {
     _logger.debug('Celular verificado correctamente:', result);
     this.registerUser();
   }).catch(error => {
     _logger.error('Error al verificar celular:', error);
     this.onSignUpFail('Ocurrió un error al intentar verificar su número de celular, ' +
       'por favor intente nuevamente.');
   });

   }
   else
   {
     this.modalsService.showErrorModal('La contraseña debe contener al menos 8 caracteres, una mayúscula y un número')
   }

  }

  private registerUser(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'registerUser'
    });
    _logger.info(_METHOD_START_MSG);

    // Facebook fbq
    fbq('track', 'Registro_Formulario');
    // Google GDN Registro_Formulario
    gtag('event', 'conversion', {
      event_label: 'EOJ2CN3T38IBEKfY38ED',
      value: 943189031
    });
    // Google SEM Registro_Formulario
    gtag('event', 'conversion', {
      event_label: 'qrOyCO-Vz8IBEMeE7qAD',
      value: 874218055
    });

    this.disableForm = true;
    const loadingModal = this.modalsService.showLoadingModal('Registrando...');
    this.usersService.doRegister(this.userName, this.userSurname, this.userEmail, this.userRepeatEmail, this.userPassword,
      this.userRepeatPassword, this.userCellphone, this.redirectAfterRegister)
      .subscribe((resultOrError: boolean | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('doRegister resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          this.onSignUpFail(resultOrError);
        } else {
          _logger.debugVerbose('doRegister success.');
          this.modalsService.showSuccessModal('Revise su casilla de correo.', 'Registro exitoso')
            .then(modal => {
              modal.result
                .then(result => {
                  _logger.debug('Error modal closed with result:', result);
                })
                .catch(reason => {
                  _logger.debug('Error modal dismissed with reason:', reason);
                })
                .finally(() => {
                  this.viewportScroller.scrollToAnchor('app-main-header');
                  // Redirect to LoginPage.
                  this.sessionService.setSessionData('redirectAfterLogin', this.redirectAfterRegister);
                  this.router.navigate([_LOGIN_PAGE_URL]);
                });
            });
        }
      }, (error: any) => {
        _logger.error('Error on doRegister.');
        _logger.errorVerbose('Error:', error);
        this.onSignUpFail(error);
      }, () => {
        this.disableForm = false;
        this.closeLoadingModal(loadingModal);
      });
  }

  /**
   * User blurs Cellphone
   */
  protected onBlurCellPhone(): void {
    if (!this.isPhoneInvalid) {
      const _logger = _LOGGER.getDerivedContext({
        methodName: 'onBlurCellPhone'
      });
      _logger.info(_METHOD_START_MSG);

      const loadingModal = this.modalsService.showLoadingModal('Validando teléfono. Por favor espere.');
      this.usersService.validatePhone(this.userCellphone)
        .subscribe((resultOrError: PhoneValidatedModel | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError)) {
            const appError: BaseAppError = this.errorsService.getAppError(resultOrError);
            _logger.error('onBlurCellPhone resulted in error.');
            _logger.errorVerbose('Error:', resultOrError);
            this.userCellphone = '';
            this.modalsService.showErrorModal(appError.getMessage());
          } else {
            _logger.debugVerbose('onBlurCellPhone success.');
            this.userCellphone = resultOrError.phone;
          }
        }, (error: BaseAppError) => {
          _logger.error('Error on onBlurCellPhone.');
          _logger.errorVerbose('Error:', error);
          this.userCellphone = '';
          this.modalsService.showErrorModal('Ha ocurrido un error al validar el teléfono. Intente nuevamente en unos minutos.');
        }, () => {
          this.disableForm = false;
          this.closeLoadingModal(loadingModal);
        });
    }
  }

  private closeLoadingModal(loadingModal: Promise<NgbModalRef>): void {
    loadingModal.then(modal => {
      modal.dismiss('done');
    });
  }
}
