import {Injectable} from '@angular/core';
import {LoggerContext, SimpleLogger} from '../../shared/simple-logger.shared';
import {EnvironmentManager} from '../../shared/environment-manager.shared';
import {IWidgetCotizadorInitResponse} from './i-widget-cotizador-init-response';
import {PlanSubplanModel} from '../../models/plan-subplan.model';


declare var window;


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'widget-cotizador.service.ts',
  className: 'WidgetCotizadorService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


const _SCRIPT_LOAD_TIMEOUT = 2 * 60 * 1000; // 2'.


/**
 * Widget Cotizador Service.
 * Loads Widget Cotizador SDK Script into DOM and publishes a facade to its methods.
 */
@Injectable({
  providedIn: 'root'
})
export class WidgetCotizadorService {
  private widgetCotizadorSdkPromise: Promise<any>;
  private widgetCotizadorSdk: any;

  constructor() {
  }

  /**
   * Loads Widget Cotizador SDK.
   */
  private loadWidgetSdk(): Promise<any> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'loadWidgetSdk'
    });
    __logger.info('Method start.');

    if (!this.widgetCotizadorSdkPromise) {
      let __sdkLoaded: boolean = false;

      this.widgetCotizadorSdkPromise = new Promise<any>((resolve, reject) => {
        const __onLoadScriptError = (reason: string) => {
          __logger.debug('Cannot load Widget Cotizador SDK Script.', reason);
          reject(`Cannot load Widget Cotizador SDK Script (${reason}).`);
        };

        // Google Callback function.
        let script = window.document.createElement('script');
        script.type = 'text/javascript';
        script.src = _CONFIG.widgetCotizadorUrl;

        const onSdkLoaded = () => {
          __logger.debugVerbose('Widget Cotizador SDK start.');
          if (window && window.TCFACotizadorSDK) {
            __logger.debug('Widget Cotizador SDK resolved.');
            __sdkLoaded = true;
            resolve(window.TCFACotizadorSDK);
          } else {
            __onLoadScriptError('other');
          }
        };

        if (script.readyState) {  // IE.
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              onSdkLoaded();
            }
          };
        } else {  // Others.
          script.onload = () => {
            onSdkLoaded();
          };
        }

        // Timeout error function.
        setTimeout(() => {
          if (!__sdkLoaded) {
            __onLoadScriptError('timeout');
          }
        }, _SCRIPT_LOAD_TIMEOUT);

        script.onerror = (error: any) => {
          __onLoadScriptError(error);
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      });
    }
    return this.widgetCotizadorSdkPromise;
  }

  public sdkInit(idFrameWrapper: string, onUpdateCallback?: (value: any) => void, onScrollToCallback?: (value: { height: number; }) => void): Promise<IWidgetCotizadorInitResponse | any> {
    return new Promise<IWidgetCotizadorInitResponse>((resolve, reject) => {
      this.loadWidgetSdk()
        .then(sdk => {
          this.widgetCotizadorSdk = sdk;
          this.widgetCotizadorSdk.init({
            container: idFrameWrapper,
            environment: _CONFIG.widgetCotizadorEnvName,
            client: 'CLIENT_ID',
            secret: 'SECRET',
            theme: _CONFIG.widgetCotizadorTheme,
            done: (value: IWidgetCotizadorInitResponse) => {
              resolve(value);
            },
            fail: (error: any) => {
              reject(error);
            },
            update: (value: any) => {
              if (onUpdateCallback) {
                onUpdateCallback(value);
              }
            },
            scroll_to: (value: { height: number; }) => {
              if (onScrollToCallback) {
                onScrollToCallback(value);
              }
            }
          });
        })
        .catch((error: any) => {
          this.widgetCotizadorSdk = null;
          reject(error);
        });
    });
  }

  /**
   * Gets quotes for a Vehicle.
   */
  public quoteVehicle(operationId: number, personId: number, taxConditionId: number, vehicleIaId: number, loanAmount: number,
                      vehicleYear: number, vehicleIsNew: boolean, vehiclePrice: number, dealerCuit: string,
                      vehicleBrandId: number, vehicleModelId: number, vehicleVersionId: number, publicationId: number, vehicleImage: string,
                      vehicleBrandName: string, vehicleModelName: string, vehicleVersionName: string, canal: string,
                      isCertificated: boolean, app: string, removePlanFilter: boolean, plans: PlanSubplanModel[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.widgetCotizadorSdk && this.widgetCotizadorSdk.trigger) {
        this.widgetCotizadorSdk.trigger({
          operationId,
          personId,
          taxConditionId,
          vehicleIaId,
          loanAmount,
          vehicleYear,
          vehicleIsNew,
          vehiclePrice,
          dealerCuit,
          vehicleBrandId,
          vehicleModelId,
          vehicleVersionId,
          publicationId,
          vehicleImage,
          vehicleBrandName,
          vehicleModelName,
          vehicleVersionName,
          canal,
          isCertificated,
          app,
          removePlanFilter,
          plans,
          done: (value) => {
            resolve(value);
          },
          fail: (error) => {
            reject(error);
          }
        });
      } else {
        reject('SDK not initialized.');
      }
    });
  }

  /**
   * Gets quotes for a Vehicle shared.
   */
  public quoteVehicleShared(operationId: number, personId: number, taxConditionId: number, vehicleIaId: number,
                            loanAmount: number, vehicleYear: number, vehicleIsNew: boolean, vehiclePrice: number,
                            dealerCuit: string, vehicleBrandId: number, vehicleModelId: number,
                            vehicleVersionId: number, publicationId: number, vehicleImage: string,
                            vehicleBrandName: string, vehicleModelName: string, vehicleVersionName: string,
                            canal: string, isCertificated: boolean, app: string, removePlanFilter: boolean,
                            plans: PlanSubplanModel[], term: number, subplan: number, postalCode: number, zoneId: string,
                            insurancePlanId: string, insuranceTypeCode: string, insuranceCompanyId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.widgetCotizadorSdk && this.widgetCotizadorSdk.triggerShared) {
        this.widgetCotizadorSdk.triggerShared({
          operationId,
          personId,
          taxConditionId,
          vehicleIaId,
          loanAmount,
          vehicleYear,
          vehicleIsNew,
          vehiclePrice,
          dealerCuit,
          vehicleBrandId,
          vehicleModelId,
          vehicleVersionId,
          publicationId,
          vehicleImage,
          vehicleBrandName,
          vehicleModelName,
          vehicleVersionName,
          canal,
          isCertificated,
          app,
          removePlanFilter,
          plans,
          term,
          subplan,
          postalCode,
          zoneId,
          insurancePlanId,
          insuranceTypeCode,
          insuranceCompanyId,
          done: (value) => {
            resolve(value);
          },
          fail: (error) => {
            reject(error);
          }
        });
      } else {
        reject('SDK not initialized.');
      }
    });
  }
}
