export class UserMessageModel {
  public id: number;
  public consultaPreviaId: string;
  public nombre: string;
  public texto: string;
  public email: string;
  public visto: boolean;
  public fechaCreacion: Date;
  public estado: string;
  public celular: string;
  public esRespuesta: boolean;
  public telefono: string;

  public constructor(id: number, consultaPreviaId: string, nombre: string, texto: string, email: string, visto: boolean,
                     fechaCreacion: Date, estado: string, celular: string, esRespuesta: boolean, telefono: string) {
    this.id = id;
    this.nombre = nombre;
    this.consultaPreviaId = consultaPreviaId;
    this.texto = texto;
    this.email = email;
    this.visto = visto;
    this.fechaCreacion = fechaCreacion;
    this.estado = estado;
    this.celular = celular;
    this.esRespuesta = esRespuesta;
    this.telefono = telefono;
  }
}
