import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from "../../../../services/auth/auth.service";
import { UsersService } from "../../../../services/users/users.service";
import { ErrorsService } from "../../../../services/errors/errors.service";
import { Router } from "@angular/router";
import { BaseAppError } from "../../../../services/errors/base-app-error";
import { UserProfileModel } from "../../../../models/user-profile.model";
import { LoggerContext, SimpleLogger } from "../../../../shared/simple-logger.shared";
import { ModalsService } from "../../../../services/modals/modals.service";
import { ViewportScroller } from "@angular/common";
import { NgForm } from "@angular/forms";
import { HelpersService } from "../../../../services/helpers/helpers.service";
import { SessionService } from "../../../../services/session/session.service";
import { PhoneValidatedModel } from '../../../../models/phone-validated.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PhoneVerifyModalComponent } from '../../../main/components/phone-verify-modal/phone-verify-modal.component';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'profile-page.component.ts',
  className: 'ProfilePageComponent',
  tagName: 'PAGES'
});
_LOGGER.debugVerbose('Loaded.');

const _METHOD_START_MSG = 'Method start.';


/**
 * User Profile page.
 */
@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {
  /** Form controls. */
  @ViewChild('profileForm') protected profileForm: NgForm;

  /** Form data. */
  protected disableForm: boolean;
  protected profileData: UserProfileModel;
  protected loadingProfile: boolean;
  protected profileName: string;
  protected profileSurname: string;
  protected profileEmail: string;
  protected profileCellphone: string;
  protected profilePassword: string;
  protected profileRepeatPassword: string;

  active: boolean = false;

  protected messageCount: number;

  constructor(
    private router: Router,
    private authService: AuthService,
    private usersService: UsersService,
    private errorsService: ErrorsService,
    private modalsService: ModalsService,
    private viewportScroller: ViewportScroller,
    private helpersService: HelpersService,
    private sessionService: SessionService
  ) {
  }

  /* Presentation logic. */

  protected get userAvatarUrl(): string {
    if (this.authService.isUserLoggedIn()) {
      return this.authService.getUserData().imageUrl;
    }
    return null;
  }

  /**
   * Initializes form data.
   */
  private formInit(): void {
    this.disableForm = true;

    // No init needed (simple form).

    this.formReset();
  }

  /**
   * Resets the form.
   */
  private formReset(): void {
    if (this.profileForm && this.profileForm.pristine) {
      this.profileForm.control.reset();
    }

    this.profileName = this.profileData.name;
    this.onChangeProfileName();
    this.profileSurname = this.profileData.surname;
    this.onChangeProfileSurname();
    this.profileEmail = this.profileData.email;
    this.onChangeProfileEmail();
    this.profileCellphone = this.profileData.cellphone;
    this.onChangeProfileCellphone();
    this.profilePassword = null;
    this.onChangeProfilePassword();
    this.profileRepeatPassword = null;
    this.onChangeProfileRepeatPassword();

    this.disableForm = false;
  }

  /**
   * Navs to Login Page.
   */
  private goToLoginPage(): void {
    this.sessionService.setSessionData('redirectAfterLogin', '/profile');
    this.router.navigate(['/login']);
  }

  /**
   * Returns TRUE if e-mail for Password Recovery is invalid.
   */
  protected get isEmailInvalid(): boolean {
    return !this.helpersService.isEmailValid(this.profileEmail);
  }

  /**
   * Return TRUE if Cellphone is invalid
   */
  protected get isPhoneInvalid(): boolean {
    return !this.helpersService.isCellphoneValid(this.profileCellphone);
  }

  private getMessageCount(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'getMessageCount'
    });
    _logger.info(_METHOD_START_MSG);

    this.usersService.getUserMessagesCount()
      .subscribe((resultOrError: number | BaseAppError) => {
        if ((!resultOrError && (resultOrError !== 0)) || (resultOrError instanceof BaseAppError)) {
          _logger.error('getMessageCount resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          this.onError(resultOrError);
        } else {
          _logger.debugVerbose('getMessageCount success.');
          this.messageCount = resultOrError;
        }
      }, (error: any) => {
        _logger.error('Error on getMessageCount.');
        _logger.errorVerbose('Error:', error);
        this.onError(error);
      });
  }


  /* Handlers. */

  /**
   * Shows errors to user.
   */
  private onError(error: any, navigate: boolean = false): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'onSignUpFail'
    });
    _logger.info(_METHOD_START_MSG);

    const appError: BaseAppError = this.errorsService.getAppError(error);
    _logger.error('Error:', appError.getMessage());

    if (appError.getCode() === ErrorsService.getApiHttpErrorCode(401)) {
      this.authService.expireSession();
      navigate = true;
    }

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            _logger.debug('Error modal closed with result:', result);
          })
          .catch(reason => {
            _logger.debug('Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
            if (navigate) {
              this.goToLoginPage();
            }
          });
      });
  }

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'ngOnInit'
    });
    _logger.info(_METHOD_START_MSG);

    // Get User's profile.
    if (this.authService.isUserLoggedIn()) {
      this.getMessageCount();

      this.loadingProfile = true;
      const loadingModal = this.modalsService.showLoadingModal('Obteniendo datos...');
      this.usersService.getUserProfile()
        .subscribe((resultOrError: UserProfileModel | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError)) {
            _logger.error('getUserProfile resulted in error.');
            _logger.errorVerbose('Error:', resultOrError);
            this.onError(resultOrError);
          } else {
            _logger.debugVerbose('getUserProfile success.');
            this.profileData = resultOrError;
            this.formInit();
          }
        }, (error: any) => {
          _logger.error('Error on getUserProfile.');
          _logger.errorVerbose('Error:', error);
          this.onError(error);
        }, () => {
          this.loadingProfile = false;
          this.closeLoadingModal(loadingModal);
        });
    } else {
      this.goToLoginPage();
    }
  }

  /**
   * User changes Name data handler.
   */
  protected onChangeProfileName(): void {
    // No action needed.
  }

  /**
   * User changes Surname data handler.
   */
  protected onChangeProfileSurname(): void {
    // No action needed.
  }

  /**
   * User changes Email data handler.
   */
  protected onChangeProfileEmail(): void {
    // No action needed.
  }

  /**
   * User changes Cellphone data handler.
   */
  protected onChangeProfileCellphone(): void {
    // No action needed.
  }

  /**
   * User changes Password data handler.
   */
  protected onChangeProfilePassword(): void {
    // No action needed.
  }

  /**
   * User changes RepeatPassword data handler.
   */
  protected onChangeProfileRepeatPassword(): void {
    // No action needed.
  }

  /**
   * Profile update submit handler.
   */
  protected onProfileSubmit(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'onProfileSubmit'
    });
    _logger.info(_METHOD_START_MSG);

    if (!this.profileName || !this.profileSurname || !this.profileEmail || this.isEmailInvalid || (!!this.profileCellphone && this.isPhoneInvalid)) {
      this.onError('Los datos ingresados no son correctos.');
      return;
    }

    if ((!!this.profilePassword || !!this.profileRepeatPassword) && (this.profilePassword !== this.profileRepeatPassword)) {
      this.onError('Las contraseñas no coinciden.');
      return;
    }

    this.disableForm = true;
    if (this.profileData.cellphoneValidated && this.profileCellphone === this.profileData.cellphone) {
      this.updateUserProfile();
    } else {
      const verifyModal = this.modalsService.showCustomModal(PhoneVerifyModalComponent, 'phone-verify-modal', 'sm',
        true, 'modal-basic-title', false, 'static');
      verifyModal.componentInstance.phone = this.profileCellphone;
      verifyModal.componentInstance.email = this.profileEmail;

      verifyModal.result.then(result => {
        _logger.debug('Celular verificado correctamente:', result);
        this.updateUserProfile();
      }).catch(error => {
        _logger.error('Error al verificar celular:', error);
        this.onError('Ocurrió un error al intentar verificar su número de celular, ' +
          'por favor intente nuevamente.');
      });
    }
  }

  private updateUserProfile(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'updateUserProfile'
    });
    _logger.info(_METHOD_START_MSG);

    const loadingModal = this.modalsService.showLoadingModal('Actualizando...');
    this.usersService.updateUserProfile(this.profileName, this.profileSurname, this.profileEmail, this.profilePassword,
      this.profileRepeatPassword, null, this.profileCellphone)
      .subscribe((resultOrError: boolean | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('updateUserProfile resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          this.onError(resultOrError);
        } else {
          _logger.debugVerbose('updateUserProfile success.');
          this.profileData.name = this.profileName;
          this.profileData.surname = this.profileSurname;
          this.profileData.email = this.profileEmail;
          this.profileData.cellphone = this.profileCellphone;
          this.profilePassword = null;
          this.profileRepeatPassword = null;
          this.modalsService.showSuccessModal('Sus datos han sido actualizados.', 'Modificación exitosa')
            .then(modal => {
              modal.result
                .then(result => {
                  _logger.debug('Success modal closed with result:', result);
                })
                .catch(reason => {
                  _logger.debug('Success modal dismissed with reason:', reason);
                })
                .finally(() => {
                  this.viewportScroller.scrollToAnchor('app-main-header');
                });
            });
        }
      }, (error: any) => {
        _logger.error('Error on updateUserProfile.');
        _logger.errorVerbose('Error:', error);
        this.onError(error);
      }, () => {
        this.disableForm = false;
        this.closeLoadingModal(loadingModal);
      });
  }

  isOpen() {
    this.active = !this.active;
  }

  /**
   * User's Profile Avatar change handler.
   *
   * @param files   Input files object (avatar img file).
   */
  protected onChangeAvatar(files: FileList): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'updateAvatar'
    });
    _logger.info(_METHOD_START_MSG);

    if (files.length > 0) {
      const loadingModal = this.modalsService.showLoadingModal('Actualizando...');
      this.usersService.doUpdateProfileAvatar(files[0])
        .subscribe((resultOrError: boolean | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError)) {
            _logger.error('doUpdateProfileAvatar resulted in error.');
            _logger.errorVerbose('Error:', resultOrError);
            this.onError(resultOrError);
          } else {
            _logger.debugVerbose('doUpdateProfileAvatar success.');
            const reader = new FileReader();
            reader.onload = (ev: ProgressEvent | any) => {
              this.authService.getUserData().imageUrl = ev.target.result;
            };
            reader.readAsDataURL(files[0]);
          }
        }, (error: any) => {
          _logger.error('Error on doUpdateProfileAvatar.');
          _logger.errorVerbose('Error:', error);
          this.onError(error);
        }, () => {
          this.closeLoadingModal(loadingModal);
        });
    }
  }

  /**
   * User blurs Cellphone
   */
  protected onBlurCellPhone(): void {
    if (!this.isPhoneInvalid) {
      const _logger = _LOGGER.getDerivedContext({
        methodName: 'onBlurCellPhone'
      });
      _logger.info(_METHOD_START_MSG);

      const loadingModal = this.modalsService.showLoadingModal('Validando teléfono. Por favor espere.');
      this.usersService.validatePhone(this.profileCellphone)
        .subscribe((resultOrError: PhoneValidatedModel | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError)) {
            const appError: BaseAppError = this.errorsService.getAppError(resultOrError);
            _logger.error('onBlurCellPhone resulted in error.');
            _logger.errorVerbose('Error:', resultOrError);
            this.profileCellphone = '';
            this.modalsService.showErrorModal(appError.getMessage());
          } else {
            _logger.debugVerbose('onBlurCellPhone success.');
            this.profileCellphone = resultOrError.phone;
          }
        }, (error: BaseAppError) => {
          _logger.error('Error on onBlurCellPhone.');
          _logger.errorVerbose('Error:', error);
          this.profileCellphone = '';
          this.modalsService.showErrorModal('Ha ocurrido un error al validar el teléfono. Intente nuevamente en unos minutos.');
        }, () => {
          this.disableForm = false;
          this.closeLoadingModal(loadingModal);
        });
    }
  }

  private closeLoadingModal(loadingModal: Promise<NgbModalRef>): void {
    loadingModal.then(modal => {
      modal.dismiss('done');
    });
  }
}
