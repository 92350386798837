import { Component, ElementRef, Input } from '@angular/core';

@Component({
    selector: 'fb-share',
    template: `<a class="share" href="https://www.facebook.com/sharer/sharer.php?app_id=&sdk=joey&u={{url}}&display=popup&ref=plugin&src=share_button" onclick="return !window.open(this.href, 'Facebook', 'width=640,height=580')"><img src="../../../../../assets/img/fa.png" alt="Logo Facebook"><p>En Facebook</p></a>`,
    styleUrls: ['./facebook.component.scss']
})

export class FacebookComponent {
    @Input() url = location.href;

    constructor() {
    }
}