import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl, NgControl } from "@angular/forms";
import { EnvironmentManager } from "../../shared/environment-manager.shared";


const _CONFIG = EnvironmentManager.getInstance().getConfig();


/**
 * Directive that formats and parses a number in float format on input blur and focus.
 */
@Directive({
  selector: '[appNumericFormatter]'
})
export class NumericFormatterDirective {
  @Input()
  public displayMilesSymbol = false;
  @Input()
  public displayDecimalsSymbol = false;

  private el: HTMLInputElement;

  private get ctrl(): AbstractControl {
    return this.ngControl.control;
  }

  constructor(
    private elementRef: ElementRef,
    private ngControl: NgControl
  ) {
    this.el = this.elementRef.nativeElement;
  }

  public static parseInputToFloat(inputString: string): number {
    const numberParts: string[] = ('0' + inputString + _CONFIG.decimalSeparator + '0')
      .replace(new RegExp('[' + _CONFIG.milesSeparator + ']', 'g'), '')
      .split(_CONFIG.decimalSeparator);

    const inputAsNumber: number = parseFloat(numberParts[0] + '.' + numberParts[1]);
    if (isNaN(inputAsNumber)) {
      return null;
    }

    return inputAsNumber;
  }

  public static parseInputToString(inputFloat: number, showMilesSymbol: boolean, showDecimalSymbol: boolean = false): string {
    if (showMilesSymbol) {
      return inputFloat.toLocaleString(_CONFIG.i18nLocaleShort, {
        style: 'decimal',
        minimumIntegerDigits: 1,
        minimumFractionDigits: showDecimalSymbol ? 2 : 0,
        maximumFractionDigits: showDecimalSymbol ? 2 : 0
      });
    }
    return inputFloat.toFixed(showDecimalSymbol ? 2 : 0);
  }

  private getInputAsNumber(): number {
    const currentValue: string = (this.ctrl.value as string) || '';
    const numberParts: string[] = ('0' + currentValue + _CONFIG.decimalSeparator + '0')
      .replace(new RegExp('[' + _CONFIG.milesSeparator + ']', 'g'), '')
      .split(_CONFIG.decimalSeparator);

    return parseFloat(numberParts[0] + '.' + numberParts[1]);
  }

  @HostListener('keyup')
  public onKeyUp(e: KeyboardEvent): void {
    const inputAsNumber: number = this.getInputAsNumber();
    if (isNaN(inputAsNumber)) {
      return;
    }

    const newValue = NumericFormatterDirective.parseInputToString(inputAsNumber, this.displayMilesSymbol);
    const newIntegerPart: string = newValue.split(_CONFIG.decimalSeparator)[0];
    const currentValue: string = (this.ctrl.value as string) || '';
    const currentIntegerPart: string = currentValue.split(_CONFIG.decimalSeparator)[0];

    let newCaretPosition = this.el.selectionStart;
    if (newIntegerPart.length > currentIntegerPart.length) {
      newCaretPosition = newCaretPosition + 1;
    } else if (newIntegerPart.length < currentIntegerPart.length) {
      newCaretPosition = newCaretPosition - 1;
    }

    this.ctrl.setValue(newValue);
    this.el.setSelectionRange(newCaretPosition, newCaretPosition);
  }
}
