import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggerContext, SimpleLogger } from './shared/simple-logger.shared';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'app-routing.module.ts',
  className: 'AppRoutingModule',
  methodName: '',
  tagName: 'APP'
});
_LOGGER.debugVerbose('Loaded.');


const _ROUTES: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: '**', redirectTo: 'home', pathMatch: 'full'} // FIXME: add 404.
];

@NgModule({
  imports: [RouterModule.forRoot(_ROUTES, {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
