import { VehicleModelModel } from './vehicle-model.model';
import { VehicleVersionModel } from './vehicle-version.model';
import { ProvinceModel } from './province.model';
import { VehicleBrandModel } from './vehicle-brand.model';


export class PublicationModel {
  public id: number;
  public images: string[];
  public price: number;
  public cuota: number;
  public priceCalculated: number;
  public year: number;
  public mileage: number;
  public title: string;
  public vehicleBrand: VehicleBrandModel;
  public vehicleModel: VehicleModelModel;
  public vehicleVersion: VehicleVersionModel;
  public province: ProvinceModel;
  public liked: boolean;
  public isCertificated: boolean;
  public isReserved: boolean;

  constructor(id: number, images: string[], price: number, cuota: number, priceCalculated: number, year: number,
              mileage: number, title: string, vehicleBrand: VehicleBrandModel, vehicleModel: VehicleModelModel,
              vehicleVersion: VehicleVersionModel, province: ProvinceModel, isCertificated: boolean = false,
              isReserved: boolean = false, liked: boolean = false) {
    this.id = id;
    this.images = images;
    this.price = price;
    this.cuota = cuota;
    this.priceCalculated = priceCalculated;
    this.year = year;
    this.mileage = mileage;
    this.title = title;
    this.vehicleBrand = vehicleBrand;
    this.vehicleModel = vehicleModel;
    this.vehicleVersion = vehicleVersion;
    this.province = province;
    this.liked = liked;
    this.isCertificated = isCertificated;
    this.isReserved = isReserved;
  }
}
