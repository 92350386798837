import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { DealersService } from '../../../../services/dealers/dealers.service';
import { DealerModel } from '../../../../models/dealer.model';
import { BaseAppError } from '../../../../services/errors/base-app-error';
import { LoggerContext, SimpleLogger } from '../../../../shared/simple-logger.shared';
import { GoogleMapService } from '../../../../services/google-map/google-map.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { ModalsService } from '../../../../services/modals/modals.service';
import { CommonsService } from '../../../../services/commons/commons.service';
import { UserActionService } from '../../../../services/user-action/user-action.service';
import { UserActionModel } from '../../../../models/user-action-send.model';
import {EnvironmentManager} from '../../../../shared/environment-manager.shared';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'home-page.component.ts',
  className: 'HomePageComponent',
  tagName: 'PAGES'
});
_LOGGER.debugVerbose('Loaded.');

const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _OPEN_SEARCH_MODAL_ANIMATION_TIMESPAN = 200;
const _BACKEND_CODE_REGISTRATION_SUCCESS = 'REG-OK';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  animations: [
    trigger('searchModalDisplay', [
      state('closed', style({
        height: '0'
      })),
      state('opened', style({
        height: '95%'
      })),
      transition('closed => opened', [
        animate(`${_OPEN_SEARCH_MODAL_ANIMATION_TIMESPAN}ms`)
      ]),
      transition('opened => closed', [
        animate(`${_OPEN_SEARCH_MODAL_ANIMATION_TIMESPAN}ms`)
      ])
    ])
  ]
})
export class HomePageComponent implements OnInit {

  /* Url from envirinment*/
  protected brokerSegurosURL: string = _CONFIG.brokerSegurosURL;

  /* Form controls. */
  @ViewChild('map') protected mapElement: ElementRef;

  /* View data. */
  protected dealers: DealerModel[];
  protected map: any;
  public loadingMap = true;
  public mapError = false;

  private openInfoWindow: any;

  @ViewChild('acc') protected acc: NgbAccordion;

  /* Accordion Toggle */
  public accordionValue = '+';

  constructor(
    private dealersService: DealersService,
    private googleMapService: GoogleMapService,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
    private modalsService: ModalsService,
    private commonsService: CommonsService,
    private userActionService: UserActionService
  ) {
    const __logger = _LOGGER.getDerivedContext({methodName: 'constructor'});
    __logger.infoVerbose('Created.');
  }


  /* Presentation logic. */

  /**
   * Get Dealers Map array.
   */
  private getDealers(): Promise<DealerModel[]> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getDealers'
    });
    __logger.info('Method start.');

    return new Promise<DealerModel[]>((resolve) => {
      const __onDealersGetMapFail = () => {
        this.dealers = [];
        resolve(this.dealers);
      };

      const __onDealersGetMapSuccess = (dealers: DealerModel[]) => {
        this.dealers = dealers;
        resolve(this.dealers);
      };

      this.dealersService.getMap()
        .subscribe(
          // Success response.
          (resultOrError: DealerModel[] | BaseAppError) => {
            if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.length) {
              __logger.error('Cannot resolve Dealers Map array.', resultOrError);
              __onDealersGetMapFail();
            } else {
              __logger.debug('Dealers Map initialized.');
              __onDealersGetMapSuccess(resultOrError);
            }
          },
          // Error response.
          (error: any) => {
            __logger.error('Error on get Dealers Map.', error);
            __onDealersGetMapFail();
          });
    });
  }

  /**
   * Loads Dealer Map.
   */
  private loadDealersMap(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'loadDealersMap'
    });
    __logger.info('Method start.');

    this.loadingMap = true;
    this.mapError = false;

    // Get user position.
    const __userPositionPromise: Promise<Position> = this.googleMapService.getUserPosition();

    // Get Dealers.
    const __dealersPromise: Promise<DealerModel[]> = this.getDealers();

    
    // Get Google Map SDK.
    this.googleMapService
      .getGoogleMapSdk()
      .then((googleMapSdk) => {
        /**
         * Adds a marker to Dealer Map.
         *
         * @param lat
         * @param long
         * @param title
         * @param address
         */
        const __addMarkerToDealerMap = (lat: number, long: number, title: string, address?: string) => {
          __logger.debugVerbose(`Adding Marker position (${lat}, ${long}). Title: ${title}; Address:`, address);

          let __markerOptions: any = {
            map: this.map,
            animation: googleMapSdk.Animation.DROP,
            position: new googleMapSdk.LatLng(lat, long)
          };

          const svgMarker = {
            path: "M 12 22 z M 12 2.016 q 2.906 0 4.945 2.039 t 2.039 4.945 q 0 1.453 -0.727 3.328 t -1.758 3.516 t -2.039 3.07 t -1.711 2.273 l -0.75 0.797 q -0.281 -0.328 -0.75 -0.867 t -1.688 -2.156 t -2.133 -3.141 t -1.664 -3.445 t -0.75 -3.375 q 0 -2.906 2.039 -4.945 t 4.945 -2.039 z",
            fillColor: "red",
            fillOpacity: 0.8,
            strokeWeight: 0,
            rotation: 0,
            scale: 2,
            anchor: new googleMapSdk.Point(10, 20)
          };
          
          if (address) {
            __markerOptions.icon = svgMarker;
          }
          let __marker = new googleMapSdk.Marker(__markerOptions);

          let __infoWindow = new googleMapSdk.InfoWindow({
            content: `<div class="map-marker"><h4>${title}</h4><h5>${address || ''}</h5></div>`
          });

          googleMapSdk.event.addListener(__marker, 'click', () => {
            if (__infoWindow) {
              if (this.openInfoWindow) {
                this.openInfoWindow.close();
              }

              if (__infoWindow !== this.openInfoWindow) {
                __infoWindow.open(this.map, __marker);
                this.map.panTo(__infoWindow.position);
                this.openInfoWindow = __infoWindow;
              } else {
                this.openInfoWindow = null;
              }
            }
          });

          googleMapSdk.event.addListener(__infoWindow, 'closeclick', () => {
            this.openInfoWindow = null;
          });
        };

        this.map = new googleMapSdk.Map(this.mapElement.nativeElement, {
          center: new googleMapSdk.LatLng(-39.4520765, -63.9034918), // Centered on Argentina by default.
          zoom: 4,
          mapTypeId: googleMapSdk.MapTypeId.ROADMAP
        });

        __userPositionPromise
          .then((userPosition: Position) => {
            __logger.debug('Got User position:', userPosition);

            const __userLatitude = userPosition.coords.latitude;
            const __userLongitude = userPosition.coords.longitude;

            __addMarkerToDealerMap(__userLatitude, __userLongitude, 'Ud. se encuentra aquí.');
            this.map.panTo(new googleMapSdk.LatLng(__userLatitude, __userLongitude));
            this.map.setZoom(11);
          });

        __dealersPromise
          .then((dealers: DealerModel[]) => {
            dealers
              .filter(dealer => dealer.publicationsCount > 0)
              .forEach((dealer: DealerModel) => {
                dealer.branches
                  .forEach((branch) => {
                    __addMarkerToDealerMap(branch.latitude, branch.longitude, `${dealer.name} - ${branch.name}`, branch.address);
                  });
              });
          });

        this.loadingMap = false;
      })
      .catch(() => {
        this.loadingMap = false;
        this.mapError = true;
      });
  }

  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'ngOnInit'
    });
    __logger.info('Method start.');

    this.loadDealersMap();

    // Display integration messages.
    try {
      const integrationMessageString: string = this.route.snapshot.queryParamMap.get('e');
      if (integrationMessageString) {
        const integrationMessageJson: string = atob(decodeURIComponent(integrationMessageString));
        if (integrationMessageJson) {
          const integrationMessageParsed: any = JSON.parse(integrationMessageJson);
          if (integrationMessageParsed.title && integrationMessageParsed.message) {
            this.modalsService.showAlertModal(integrationMessageParsed.message, integrationMessageParsed.title);
            if (integrationMessageParsed.code === _BACKEND_CODE_REGISTRATION_SUCCESS) {
              this.router.navigate(['/vender/wizard'], {state: {recoverRegisterData: true}});
            }
          }
        }
      }
    } catch (e) {
      __logger.errorVerbose('Cannot parse integration message.', e);
    }
  }

  public accordionToggleHandler(): void {
    this.accordionValue == '+' ? this.accordionValue = '-' : this.accordionValue = '+';
  }

  public onOpenAccAndScroll(accId: string, offset: number = 0): void {
    if (!this.acc.isExpanded(accId)) {
      this.acc.toggle(accId);
    }
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor('accWrap');
      const currentPos = this.viewportScroller.getScrollPosition();
      this.viewportScroller.scrollToPosition([currentPos[0], currentPos[1] - 100 + offset]);
    }, 100);
  }

  /**
   * User selects "Usados Certificados Toyota" button.
   */
  public onSelectCertifiedToyotaPublications(): void {
    const logger = _LOGGER.getDerivedContext({methodName: 'onSelectCertifiedToyotaPublications'});
    logger.info('Method start.');
    this.userActionService.sendUserAction('comprar').subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
    this.commonsService.execUsadosCertificadosToyotaSearchTerm();
  }

  public onClickBuyToyota(): void {
    const logger = _LOGGER.getDerivedContext({methodName: 'onClickBuyToyota'});
    logger.info('Method start.');
    this.userActionService.sendUserAction('comprar').subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
    this.commonsService.execAllPublicationsSearchTerm();
  }

  public onClickSellToyota(): void {
    const logger = _LOGGER.getDerivedContext({methodName: 'onClickSellToyota'});
    logger.info('Method start.');
    this.userActionService.sendUserAction('vender').subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
    this.router.navigate(['/vender'], {state: {recoverRegisterData: true}});
  }
  
  onOpenBrokerSegurosTab(){
    window.open(this.brokerSegurosURL, "_blank");
  }

}
