import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseAppError } from '../errors/base-app-error';
import { Observable, of } from 'rxjs';
import { IApiCommonResponse } from '../i-api-common-response';
import { catchError, map, tap } from 'rxjs/operators';
import { UserActionModel } from 'src/app/models/user-action-send.model';
import { IUserActionResponse } from './i-user-action-response';
import { ErrorsService } from '../errors/errors.service';
import { AuthService } from '../auth/auth.service';

const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'user-action.service.ts',
  className: 'UserActionService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');

const _CONFIG = EnvironmentManager.getInstance().getConfig();

/**
 * User Action Service.
 */
@Injectable({
  providedIn: 'root'
})
export class UserActionService {
  private baseURLUserAction = `${_CONFIG.apiBaseURL}/action`;
  private appId = `${_CONFIG.appId}`;

  constructor(
    private readonly http: HttpClient,
    private errorsService: ErrorsService,
    private authService: AuthService
  ) {}

  public sendUserAction(action: string, params: string | number = null, publication_id: string | number = null): Observable<UserActionModel | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
        methodName: 'sendUserAction'
      });
      __logger.info('Method start.');
      const payload = {
        tipo_aplicacion_id: this.appId,
        accion: action,
        parametros: params,
        publicacion_id: publication_id
      };
      const endpointURL = `${this.baseURLUserAction}`;
      __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`);

      return this.http.post<IApiCommonResponse<IUserActionResponse>>(endpointURL, payload, {
        observe: 'response',
        headers: this.authService.getAuthorizationHeaders(true)
       })
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<IUserActionResponse>>) => {
            __logger.debug('API response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<IApiCommonResponse<IUserActionResponse>>) => response.body.data),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );
    }

}