import { IEnvironmentConfiguration } from '../app/shared/environment-manager.shared';
import { LOGLEVEL } from '../app/shared/simple-logger.shared';


export const environment: IEnvironmentConfiguration = {
  frontDomains: ['qa2.www.toyotausados.codeicus.com.ar', 'qa2.www.toyotausados.codeicus'],
  apiBaseURL: 'http://qa2.api.tcfautos.codeicus/index.php',
  brokerSegurosURL: 'https://testqawww.tcfaseguros.com.ar',
  ventaUsadosURL: 'https://dev.toyota.com.ar/venta-usados',
  appId: 2,
  publicationsImagesContextUrl: 'http://qa2.resources.tcfautos.codeicus/auto_images',
  profilesImagesContextUrl: 'http://qa2.resources.tcfautos.codeicus/user_images',
  dealerImagesContextUrl: 'http://qa2.resources.tcfautos.codeicus/dealer_images',
  googleMapAK: 'AIzaSyBGKK8d71IwRVZ9oIqE2256mR-HwnGCegg',
  googleAnalyticsId: '', // Format: 'UA-XXXXX-Y'.
  logLevel: LOGLEVEL.DEBUG,
  widgetCotizadorUrl: 'http://qa.wcotizador.tcfautos.codeicus/sdk/sdk.js',
  widgetCotizadorEnvName: 'QA',
  widgetCotizadorTheme: 'tcfautos',
  defaultOperationTypeCode: 'RE',
  facebookConfig: {
    appId: '1779429392292463',
    hash: '295e950403db38d00ddf58e730efdfb6',
    version: 'v2.6'
  },
  linkedinClientId: '77afduyxtja3k9',
  googleAuthConfig: {
    clientId: '823949072627-769p880beq6f731su74nf9v9h3qi2ss9.apps.googleusercontent.com',
    scopes: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/plus.me https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/userinfo.profile'
  }
};
