import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ErrorsService } from '../errors/errors.service';
import { BaseAppError } from '../errors/base-app-error';
import { Observable, of } from 'rxjs';
import { IApiCommonResponse } from '../i-api-common-response';
import { IUsersDoRegisterResponse } from './i-users-do-register-response';
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { IUsersDoPasswordRecoveryResponse } from './i-users-do-password-recovery-response';
import { AuthService } from '../auth/auth.service';
import { IUsersGetProfileResponse } from './i-users-get-profile-response';
import { UserProfileModel } from '../../models/user-profile.model';
import { IUsersGetMessagesResponse, IUsersGetMessageResponseMessageItem } from './i-users-get-messages-response';
import { UserMessagesModel } from 'src/app/models/user-messages.model';
import { UserMessageModel } from 'src/app/models/user-message.model';
import { UserMessageCarModel } from 'src/app/models/user-message-car.model';
import { UserMessageDealerModel } from 'src/app/models/user-message-dealer.model';
import { IUsersGetMessagesCountResponse } from './i-uses-get-messages-count-response';
import { IUsersValidatePhoneResponse } from './i-users-validate-phone-response';
import { PhoneValidatedModel } from '../../models/phone-validated.model';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'users.service.ts',
  className: 'UsersService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();
const _HTTP_SUCESS_RESPONSE = 200;


/**
 * Users Service.
 */
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private baseURL = `${_CONFIG.apiBaseURL}/toyotausados/user`;
  private baseURLUserTCFA = `${_CONFIG.apiBaseURL}/user`;
  private baseURLUsuario = `${_CONFIG.apiBaseURL}/toyotausados/usuario`;

  private getUserMessagesCountThrottle: Observable<number | BaseAppError>;
  private getUserProfileThrottle: Observable<UserProfileModel | BaseAppError>;

  constructor(
    private http: HttpClient,
    private errorsService: ErrorsService,
    private authService: AuthService
  ) {
  }

  /**
   * User Register operation.
   */
  public doRegister(name: string, surname: string, email: string, repeatEmail: string, password: string,
                    repeatPassword: string, cellphone: string, redirectUrl: string): Observable<boolean | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'doRegister'
    });

    __logger.info('Method start.');

    const endpointURL = `${this.baseURLUsuario}/register`;
    const payload = {
      nombre: name,
      apellido: surname,
      email,
      repeated_email: repeatEmail,
      password,
      repeated_password: repeatPassword,
      celular: cellphone,
      redirect_url: redirectUrl
    };
    __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);

    return this.http.post<IApiCommonResponse<IUsersDoRegisterResponse>>(endpointURL, payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<IUsersDoRegisterResponse>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response. Get User data.
        map((response: HttpResponse<IApiCommonResponse<IUsersDoRegisterResponse>>) => response.ok),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }

  /**
   * User Password Recovery operation.
   */
  public doPasswordRecovery(email: string): Observable<boolean | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'doPasswordRecovery'
    });
    __logger.info('Method start.');

    const endpointURL = `${this.baseURLUsuario}/reset/password`;
    const payload = {
      email
    };
    __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);

    return this.http.post<IApiCommonResponse<IUsersDoPasswordRecoveryResponse>>(endpointURL, payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<IUsersDoPasswordRecoveryResponse>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response. Get User data.
        map((response: HttpResponse<IApiCommonResponse<IUsersDoPasswordRecoveryResponse>>) => response.ok),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }

  /**
   * User Password Change operation.
   */
  public doPasswordChange(password: string, passwordConfirm: string, token: string): Observable<boolean | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'Password'
    });
    __logger.info('Method start.');

    const endpointURL = `${this.baseURLUserTCFA}/password/change`;
    const payload = {
      password: btoa(password),
      passwordConfirm: btoa(passwordConfirm),
      token
    };
    __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);

    return this.http.post<IApiCommonResponse<any>>(endpointURL, payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<any>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response. Get User data.
        map((response: HttpResponse<IApiCommonResponse<any>>) => response.ok),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }

  /**
   * Gets User Profile data.
   */
  public getUserProfile(): Observable<UserProfileModel | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getUserProfile'
    });
    __logger.info('Method start.');

    if (!this.authService.isUserLoggedIn()) {
      return of(this.errorsService.getAppError('Usuario no logueado.'));
    }

    const endpointURL = `${this.baseURLUsuario}/profile`;
    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

    if (!this.getUserProfileThrottle) {
      this.getUserProfileThrottle = this.http.get<IApiCommonResponse<IUsersGetProfileResponse>>(endpointURL, {
        observe: 'response',
        headers: this.authService.getAuthorizationHeaders()
      })
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<IUsersGetProfileResponse>>) => {
            __logger.debug('API response:', response);
          }),
          // Parse response. Get User Profile data.
          map((response: HttpResponse<IApiCommonResponse<IUsersGetProfileResponse>>) =>
            new UserProfileModel({
              id: response.body.data.id,
              name: response.body.data.nombre,
              surname: response.body.data.apellido,
              email: response.body.data.email,
              origin: response.body.data.origen,
              location: response.body.data.localidad,
              cellphone: response.body.data.celular,
              cellphonePrefix: response.body.data.prefijo_celular,
              cellphoneValidated: response.body.data.celular_validado
            })),
          tap((data: UserProfileModel) => {
            __logger.debug('Setting UserData Profile:', data);
            this.authService.getUserData().profile = data;
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.authService.checkSessionError(error)))
        );
    }
    this.getUserProfileThrottle.subscribe(null, null, () => this.getUserProfileThrottle = null);
    return this.getUserProfileThrottle;
  }

  /**
   * Updates User Profile data.
   */
  public updateUserProfile(name: string, surname: string, email: string, password: string = null, passwordConfirm: string = null,
                           location: string = null, cellphone: string = null): Observable<boolean | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'updateUserProfile'
    });
    __logger.info('Method start.');

    if (!this.authService.isUserLoggedIn()) {
      return of(this.errorsService.getAppError('Usuario no logueado.'));
    }

    const endpointURL = `${this.baseURLUsuario}/profile`;
    let payload: any = {
      nombre: name,
      apellido: surname,
      email
    };
    if (!!password && !!passwordConfirm) {
      payload.password = password;
      payload.repeated_password = passwordConfirm;
    }
    if (!!location) {
      payload.localidad = location;
    }
    if (!!cellphone) {
      payload.celular = cellphone;
    }
    __logger.debug('Calling API.', 'Endpoint:', `PATCH ${endpointURL}`, 'Payload:', payload);

    return this.http.patch<IApiCommonResponse<any>>(endpointURL, payload, {
      observe: 'response',
      headers: this.authService.getAuthorizationHeaders()
    })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<any>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response. Return success flag.
        map((response: HttpResponse<IApiCommonResponse<any>>) => response.ok),
        // Error handler.
        catchError(error => of(this.authService.checkSessionError(error)))
      );
  }

  /**
   * Gets User messages
   */
  public getUserMessages(): Observable<UserMessagesModel[] | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getUserMessages'
    });
    __logger.info('Method start.');

    if (!this.authService.isUserLoggedIn()) {
      return of(this.errorsService.getAppError('Usuario no logueado.'));
    }

    const endpointURL = `${this.baseURL}/consulta`;
    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

    return this.http.get<IApiCommonResponse<IUsersGetMessagesResponse[]>>(endpointURL, {
      observe: 'response',
      headers: this.authService.getAuthorizationHeaders()
    })
      .pipe(
        //Log operation.
        tap((response: HttpResponse<IApiCommonResponse<IUsersGetMessagesResponse[]>>) => {
          __logger.debug('API response:', response);
        }),
        //Parse response. Get User Messages data
        map((response: HttpResponse<IApiCommonResponse<IUsersGetMessagesResponse[]>>) => this.createUserPublicationMessagesList(response.body.data)),
        //Error handler
        catchError(error => of(this.authService.checkSessionError(error)))
      );
  }

  /**
   * Gets User message count
   */
  public getUserMessagesCount(): Observable<number | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getUserMessageCount'
    });
    __logger.info('Method start.');

    if (!this.authService.isUserLoggedIn()) {
      return of(this.errorsService.getAppError('Usuario no logueado.'));
    }

    if (!this.getUserMessagesCountThrottle) {
      const endpointURL = `${this.baseURL}/consulta/count`;
      __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

      this.getUserMessagesCountThrottle = this.http.get<IApiCommonResponse<IUsersGetMessagesCountResponse>>(endpointURL, {
        observe: 'response',
        headers: this.authService.getAuthorizationHeaders()
      })
        .pipe(
          //Log operation.
          tap((response: HttpResponse<IApiCommonResponse<IUsersGetMessagesCountResponse>>) => {
            __logger.debug('API response:', response);
          }),
          //Parse response. Get User Messages data
          map((response: HttpResponse<IApiCommonResponse<IUsersGetMessagesCountResponse>>) => response.body.data.amount),
          // Replay last response.
          publishReplay(1),
          refCount(),
          //Error handler
          catchError(error => of(this.authService.checkSessionError(error)))
        );
    }
    this.getUserMessagesCountThrottle.subscribe(null, null, () => this.getUserMessagesCountThrottle = null);
    return this.getUserMessagesCountThrottle;
  }

  /**
   * Set user message as read
   * @param id_consulta Id del mensaje
   */
  public readUserMessage(id_consulta: number): Observable<boolean | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'readUserMessage'
    });
    __logger.info('Method start.');

    const endpointURL = `${this.baseURL}/consulta/read`;
    const payload = {
      consulta_id: id_consulta
    };
    __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);

    return this.http.post<IApiCommonResponse<any>>(endpointURL, payload, {
      observe: 'response',
      headers: this.authService.getAuthorizationHeaders()
    })
      .pipe(
        //Log operation.
        tap((response: HttpResponse<IApiCommonResponse<any>>) => {
          __logger.debug('API response:', response);
        }),
        //Parse response. Read user message.
        map((response: HttpResponse<IApiCommonResponse<any>>) => response.ok),
        //Error handler
        catchError(error => of(this.authService.checkSessionError(error)))
      );
  }

  private createUserPublicationMessagesList(userMessageInterfaceAPI: IUsersGetMessagesResponse[]): UserMessagesModel[] {
    return userMessageInterfaceAPI.map((userMessagePublication: IUsersGetMessagesResponse) => {
      let messagesByPublication: UserMessageModel[] = this.createUserMessagesList(userMessagePublication);

      const concesionario = userMessagePublication.concesionario ? userMessagePublication.concesionario : userMessagePublication.publicacion.concesionario;

      // Creating dealer entity.
      let dealer: UserMessageDealerModel = new UserMessageDealerModel(
        concesionario.id,
        concesionario.nombre,
        concesionario.razon_social,
        concesionario.cuit,
        concesionario.external_id,
        concesionario.email,
        `${_CONFIG.dealerImagesContextUrl}/${concesionario.cuit}/logo.png`
      );

      if (userMessagePublication.publicacion) {
        // Creating car entity.
        let car: UserMessageCarModel = new UserMessageCarModel(
          userMessagePublication.publicacion.auto.version.modelo.marca.mar_descripcion_s,
          userMessagePublication.publicacion.auto.version.modelo.mod_descripcion_s,
          userMessagePublication.publicacion.auto.version.ver_descripcion_s,
          userMessagePublication.publicacion.auto.year,
          userMessagePublication.publicacion.auto.price,
          userMessagePublication.publicacion.auto.kms,
          userMessagePublication.publicacion.auto.car_images
            .map(i => `${_CONFIG.publicationsImagesContextUrl}/${userMessagePublication.publicacion_id}/${i.url}`)
        );

        // Loading messagePublication to array.
        return new UserMessagesModel(userMessagePublication.id, userMessagePublication.publicacion_id,
          userMessagePublication.publicacion.estado, messagesByPublication, userMessagePublication.unread, car, dealer,
          userMessagePublication.created_at, !!userMessagePublication.is_certificado);
      } else {
        return new UserMessagesModel(userMessagePublication.id, undefined, undefined, messagesByPublication,
          userMessagePublication.unread, undefined, dealer, userMessagePublication.created_at, undefined, userMessagePublication.titulo,
          userMessagePublication.pedido_publicacion_id);
      }
    });
  }

  private createUserMessagesList(userMessagePublication: IUsersGetMessagesResponse): UserMessageModel[] {
    let messagesByPublication: UserMessageModel[] = [];

    messagesByPublication.push(new UserMessageModel(
      userMessagePublication.id,
      '',
      userMessagePublication.nombre,
      userMessagePublication.texto,
      userMessagePublication.email,
      userMessagePublication.visto,
      userMessagePublication.created_at,
      userMessagePublication.estado,
      userMessagePublication.celular,
      false,
      userMessagePublication.telefono
    ));

    const concesionario = userMessagePublication.concesionario ? userMessagePublication.concesionario : userMessagePublication.publicacion.concesionario;

    if (userMessagePublication.respuesta_permitida !== null) {
      messagesByPublication.push(new UserMessageModel(
        userMessagePublication.respuesta_permitida.id,
        '',
        concesionario.razon_social,
        userMessagePublication.respuesta_permitida.texto,
        '',
        userMessagePublication.respuesta_permitida.visto,
        userMessagePublication.respuesta_permitida.created_at,
        '',
        '',
        true,
        ''
      ));
    }

    userMessagePublication.nuevas_consultas
      .forEach((messageItem: IUsersGetMessageResponseMessageItem) => {
        messagesByPublication.push(new UserMessageModel(
          messageItem.id,
          messageItem.consulta_previa_id,
          messageItem.nombre,
          messageItem.texto,
          messageItem.email,
          messageItem.visto,
          messageItem.created_at,
          messageItem.estado,
          messageItem.celular,
          false,
          messageItem.telefono
        ));

        if (messageItem.respuesta_permitida !== null) {
          messagesByPublication.push(new UserMessageModel(
            messageItem.respuesta_permitida.id,
            '',
            concesionario.razon_social,
            messageItem.respuesta_permitida.texto,
            '',
            messageItem.respuesta_permitida.visto,
            messageItem.respuesta_permitida.created_at,
            '',
            '',
            true,
            ''
          ));
        }
      });

    return messagesByPublication.sort((a, b) => new Date(a.fechaCreacion).getTime() - new Date(b.fechaCreacion).getTime());
  }

  /**
   * Updates User's profile Avatar image.
   */
  public doUpdateProfileAvatar(profileImage: File): Observable<boolean | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'doUpdateProfileAvatar'
    });
    __logger.info('Method start.');

    if (!this.authService.isUserLoggedIn()) {
      return of(this.errorsService.getAppError('Usuario no logueado.'));
    }

    const endpointURL = `${this.baseURLUsuario}/profileImage`;
    const payload = new FormData();
    payload.append('profile_image', profileImage, profileImage.name);
    __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);

    return this.http.post<IApiCommonResponse<any>>(endpointURL, payload, {
      observe: 'response',
      headers: this.authService.getAuthorizationHeaders(true),
      reportProgress: true
    })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<any>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response. Get User data.
        map((response: HttpResponse<IApiCommonResponse<any>>) => response.ok),
        // Error handler.
        catchError(error => of(this.authService.checkSessionError(error)))
      );
  }

  public validatePhone(phone: string): Observable<PhoneValidatedModel | BaseAppError> {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'validatePhone'
    });
    _logger.info('Method start.');

    const endpointURL = `${_CONFIG.apiBaseURL}/usuario/telefono/validar`;
    const payload = {
      telefono: phone
    };
    _logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);

    return this.http.post<IApiCommonResponse<IUsersValidatePhoneResponse>>(endpointURL, payload, {
      observe: 'response',
      headers: this.authService.getAuthorizationHeaders(true),
      reportProgress: true
    })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<IUsersValidatePhoneResponse>>) => {
          _logger.debug('API response:', response);
        }),
        // Parse response. Get User data.
        map((response: HttpResponse<IApiCommonResponse<IUsersValidatePhoneResponse>>) => new PhoneValidatedModel(
          (response.body.code === _HTTP_SUCESS_RESPONSE),
          response.body.data.message,
          String(response.body.data.codigo_area) + String(response.body.data.telefono)
        )),
        // Error handler.
        catchError(error => of(this.authService.checkSessionError(error)))
      );
  }
}
