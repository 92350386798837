export class PhoneValidatedModel {
  public valid: boolean;
  public message: string;
  public phone: string;

  constructor(valid: boolean, message: string, phone: string) {
    this.valid = valid;
    this.message = message;
    this.phone = phone;
  }
}
