import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingModalComponent } from '../../modules/main/components/loading-modal/loading-modal.component';
import { ErrorModalComponent } from '../../modules/main/components/error-modal/error-modal.component';
import { AlertModalComponent } from '../../modules/main/components/alert-modal/alert-modal.component';
import { SuccessModalComponent } from '../../modules/main/components/success-modal/success-modal.component';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'modals.service.ts',
  className: 'ModalsService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


export enum ModalType {
  LOADING = 0,
  ERROR = 1,
  ALERT = 2,
  SUCCESS = 3
}


/**
 * Modals Service.
 */
@Injectable({
  providedIn: 'root'
})
export class ModalsService {
  constructor(
    private modalService: NgbModal
  ) {
  }

  private showModal(modalType: ModalType, message?: string, title?: string): Promise<NgbModalRef> {
    let modalContent: any;
    let modalWindowClassSuffix: string;
    let keyboard: boolean;

    switch (modalType) {
      case ModalType.LOADING:
        modalContent = LoadingModalComponent;
        modalWindowClassSuffix = 'loading';
        keyboard = false;
        break;
      case ModalType.ERROR:
        modalContent = ErrorModalComponent;
        modalWindowClassSuffix = 'error';
        keyboard = true;
        break;
      case ModalType.SUCCESS:
        modalContent = SuccessModalComponent;
        modalWindowClassSuffix = 'success';
        keyboard = true;
        break;
      default:
        modalContent = AlertModalComponent;
        modalWindowClassSuffix = 'alert';
        keyboard = false;
    }

    return new Promise<NgbModalRef>(resolve => {
      setTimeout(() => {
        const modal = this.modalService.open(modalContent, {
          ariaLabelledBy: 'modal-basic-title',
          windowClass: `modal-common ${modalWindowClassSuffix}-modal modal-animate`,
          keyboard,
          backdrop: (!keyboard && 'static') || true,
          centered: true
        });
        if (message) {
          modal.componentInstance.message = message;
        }
        if (title) {
          modal.componentInstance.title = title;
        }
        resolve(modal);
      });
    });
  }

  public showLoadingModal(message?: string): Promise<NgbModalRef> {
    return this.showModal(ModalType.LOADING, message);
  }

  public showErrorModal(message: string): Promise<NgbModalRef> {
    return this.showModal(ModalType.ERROR, message);
  }

  public showAlertModal(message: string, title: string): Promise<NgbModalRef> {
    return this.showModal(ModalType.ALERT, message, title);
  }

  public showSuccessModal(message: string, title: string): Promise<NgbModalRef> {
    return this.showModal(ModalType.SUCCESS, message, title);
  }

  public showCustomModal(modalContent: any, additionalClasses?: string, size: 'sm' | 'lg' = 'sm', centered: boolean = true,
    ariaLabelledBy = 'modal-basic-title', keyboard = true, backdrop: boolean | 'static' = true): NgbModalRef {
    return this.modalService.open(modalContent, {
      ariaLabelledBy,
      windowClass: 'modal-custom modal-animate' + (additionalClasses ? ' ' + additionalClasses : ''),
      keyboard,
      backdrop,
      size,
      centered
    });
  }
}
