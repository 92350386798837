import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SecurityContext, ViewChild} from '@angular/core';
import {LegalsService} from '../../../../services/legals/legals.service';
import {LoggerContext, SimpleLogger} from '../../../../shared/simple-logger.shared';
import {BaseAppError} from '../../../../services/errors/base-app-error';
import {ILegalsGetTextsResponse} from '../../../../services/legals/i-legals-get-texts-response';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer} from '@angular/platform-browser';
import {ModalsService} from '../../../../services/modals/modals.service';
import {Router} from '@angular/router';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'page-footer.component.ts',
  className: 'PageFooterComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');


@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {
  @Input() disableAll = false;

  @Output()
  public openAdvancedSearch = new EventEmitter<any>();

  @ViewChild('tycModalContent') protected tycModalContent: ElementRef;
  public tycModal: NgbModalRef;
  public pdpModal: NgbModalRef;

  public loadingLegalTexts: boolean;
  public termsAndConditions: string;
  public privacyPolicies: string;
  public displayTermsAndConditionsText: boolean;

  constructor(
    private modalsService: ModalsService,
    private legalsService: LegalsService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
  }

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
    const logger = _LOGGER.getDerivedContext({methodName: 'ngOnInit'});
    logger.info('Method start.');
    this.getLegalTexts();
  }

  private getLegalTexts(): void {
    const logger = _LOGGER.getDerivedContext({methodName: 'getLegalTexts'});
    logger.info('Method start.');

    this.loadingLegalTexts = true;
    this.legalsService.getTexts().subscribe((resultOrError: ILegalsGetTextsResponse | BaseAppError) => {
      if (!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.error('getTexts resulted in error.');
        logger.errorVerbose('Error:', resultOrError);
      } else {
        logger.debugVerbose('getTexts success.');
        this.termsAndConditions = this.sanitizer.sanitize(SecurityContext.HTML, resultOrError.TERMINOS_CONDICIONES);
        this.privacyPolicies = this.sanitizer.sanitize(SecurityContext.HTML, resultOrError.POLITICAS_PRIVACIDAD);
      }
    }, (error: any) => {
      logger.error('Error on getTexts.');
      logger.errorVerbose('Error:', error);
    }, () => {
      this.loadingLegalTexts = false;
    });
  }

  private openLegalTextModal(): void {
    this.tycModal = this.modalsService.showCustomModal(this.tycModalContent, 'tycModal', 'lg');
  }

  public onOpenTyC(): void {
    if (this.disableAll) {
      return;
    }

    this.displayTermsAndConditionsText = true;
    this.openLegalTextModal();
  }

  public onOpenPdP(): void {
    if (this.disableAll) {
      return;
    }
    this.openLegalTextModal();
  }

  public onClickYourNextToyota(): void {
    if (this.router.url === '/home') {
      this.openAdvancedSearch.emit();
    } else {
      this.router.navigate(['/home'], {state: {openSearchModal: true}});
    }
  }
}
