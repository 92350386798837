import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../../../shared/simple-logger.shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISellData } from 'src/app/services/sell/i-sell-data';
import {DomSanitizer} from '@angular/platform-browser';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'terminos-condiciones-modal.component.ts',
  className: 'TerminosCondicionesModalComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');


/**
 * Modal used to display success messages to the user.
 */
@Component({
  selector: 'app-terminos-condiciones-modal',
  templateUrl: './terminos-condiciones-modal.component.html',
  styleUrls: ['./terminos-condiciones-modal.component.scss']
})
export class TerminosCondicionesModalComponent implements OnInit {
  @Input() text: string;

  constructor(
    public activeModal: NgbActiveModal,
    private domSanitizer : DomSanitizer
  ) {
  }


  /* Handlers. */

  ngOnInit() {
    this.text = this.domSanitizer.sanitize(SecurityContext.HTML , this.text);
  }

  /**
   * Closes modal.
   */
  protected onAccept(): void {
    this.activeModal.close('Accept');
  }


  /**
   * Dismiss modal.
   */
   protected onCancel(): void {
    this.activeModal.dismiss('Cancel');
  }
}
