export class PageDataModel<T> {
  public totalItems: number;
  public itemsPerPage: number;
  public firstItemInPage: number;
  public lastItemInPage: number;
  public firstPage: number;
  public currentPage: number;
  public lastPage: number;
  public items: T[];

  constructor(totalItems: number, itemsPerPage: number, firstItemInPage: number, lastItemInPage: number,
              firstPage: number, currentPage: number, lastPage: number, items: T[]) {
    this.totalItems = totalItems;
    this.itemsPerPage = itemsPerPage;
    this.firstItemInPage = firstItemInPage;
    this.lastItemInPage = lastItemInPage;
    this.firstPage = firstPage;
    this.currentPage = currentPage;
    this.lastPage = lastPage;
    this.items = items;
  }
}
