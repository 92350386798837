import { IEnvironmentConfiguration } from '../app/shared/environment-manager.shared';
import { LOGLEVEL } from '../app/shared/simple-logger.shared';
import { environment as envQA } from './environment.qa';


export const environment: IEnvironmentConfiguration = {
  // Same as env.QA:
  apiBaseURL: envQA.apiBaseURL,
  appId: envQA.appId,
  brokerSegurosURL: envQA.brokerSegurosURL,
  ventaUsadosURL: envQA.ventaUsadosURL,
  googleMapAK: envQA.googleMapAK,
  googleAnalyticsId: envQA.googleAnalyticsId,
  publicationsImagesContextUrl: envQA.publicationsImagesContextUrl,
  profilesImagesContextUrl: envQA.profilesImagesContextUrl,
  dealerImagesContextUrl: envQA.dealerImagesContextUrl,
  defaultOperationTypeCode: envQA.defaultOperationTypeCode,
  linkedinClientId: envQA.linkedinClientId,

  // Override:
  frontDomains: ['localhost:4200'],
  logLevel: LOGLEVEL.VERBOSE,
  widgetCotizadorUrl: 'http://local.www.poc/sdk/sdk.js',
  widgetCotizadorEnvName: 'DEV',
  widgetCotizadorTheme: 'tcfautos',
  facebookConfig: {
    appId: '1745266399076401',
    hash: '085e61471f6f0181c0ae9066495b4e2d',
    version: 'v2.6'
  },
  googleAuthConfig: {
    clientId: '823949072627-769p880beq6f731su74nf9v9h3qi2ss9.apps.googleusercontent.com',
    scopes: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/plus.me https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/userinfo.profile'
  }
};
