import { PublicationDealerExtraInfoModel } from "./publication-dealer-extra-info.model";


export class UserMessageDealerModel {
  public id: number;
  public name: string;
  public businessName: string;
  public cuit: string;
  public externalId: string;
  public email: string;
  public extraInfo: PublicationDealerExtraInfoModel;
  public imgUrl: string;

  public constructor(id: number, name: string, businessName: string, cuit: string,
                     externalId: string, email: string, imgUrl: string) {
    this.id = id;
    this.name = name;
    this.businessName = businessName;
    this.cuit = cuit;
    this.externalId = externalId;
    this.email = email;
    this.imgUrl = imgUrl;
  }
}
