import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IGoogleGetReverseGeocodingResponse } from './i-google-get-reverse-geocoding-response';
import { ErrorsService } from '../errors/errors.service';
import { BaseAppError } from '../errors/base-app-error';


declare var window;


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'google-map.service.ts',
  className: 'GoogleMapService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


const _GOOGLE_MAP_CALLBACK_FUNC = '__onGoogleLoaded';
const _GOOGLE_SCRIPT_LOAD_TIMEOUT = 2 * 60 * 1000; // 2'.
const _TIMEOUT_FOR_GETTING_USER_POSITION = 2 * 60 * 1000; // 2'


/**
 * Google Map Service. This Service loads Google Map SDK Script into DOM and also asks the User to share its geo-location.
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {
  private baseURL = `https://maps.googleapis.com/maps/api`;

  private googleMapSdk: any;
  private googleMapSdkPromise: Promise<any>;

  constructor(
    private http: HttpClient,
    private errorsService: ErrorsService
  ) {
  }

  /**
   * Returns the Google Map SDK after it is loaded.
   */
  public getGoogleMapSdk(): Promise<any> {
    if (this.googleMapSdk) {
      return Promise.resolve<any>(this.googleMapSdk);
    }
    return this.loadGoogleMapApi();
  }

  /**
   * Loads Google Map API.
   */
  private loadGoogleMapApi(): Promise<any> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'loadGoogleMapApi'
    });
    __logger.info('Method start.');

    if (!this.googleMapSdkPromise) {
      let __googleSdkLoaded: boolean = false;

      this.googleMapSdkPromise = new Promise<any>((resolve, reject) => {
        const __onLoadScriptError = (reason: string) => {
          __logger.debug('Cannot load Google Maps Script.', reason);
          reject(`Cannot load Google Map Script (${reason}).`);
        };

        // Google Callback function.
        window[_GOOGLE_MAP_CALLBACK_FUNC] = () => {
          __logger.debugVerbose('Google Maps API callback start.');
          if (window && window.google && window.google.maps) {
            __logger.debug('Google Maps API resolved.');
            __googleSdkLoaded = true;
            resolve(window.google.maps);
          } else {
            __onLoadScriptError('other');
          }
        };

        // Timeout error function.
        setTimeout(() => {
          if (!__googleSdkLoaded) {
            __onLoadScriptError('timeout');
          }
        }, _GOOGLE_SCRIPT_LOAD_TIMEOUT);

        // Append Google script to the DOM.
        const __node = document.createElement('script');
        __node.src = `${this.baseURL}/js?key=${_CONFIG.googleMapAK}&callback=${_GOOGLE_MAP_CALLBACK_FUNC}`;
        __node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(__node);
      });
    }
    return this.googleMapSdkPromise;
  }

  public getUserPosition(enableHighAccuracy: boolean = true): Promise<Position> {
    return new Promise<Position>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        // Success.
        (userPosition: Position) => {
          resolve(userPosition);
        },
        // Error.
        (error: PositionError) => {
          reject(error);
        },
        {
          enableHighAccuracy: enableHighAccuracy,
          timeout: _TIMEOUT_FOR_GETTING_USER_POSITION
        }
      );
    });
  }

  public getReverseGeocoding(latitude: number, longitude: number): Observable<IGoogleGetReverseGeocodingResponse | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getReverseGeocoding'
    });
    __logger.info('Method start.');

    // Check cache.
    const endpointURL = `${this.baseURL}/geocode/json?latlng=${latitude},${longitude}&key=${_CONFIG.googleMapAK}`;
    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

    return this.http.get<IGoogleGetReverseGeocodingResponse>(endpointURL, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IGoogleGetReverseGeocodingResponse>) => {
          __logger.debug('API response:', response);
        }, (error: any) => {
          __logger.error('API Error response:', error);
        }),
        // Parse response.
        map((response: HttpResponse<IGoogleGetReverseGeocodingResponse>) => response.body),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }
}
