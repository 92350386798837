import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { DependencyInjectionService } from '../dependency-injection/dependency-injection.service';
import { AuthService } from '../auth/auth.service';
import { ErrorsService } from '../errors/errors.service';
import { UserModel } from '../../models/user.model';
import { BaseAppError } from '../errors/base-app-error';

declare let gapi;

const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'google-auth.service.ts',
  className: 'GoogleAuthService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();
const _METHOD_START = 'Method start.';


/**
 * Auth. Service.
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {
  private auth2: any;

  constructor(
    private readonly dependencyInjection: DependencyInjectionService,
    private readonly authService: AuthService,
    private readonly errorsService: ErrorsService
  ) {
  }

  /**
   * Load & Init Facebook SDK.
   */
  public init(): Promise<void | BaseAppError> {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'init'
    });
    _logger.info(_METHOD_START);

    return this.dependencyInjection.loadGoogle()
      .then(() => {
        gapi.load('auth2', () => {
          this.auth2 = gapi.auth2.init({
              client_id: _CONFIG.googleAuthConfig.clientId,
              scope: _CONFIG.googleAuthConfig.scopes
          });
      });
        _logger.debug('Google SDK loaded.');
      })
      .catch((e: BaseAppError) => {
        _logger.error('Error loading Google SDK:', e);
        throw e;
      });
  }

  /**
   * LogIn via Google.
   */
  public login(): Promise<UserModel | BaseAppError> {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'login'
    });
    _logger.info(_METHOD_START);

    let loginResult;

    _logger.debug('Calling Google Login.');
    return Promise.resolve(this.auth2.signIn())
      .then(loginResponse => {
        if (!loginResponse || loginResponse.error) {
          _logger.error('Login response error:', loginResponse);
          throw new Error('Login response error');
        }

        loginResult = loginResponse;

        _logger.debug('Calling TCFAutos API Social Auth.');
        return this.authService.doSocialLogin('google', loginResult.getAuthResponse().id_token).toPromise();
      })
      .then(resultOrError => {
        const googlePicture = loginResult.getBasicProfile().getImageUrl();
        if (googlePicture) {

          this.authService.setUserDataImageUrl(`${googlePicture}0`); // img size * 10px
        }

        _logger.debug('Login via Google successful.');
        return resultOrError;
      })
      .catch(e => {
        _logger.error('Error on Google Login:', e);
        throw this.errorsService.getAppError(e);
      });
  }
}
