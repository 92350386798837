import { PublicationDealerExtraInfoModel } from "./publication-dealer-extra-info.model";


export class PublicationDealerModel {
  public id: number;
  public name: string;
  public cuit: string;
  public imgUrl: string;
  public extraInfo: PublicationDealerExtraInfoModel;

  constructor(id: number, name: string, cuit: string, imgUrl: string) {
    this.id = id;
    this.name = name;
    this.cuit = cuit;
    this.imgUrl = imgUrl;
  }
}
