import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { DependencyInjectionService } from '../dependency-injection/dependency-injection.service';
import { AuthService } from '../auth/auth.service';
import { ErrorsService } from '../errors/errors.service';
import { UserModel } from '../../models/user.model';
import { BaseAppError } from '../errors/base-app-error';

declare let FB;

const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'facebook.service.ts',
  className: 'FacebookService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();
const _METHOD_START = 'Method start.';


/**
 * Auth. Service.
 */
@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  constructor(
    private readonly dependencyInjection: DependencyInjectionService,
    private readonly authService: AuthService,
    private readonly errorsService: ErrorsService
  ) {
  }

  /**
   * Load & Init Facebook SDK.
   */
  public init(): Promise<void | BaseAppError> {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'init'
    });
    _logger.info(_METHOD_START);

    return this.dependencyInjection.loadFacebook()
      .then(() => {
        FB.init({
          appId: _CONFIG.facebookConfig.appId, //The app id of the web app
          cookie: true, // enable cookies to allow the server to access the session
          xfbml: true,  // parse social plugins on this page
          version: _CONFIG.facebookConfig.version // use graph api version 2.6
        });
        _logger.debug('Facebook SDK loaded.');
      })
      .catch((e: BaseAppError) => {
        _logger.error('Error loading Facebook SDK:', e);
        throw e;
      });
  }

  /**
   * LogIn via Facebook.
   */
  public login(): Promise<UserModel | BaseAppError> {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'login'
    });
    _logger.info(_METHOD_START);

    let loginResult, meResult;

    _logger.debug('Calling Facebook Login.');
    return this.facebookLoginPromise()
      .then(loginResponse => {
        loginResult = loginResponse;

        _logger.debug('Calling Facebook API to get Profile Image.');
        return this.facebookApiPromise('/me?fields=picture.type(large)');
      })
      .then(meResponse => {
        meResult = meResponse;

        _logger.debug('Calling TCFAutos API Social Auth.');
        return this.authService.doSocialLogin('facebook', loginResult.authResponse.accessToken).toPromise();
      })
      .then(resultOrError => {
        if (meResult.picture && meResult.picture.data) {
          this.authService.setUserDataImageUrl(meResult.picture.data.url);
        }

        _logger.debug('Login via Facebook successful.');
        return resultOrError;
      })
      .catch(e => {
        _logger.error('Error on Facebook Login:', e);
        throw this.errorsService.getAppError(e);
      });
  }

  private facebookLoginPromise(): Promise<object> {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'facebookLoginPromise'
    });
    _logger.info(_METHOD_START);

    return new Promise((resolve, reject) => {
      FB.login(function (response) {
        _logger.debug('Facebook login result', response);
        if (response.status === 'connected') {
          _logger.debug('Facebook user connected.');
          resolve(response);
        } else {
          _logger.error('User cancelled login or did not fully authorize.');
          reject(response);
        }
      }, { scope: 'email' });
    });
  }

  private facebookApiPromise(query: string): Promise<object> {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'facebookApiPromise'
    });
    _logger.info(_METHOD_START);

    return new Promise((resolve, reject) => {
      FB.api(query, function (response) {
        if (!response || response.error) {
          _logger.error('Error calling API:', response);
          reject(response);
        } else {
          _logger.debug('API result:', response);
          resolve(response);
        }
      });
    });
  }
}
