import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseAppError } from '../errors/base-app-error';
import { Observable, of } from 'rxjs';
import { IApiCommonResponse } from '../i-api-common-response';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { PhoneSendVerificationModel } from '../../models/phone-send-verification.model';
import { PhoneCheckVerificationModel } from '../../models/phone-check-verification.model';
import { IPhoneVerifySendCodeResponse } from './i-phone-verify-send-code-response';
import { IPhoneVerifyCheckCodeResponse } from './i-phone-verify-check-code-response';

const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'phone-verify.service.ts',
  className: 'PhoneVerifyService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


/**
 * Phone Verify Service.
 */
@Injectable({
  providedIn: 'root'
})
export class PhoneVerifyService {
  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService
  ) {
  }

  public sendVerificationCode(phone: string, email: string): Observable<PhoneSendVerificationModel | BaseAppError> {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'sendVerificationCode'
    });
    _logger.info('Method start.');

    const endpointURL = `${_CONFIG.apiBaseURL}/usuario/telefono/verification/send`;
    const payload = {
      telefono: phone,
      email
    };
    _logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);

    return this.http.post<IApiCommonResponse<IPhoneVerifySendCodeResponse>>(endpointURL, payload, {
      observe: 'response',
      headers: this.authService.getAuthorizationHeaders(true),
      reportProgress: true
    })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<IPhoneVerifySendCodeResponse>>) => {
          _logger.debug('API response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IApiCommonResponse<IPhoneVerifySendCodeResponse>>) => new PhoneSendVerificationModel(
          response.body.data.id,
          response.body.data.message,
        )),
        // Error handler.
        catchError(error => of(this.authService.checkSessionError(error)))
      );
  }

  public checkVerificationCode(verificationCode: string, smsId: number): Observable<PhoneCheckVerificationModel | BaseAppError> {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'checkVerificationCode'
    });
    _logger.info('Method start.');

    const endpointURL = `${_CONFIG.apiBaseURL}/usuario/telefono/verification/check`;
    const payload = {
      verification_code: verificationCode,
      sms_id: smsId
    };
    _logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);

    return this.http.post<IApiCommonResponse<IPhoneVerifyCheckCodeResponse>>(endpointURL, payload, {
      observe: 'response',
      headers: this.authService.getAuthorizationHeaders(true),
      reportProgress: true
    })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<IPhoneVerifyCheckCodeResponse>>) => {
          _logger.debug('API response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IApiCommonResponse<IPhoneVerifyCheckCodeResponse>>) => new PhoneCheckVerificationModel(
          response.body.data.verified,
          response.body.data.message,
        )),
        // Error handler.
        catchError(error => of(this.authService.checkSessionError(error)))
      );
  }
}
