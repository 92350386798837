import { environment } from '../../environments/environment';
import { environment as environmentProd } from '../../environments/environment.prod';
import { environment as environmentTest } from '../../environments/environment.test';
import { environment as environmentTest2 } from '../../environments/environment.test2';
import { environment as environmentTest3 } from '../../environments/environment.test3';
import { environment as environmentTest4 } from '../../environments/environment.test4';
import { environment as environmentTestQA } from '../../environments/environment.testqa';
import { environment as environmentQa } from '../../environments/environment.qa';
import { environment as environmentQa2 } from '../../environments/environment.qa2';
import { environment as environmentDev } from '../../environments/environment.dev';
import { LOGLEVEL } from './simple-logger.shared';


export interface IEnvironmentConfiguration {
  frontDomains: string[];
  apiBaseURL: string;
  publicationsImagesContextUrl: string;
  brokerSegurosURL: string;
  ventaUsadosURL: string;
  profilesImagesContextUrl: string;
  dealerImagesContextUrl: string;
  googleMapAK: string;
  googleAnalyticsId: string;
  logLevel: LOGLEVEL;
  widgetCotizadorUrl: string;
  widgetCotizadorEnvName: string;
  widgetCotizadorTheme: string;
  defaultOperationTypeCode: 'RE' | 'LE';
  facebookConfig: IFacebookConfiguration;
  linkedinClientId: string;
  googleAuthConfig: IGoogleAuthConfiguration;
  appId: number;
}

export interface ISharedConfiguration {
  i18nLocaleShort: string;
  i18nLocaleFull: string;
  decimalSeparator: string;
  milesSeparator: string;
  currencySymbol: string;
  vehicleYearsAgoToConsider: number;
  vehicleMileageMinValueToFilter: number;
  vehicleMileageMaxValueToFilter: number;
  vehiclePriceMinValueToFilter: number;
  vehiclePriceMaxValueToFilter: number;
  vehicleInstallmentsPriceMinValueToFilter: number;
  vehicleInstallmentsPriceMaxValueToFilter: number;
}

export interface IConfiguration extends IEnvironmentConfiguration, ISharedConfiguration {
  production: boolean;
  envName: string;
}

interface IFacebookConfiguration {
  appId: string;
  hash: string;
  version: string;
}

interface IGoogleAuthConfiguration {
  clientId: string;
  scopes: string;
}

const _SHARED_CONFIG: ISharedConfiguration = {
    i18nLocaleShort: 'es',
    i18nLocaleFull: 'es-AR',
    decimalSeparator: ',',
    milesSeparator: '.',
    currencySymbol: '$',
    vehicleYearsAgoToConsider: 15,
    vehicleMileageMinValueToFilter: 0,
    vehicleMileageMaxValueToFilter: 300_000,
    vehiclePriceMinValueToFilter: 0,
    vehiclePriceMaxValueToFilter: 50_000_000,
    vehicleInstallmentsPriceMinValueToFilter: 0,
    vehicleInstallmentsPriceMaxValueToFilter: 2_000_000
  },
  _PROD_ENV_NAME = 'PROD';


/**
 * <Singleton>
 *     Environment Manager.
 */
export class EnvironmentManager {
  private static instance: EnvironmentManager = new EnvironmentManager();

  private config: IConfiguration;

  private enabledEnvs: { name: string, env: IEnvironmentConfiguration }[];

  private constructor() {
    this.enabledEnvs = [
      {name: _PROD_ENV_NAME, env: environmentProd},
      {name: 'TEST', env: environmentTest},
      {name: 'TEST2', env: environmentTest2},
      {name: 'TEST3', env: environmentTest3},
      {name: 'TEST4', env: environmentTest4},
      {name: 'TESTQA', env: environmentTestQA},
      {name: 'QA', env: environmentQa},
      {name: 'QA2', env: environmentQa2},
      {name: 'DEV', env: environmentDev}
    ];
  }

  public static getInstance(): EnvironmentManager {
    if (!EnvironmentManager.instance) {
      EnvironmentManager.instance = new EnvironmentManager();
    }
    return EnvironmentManager.instance;
  }

  private buildConfig(): void {
    const config: any = {};

    // Get shared configuration.
    Object
      .keys(_SHARED_CONFIG)
      .forEach(key => config[key] = _SHARED_CONFIG[key]);

    // Get env configuration.
    const currentDomain = location.host;
    let envConfig: IEnvironmentConfiguration;
    // Matched environment.
    if (!this.enabledEnvs.some(eCfg => {
      const __match = eCfg.env.frontDomains.some(d => d === currentDomain);
      if (__match) {
        envConfig = eCfg.env;
        config.envName = eCfg.name;
      }
      return __match;
    })) {
      // Default environment.
      envConfig = environment;
      config.envName = 'DEFAULT';
    }
    config.production = (config.envName === _PROD_ENV_NAME);

    Object
      .keys(envConfig)
      .forEach(key => config[key] = envConfig[key]);

    this.config = config;
  }

  public getConfig(): IConfiguration {
    if (!this.config) {
      this.buildConfig();
    }
    return this.config;
  }
}
