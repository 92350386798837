import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from "../../shared/simple-logger.shared";
import { EnvironmentManager } from "../../shared/environment-manager.shared";


declare var window;


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'google-analytics.service.ts',
  className: 'GoogleAnalyticsService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


const _GOOGLE_ANALYTICS_OBJECT = 'GoogleAnalyticsObject';
const _GOOGLE_ANALYTICS_WINDOW_OBJ = 'ga';

/**
 * Google Analytics Service. This Service loads Google Analytics Script into DOM and triggers Analytics' basic integration.
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private baseURL = `https://www.google-analytics.com/analytics.js`;

  private scriptLoaded = false;

  constructor() {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'constructor'
    });
    __logger.infoVerbose('Created.');

    if (!this.scriptLoaded) {
      this.loadGoogleAnalytics();
    }
  }

  /**
   * Loads Google Analytics Script.
   */
  private loadGoogleAnalytics(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'loadGoogleAnalytics'
    });
    __logger.info('Method start.');

    if (!this.scriptLoaded && _CONFIG.googleAnalyticsId) {
      __logger.infoVerbose('Appending GA to DOM...', '; GA ID:', _CONFIG.googleAnalyticsId);
      // Append Google Analytics Script to the DOM.
      window[_GOOGLE_ANALYTICS_OBJECT] = _GOOGLE_ANALYTICS_WINDOW_OBJ;
      window[_GOOGLE_ANALYTICS_WINDOW_OBJ] = window[_GOOGLE_ANALYTICS_WINDOW_OBJ] || function () {
        (window[_GOOGLE_ANALYTICS_WINDOW_OBJ].q = window[_GOOGLE_ANALYTICS_WINDOW_OBJ].q || []).push(arguments);
      };
      const ga = window[_GOOGLE_ANALYTICS_WINDOW_OBJ];
      ga.l = +new Date();
      const __node = document.createElement('script');
      __node.async = true;
      __node.src = `${this.baseURL}`;
      const __firstScript = document.getElementsByTagName('script')[0];
      __firstScript.parentNode.insertBefore(__node, __firstScript);
      ga('create', _CONFIG.googleAnalyticsId, 'auto');
      ga('send', 'pageview');

      this.scriptLoaded = true;
    }
  }
}
