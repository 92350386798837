import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { BaseAppError } from '../errors/base-app-error';
import { ErrorsService } from '../errors/errors.service';

const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'dependency-injection.service.ts',
  className: 'DependencyInjectionService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _METHOD_START = 'Method start.';


/**
 * Auth. Service.
 */
@Injectable({
  providedIn: 'root'
})
export class DependencyInjectionService {

  constructor(
    private readonly errorsService: ErrorsService
  ) {
  }

  /**
   * Load Facebook API.
   */
  public loadFacebook(): Promise<void | BaseAppError> {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'loadFacebook'
    });
    _logger.info(_METHOD_START);

    return this.loadScript('https://connect.facebook.net/en_US/sdk.js');
  }

  /**
   * Load Google API.
   */
  public loadGoogle(): Promise<void | BaseAppError> {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'loadGoogle'
    });
    _logger.info(_METHOD_START);

    return this.loadScript('https://apis.google.com/js/client:platform.js');
  }

  /**
   * Dynamically javascript libraries loading.
   */
  private loadScript(url: string): Promise<void | BaseAppError> {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'loadScript'
    });
    _logger.info(_METHOD_START);

    return new Promise((resolve, reject) => {
      const onLoad = function (this: GlobalEventHandlers, ev: Event): void {
        _logger.debug('Script loaded:', url);
        resolve();
      };

      const onError = (eventOrMessage: Event | string): void => {
        _logger.error('Error during Script loading:', url, eventOrMessage);

        let error;
        if((eventOrMessage instanceof String) || (typeof eventOrMessage === 'string')){
          error = this.errorsService.getAppError(eventOrMessage);
        } else {
          error = this.errorsService.getAppError('Error during Script loading.');
        }

        reject(error);
      };

      try {
        const body = document.body as HTMLDivElement;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        script.onload = onLoad;
        script.onerror = onError;

        body.appendChild(script);
      } catch (e) {
        reject(this.errorsService.getAppError(e));
      }
    });
  }
}
