import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PersistenceService } from '../persistence/persistence.service';
import { ErrorsService } from '../errors/errors.service';
import { Observable, of } from 'rxjs';
import { BaseAppError } from '../errors/base-app-error';
import { ProvinceModel } from '../../models/province.model';
import { IApiCommonResponse } from '../i-api-common-response';
import { IProvincesGetProvincesResponseProvinceItem } from './i-provinces-get-provinces-response-province-item';
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'provinces.service.ts',
  className: 'ProvincesService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


const _CACHE_CONFIG = {
  GET_PROVINCES: {
    KEY: 'get_provinces',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  }
};


/**
 * Provinces Service.
 */
@Injectable({
  providedIn: 'root'
})
export class ProvincesService {
  private baseURL = `${_CONFIG.apiBaseURL}/toyotausados/provincias`;

  /** Cache data. */
  private cacheGetProvinces: Observable<ProvinceModel[] | BaseAppError>;

  constructor(
    private http: HttpClient,
    private persistenceService: PersistenceService,
    private errorsService: ErrorsService
  ) {
  }

  /**
   * Gets all Provinces.
   */
  public getProvinces(): Observable<ProvinceModel[] | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getProvinces'
    });
    __logger.info('Method start.');

    // Check cache.
    if (this.persistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_PROVINCES.KEY, _CACHE_CONFIG.GET_PROVINCES.AGE)) {
      const endpointURL = `${this.baseURL}`;
      __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

      this.cacheGetProvinces = this.http.get<IApiCommonResponse<IProvincesGetProvincesResponseProvinceItem[]>>(endpointURL, {observe: 'response'})
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<IProvincesGetProvincesResponseProvinceItem[]>>) => {
            __logger.debug('API response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<IApiCommonResponse<IProvincesGetProvincesResponseProvinceItem[]>>) => response.body.data.map(
            province => new ProvinceModel(province.id, province.name, province.provincia_id_fu))),
          // Store in cache.
          tap((data: ProvinceModel[]) => {
            this.persistenceService.store(_CACHE_CONFIG.GET_PROVINCES.KEY, data);
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetProvinces) {
        const cacheData: ProvinceModel[] = this.persistenceService.retrieve(_CACHE_CONFIG.GET_PROVINCES.KEY, _CACHE_CONFIG.GET_PROVINCES.AGE);
        this.cacheGetProvinces = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: ProvinceModel[]) => {
              __logger.debug('Cache response:', data);
            }),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorsService.getAppError(error)))
          );
      }
    }

    return this.cacheGetProvinces;
  }
}
