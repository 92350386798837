import {UserProfileModel} from './user-profile.model';


export class UserModel {
  public id: string;
  public username: string;
  public name: string;
  public surname: string;
  public clientId: number;
  public isNative: boolean;
  public isTasa: boolean;
  public adminUrl: string;
  public requiresChangePassword: boolean;
  public changePasswordToken: string;
  public imageUrl: string;
  public profile: UserProfileModel;

  constructor(id: string, username: string, name: string, surname: string, clientId: number, isNative: boolean,
              isTasa: boolean, adminUrl: string, requiresChangePassword: boolean, changePasswordToken: string,
              imageUrl: string) {
    this.id = id;
    this.username = username;
    this.name = name;
    this.surname = surname;
    this.clientId = clientId;
    this.isNative = isNative;
    this.isTasa = !!isTasa;
    this.adminUrl = adminUrl;
    this.requiresChangePassword = requiresChangePassword;
    this.changePasswordToken = changePasswordToken;
    this.imageUrl = imageUrl;
  }
}
