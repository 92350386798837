import {AbmModule} from './modules/abm/abm.module';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PublicModule} from './modules/public/public.module';
import {LoggerContext, SimpleLogger} from './shared/simple-logger.shared';
import {HttpClientModule} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeExtraEsAr from '@angular/common/locales/extra/es-AR';
import {EnvironmentManager} from './shared/environment-manager.shared';
import {YTPlayerModule} from 'angular-youtube-player';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'app.module.ts',
  className: 'AppModule',
  methodName: '',
  tagName: 'APP'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


const _LOCALES = {es: localeEs, 'es-AR': localeExtraEsAr};
registerLocaleData(_LOCALES[_CONFIG.i18nLocaleShort], _CONFIG.i18nLocaleFull, _LOCALES[_CONFIG.i18nLocaleFull]);


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    YTPlayerModule,
    HttpClientModule,
    PublicModule,
    AbmModule,
    AppRoutingModule
  ],
  providers: [],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
