import { IEnvironmentConfiguration } from '../app/shared/environment-manager.shared';
import { LOGLEVEL } from '../app/shared/simple-logger.shared';


export const environment: IEnvironmentConfiguration = {
  frontDomains: ['test2.toyotausados.com.ar'],
  apiBaseURL: 'https://test2api.tcfautos.com.ar/index.php',
  brokerSegurosURL: 'https://test2www.tcfaseguros.com.ar',
  ventaUsadosURL: 'https://dev.toyota.com.ar/venta-usados',
  appId: 2,
  publicationsImagesContextUrl: 'https://test2resources.tcfautos.com.ar/auto_images',
  profilesImagesContextUrl: 'https://test2resources.tcfautos.com.ar/user_images',
  dealerImagesContextUrl: 'https://test2resources.tcfautos.com.ar/dealer_images',
  googleMapAK: 'AIzaSyDsnM-k76MHzztHTcGJuwBS_Nvb6wMRMxE',
  googleAnalyticsId: '', // Format: 'UA-XXXXX-Y'.
  logLevel: LOGLEVEL.DEBUG,
  widgetCotizadorUrl: 'https://test2widgetcotizador.tcfautos.com.ar/sdk/sdk.js',
  widgetCotizadorEnvName: 'TEST2',
  widgetCotizadorTheme: 'tcfautos',
  defaultOperationTypeCode: 'RE',
  facebookConfig: {
    appId: '1862730357276773',
    hash: '8d6879942780892a21ae81e27a1809fe',
    version: 'v2.6'
  },
  linkedinClientId: '77afduyxtja3k9',
  googleAuthConfig: {
    clientId: '823949072627-769p880beq6f731su74nf9v9h3qi2ss9.apps.googleusercontent.com',
    scopes: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/plus.me https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/userinfo.profile'
  }
};
