import { Component, Input, OnInit } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../../../shared/simple-logger.shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISellData } from 'src/app/services/sell/i-sell-data';
import { BaseAppError } from 'src/app/services/errors/base-app-error';
import { UserActionService } from '../../../../services/user-action/user-action.service';
import { UserActionModel } from '../../../../models/user-action-send.model';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'send-solicitud-modal.component.ts',
  className: 'SendSolicitudModalComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');


/**
 * Modal used to display success messages to the user.
 */
@Component({
  selector: 'app-send-solicitud-modal',
  templateUrl: './send-solicitud-modal.component.html',
  styleUrls: ['./send-solicitud-modal.component.scss']
})
export class SendSolicitudModalComponent implements OnInit {
  @Input() data: ISellData;

  constructor(
    public activeModal: NgbActiveModal,
    private userActionService: UserActionService
  ) {
  }


  /* Handlers. */

  ngOnInit() {
  }

  /**
   * Closes modal.
   */
  protected onAccept(): void {
    this.activeModal.close('Accept');
  }


  /**
   * Dismiss modal.
   */
   protected onCancel(button: string): void {

    let actionData = ''; 
    if (button == 'Cancel') {
      actionData = 'ped_inspección_cancel'; 
    } else {
      actionData = 'ped_inspección_X';
    }
    this.sendUserActionSendSolicitudModal(actionData, 'NO_ok');

    this.activeModal.dismiss(button);
  }

  private sendUserActionSendSolicitudModal(action, data) {
    const logger = _LOGGER.getDerivedContext({methodName: 'sendUserActionSendSolicitudModal'});
    logger.info('Method start.');
    this.userActionService.sendUserAction(action, data).subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
  }
}
