import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { Observable, of } from 'rxjs';
import { ISessionSendDataResponse } from './i-session-send-data-response';
import { BaseAppError } from '../errors/base-app-error';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IApiCommonResponse } from '../i-api-common-response';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorsService } from '../errors/errors.service';

const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'session.service.ts',
  className: 'SessionService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');

const _CONFIG = EnvironmentManager.getInstance().getConfig();

/**
 * Session Service.
 */
@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private baseURLSession = `${_CONFIG.apiBaseURL}/session`;
  private sessionData: { [key: string]: any } = {};
  private appId = `${_CONFIG.appId}`;

  constructor(
    private http: HttpClient,
    private errorsService: ErrorsService
  ) {}

  /**
   * Stores data on Session.
   *
   * @param key
   * @param data
   */
  public setSessionData(key: string, data: any): void {
    this.sessionData[key] = data;
  }

  /**
   * Retrieves data from Session.
   *
   * @param key
   */
  public getSessionData(key: string): any {
    return this.sessionData[key];
  }

  /**
   * Send session data.
   */
  public sendSessionData(): Observable<ISessionSendDataResponse | BaseAppError> {

    const __logger = _LOGGER.getDerivedContext({
      methodName: 'sendSessionData'
    });
    __logger.info('Method start.');
    const payload = {
      tipo_aplicacion_id: this.appId
    };
    const endpointURL = `${this.baseURLSession}`;
    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

    return this.http.post<IApiCommonResponse<ISessionSendDataResponse>>(endpointURL, payload, { observe: 'response' })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<ISessionSendDataResponse>>) => {
          __logger.debug('API response:', response);
          //seteo session_token para utilizar en el auth.service para user-action.service
          this.setSessionData('session_token', response.body.data.session_token);
        }),
        // Parse response.
        map((response: HttpResponse<IApiCommonResponse<ISessionSendDataResponse>>) => response.body.data),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }

}
