import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { UserMessagesModel } from 'src/app/models/user-messages.model';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoggerContext, SimpleLogger } from 'src/app/shared/simple-logger.shared';
import { SessionService } from "../../../../services/session/session.service";
import { PublicationDealerExtraInfoModel } from "../../../../models/publication-dealer-extra-info.model";
import { DealerModel } from "../../../../models/dealer.model";
import { BaseAppError } from "../../../../services/errors/base-app-error";
import { DealersService } from "../../../../services/dealers/dealers.service";
import { ModalsService } from "../../../../services/modals/modals.service";
import { PublicationsService } from "../../../../services/publications/publications.service";
import { ErrorsService } from "../../../../services/errors/errors.service";
import { ViewportScroller } from "@angular/common";
import { UserMessageModel } from "../../../../models/user-message.model";


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'messages-page.component.ts',
  className: 'MessagesPageComponent',
  tagName: 'PAGES'
});
_LOGGER.debugVerbose('Loaded.');

@Component({
  selector: 'app-messages-page',
  templateUrl: './messages-page.component.html',
  styleUrls: ['./messages-page.component.scss']
})
export class MessagesPageComponent implements OnInit {
  public title: string;
  public subtitle: string;
  protected message: UserMessagesModel;
  protected newMessage: string;
  protected disableNewMessages: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private sessionService: SessionService,
    private dealersService: DealersService,
    private modalsService: ModalsService,
    private publicationsService: PublicationsService,
    private errorsService: ErrorsService,
    private viewportScroller: ViewportScroller
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.message = navigation.extras.state ? navigation.extras.state.message : null;
  }

  protected get userAvatarUrl(): string {
    if (this.authService.isUserLoggedIn()) {
      return this.authService.getUserData().imageUrl;
    }
    return null;
  }

  public ngOnInit():void  {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'ngOnInit'
    });
    __logger.info('Method start.');

    if (!this.authService.isUserLoggedIn()) {
      this.goToLoginPage();
    }

    // Get Dealer's extra info.
    if (this.message && !this.message.dealer.extraInfo) {
      this.message.dealer.extraInfo = new PublicationDealerExtraInfoModel(
        'Cargando...',
        'Cargando...',
        'Cargando...',
        'Cargando...'
      );

      const __onDealersGetMapFail = () => {
        this.message.dealer.extraInfo.address = '-';
        this.message.dealer.extraInfo.addressZone = '-';
        this.message.dealer.extraInfo.phone = '-';
        this.message.dealer.extraInfo.email = '-';
      };

      const __onDealersGetMapSuccess = (dealersMap: DealerModel[]) => {
        const dealer = dealersMap.find(d => d.id === this.message.dealer.id);
        if (dealer) {
          const dealerCentralBranch = dealer.branches.find(db => db.isCentralBranch);
          if (dealerCentralBranch) {
            this.message.dealer.extraInfo.address = dealerCentralBranch.address;
            this.message.dealer.extraInfo.addressZone = dealerCentralBranch.location;
            this.message.dealer.extraInfo.phone = dealerCentralBranch.phone;
            this.message.dealer.extraInfo.email = dealerCentralBranch.email;
          } else {
            __onDealersGetMapFail();
          }
        } else {
          __onDealersGetMapFail();
        }
      };

      this.dealersService.getMap()
        .subscribe(
          // Success response.
          (resultOrError: DealerModel[] | BaseAppError) => {
            if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.length) {
              __logger.error('Cannot resolve Dealers Map array.', resultOrError);
              __onDealersGetMapFail();
            } else {
              __logger.debug('Dealers Map initialized.');
              __onDealersGetMapSuccess(resultOrError);
            }
          },
          // Error response.
          (error: any) => {
            __logger.error('Error on get Dealers Map.', error);
            __onDealersGetMapFail();
          });
    }

    if (this.message && this.message.title) {
      const titleParts = this.message.title.split('–')[1].trim().split(',');
      this.title = titleParts[0].trim();
      this.subtitle = `${titleParts[1].trim()} / ${titleParts[2].trim()} / ${titleParts[3].trim()}`
    }
  }

  protected swiperMessages: SwiperConfigInterface = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };

  /**
   * Navs to Login Page.
   */
  private goToLoginPage(): void {
    this.sessionService.setSessionData('redirectAfterLogin', '/mesages');
    this.router.navigate(['/login']);
  }

  protected onErrorUserAvatar(newUrl: string): void {
    if (this.authService.isUserLoggedIn()) {
      this.authService.getUserData().imageUrl = newUrl;
    }
  }

  protected onMessageKeyPress(event: KeyboardEvent): void {
    if ((event.key === 'Enter') || (event.keyCode === 13)) {
      this.onNewMessage();
    }
  }

  /**
   * Shows errors to user.
   */
  private onError(error: any, navigate: boolean = false): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'onSignUpFail'
    });
    __logger.info('Method start.');

    const appError: BaseAppError = this.errorsService.getAppError(error);
    __logger.error('Error:', appError.getMessage());

    if (appError.getCode() === ErrorsService.getApiHttpErrorCode(401)) {
      this.authService.expireSession();
      navigate = true;
    }

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            __logger.debug('Error modal closed with result:', result);
          })
          .catch(reason => {
            __logger.debug('Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
            if (navigate) {
              this.goToLoginPage();
            }
          });
      });
  }

  protected onNewMessage(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'onNewMessage'
    });
    __logger.info('Method start.');

    if (this.authService.isUserLoggedIn() && this.newMessage && this.message && this.message.messageList && this.message.messageList.length && (this.message.messageList.length > 0)) {
      const loadingModal = this.modalsService.showLoadingModal('Enviando mensaje...');
      this.disableNewMessages = true;
      const firstMessage = this.message.messageList[0];

      let observable;
      if (this.message.car) {
        observable = this.publicationsService.sendMessage(parseInt(this.message.publicationId, 10), this.newMessage, firstMessage.celular, firstMessage.telefono);
      } else {
        observable = this.publicationsService.sendMessageForSolicitud(parseInt(this.message.pedidoPublicationId, 10), this.newMessage)
      }

      observable.subscribe((resultOrError: BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          __logger.error('sendMessage resulted in error.');
          __logger.errorVerbose('Error:', resultOrError);
          this.onError(resultOrError);
        } else {
          __logger.debugVerbose('sendMessage success.');
          this.message.messageList.push(new UserMessageModel(
            0, '0', firstMessage.nombre, this.newMessage, firstMessage.email, false, new Date(),
            '', firstMessage.celular, false, firstMessage.telefono
          ));
          this.newMessage = null;
        }
      }, (error: any) => {
        __logger.error('Error on sendMessage.');
        __logger.errorVerbose('Error:', error);
        this.onError(error);
      }, () => {
        this.disableNewMessages = false;
        loadingModal.then(modal => {
          modal.dismiss('done');
        });
      });
    } else {
      this.goToLoginPage();
    }
  }
}
