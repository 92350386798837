import { Component, OnInit } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../../../shared/simple-logger.shared';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PhoneVerifyService } from '../../../../services/phone-verify/phone-verify.service';
import { PhoneSendVerificationModel } from '../../../../models/phone-send-verification.model';
import { BaseAppError } from '../../../../services/errors/base-app-error';
import { PhoneCheckVerificationModel } from '../../../../models/phone-check-verification.model';
import { ModalsService } from '../../../../services/modals/modals.service';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'phone-verify-modal.component.ts',
  className: 'PhoneVerifyModalComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');

const _RESEND_WAIT = 300000,
  _CODE_LENGTH = 4,
  _MAX_ATTEMPS = 3;


/**
 * Modal used to verify the cellphone number.
 */
@Component({
  selector: 'app-phone-verify-modal',
  templateUrl: './phone-verify-modal.component.html',
  styleUrls: ['./phone-verify-modal.component.scss']
})
export class PhoneVerifyModalComponent implements OnInit {
  public email: string;
  public phone: string;

  protected codeSent: boolean;
  protected resendEnabled: boolean;
  protected verificationCode: string;
  private attempts: number;
  private verificationId: number;

  constructor(
    public activeModal: NgbActiveModal,
    public phoneVerifyService: PhoneVerifyService,
    public modalsService: ModalsService
  ) {
    this.attempts = 0;
    this.codeSent = false;
    this.resendEnabled = false;
    this.verificationCode = '';
  }


  /* Handlers. */

  ngOnInit() {
    this.sendCode();
  }

  private sendCode(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'sendCode'
    });
    _logger.info('Method start.');

    const loadingModal = this.modalsService.showLoadingModal('Enviando...');
    this.phoneVerifyService.sendVerificationCode(this.phone, this.email)
      .subscribe((resultOrError: PhoneSendVerificationModel | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('sendVerificationCode resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          this.activeModal.dismiss({ reason: 'error', sent: false, verified: false, error: resultOrError });
        } else {
          _logger.debugVerbose('sendVerificationCode success.');
          this.codeSent = true;
          this.verificationId = resultOrError.id;

          setTimeout(function () {
            this.resendEnabled = true;
          }, _RESEND_WAIT);
        }
      }, (error: BaseAppError) => {
        _logger.error('Error on sendVerificationCode.');
        _logger.errorVerbose('Error:', error);
        this.activeModal.dismiss({ reason: 'error', sent: false, verified: false, error });
      }, () => {
        this.closeLoadingModal(loadingModal);
      });
  }

  protected resendCode(): void {
    if (this.resendEnabled) {
      this.resendEnabled = false;
      this.sendCode();
    } else {
      this.modalsService.showAlertModal('Debe esperar 5 minutos entre cada envío para solicitar un nuevo código.',
        'Reenviar código de verificación');
    }
  }

  protected close(): void {
    this.activeModal.dismiss({ reason: 'cancelled' });
  }

  protected onSubmit(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'onSubmit'
    });
    _logger.info('Method start.');

    if (this.verificationCode.trim().length !== _CODE_LENGTH) {
      this.modalsService.showErrorModal('Debe ingresar un código de verificación válido.');
      return;
    }

    this.attempts++;

    const loadingModal = this.modalsService.showLoadingModal('Verificando...');
    this.phoneVerifyService.checkVerificationCode(this.verificationCode, this.verificationId)
      .subscribe((resultOrError: PhoneCheckVerificationModel | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('checkVerificationCode resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          this.activeModal.dismiss({ reason: 'error', sent: true, verified: false, error: resultOrError });
        } else {
          _logger.debugVerbose('checkVerificationCode success.');

          this.closeLoadingModal(loadingModal);

          if (resultOrError.verified) {
            this.activeModal.close({ sent: true, verified: true });
          } else if (this.attempts === _MAX_ATTEMPS) {
            this.modalsService.showErrorModal('Código de verificación incorrecto. Se ha alcanzado el máximo de intentos, ' +
              'en breve recibirá un nuevo código de verificación.');
            this.attempts = 0;
            this.verificationCode = '';
            this.sendCode();
          } else {
            this.modalsService.showErrorModal('Código de verificación incorrecto.');
          }
        }
      }, (error: BaseAppError) => {
        _logger.error('Error on sendVerificationCode.');
        _logger.errorVerbose('Error:', error);
        this.closeLoadingModal(loadingModal);
        this.activeModal.dismiss({ reason: 'error', sent: false, verified: false, error });
      });
  }

  private closeLoadingModal(loadingModal: Promise<NgbModalRef>): void {
    loadingModal.then(modal => {
      modal.dismiss('done');
    });
  }
}
