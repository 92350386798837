import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "../../services/auth/auth.service";
import { LoggerContext, SimpleLogger } from "../../shared/simple-logger.shared";


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'auth.guard.ts',
  className: 'AuthGuard',
  methodName: '',
  tagName: 'GUARDS'
});
_LOGGER.debugVerbose('Loaded.');


/**
 * Auth Guard.
 * Checks if User is logged in.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  public canActivate(next: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const __logger = _LOGGER.getDerivedContext({methodName: 'canActivate'});
    __logger.infoVerbose('Method start.');

    if (this.authService.isUserLoggedIn()) {
      return true;
    }

    this.router.navigate(['/home'], {queryParams: state.root.queryParams});
    return false;
  }
}
