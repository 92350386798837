export class QueryModel {
    public id: number;
    public titulo: string;
    public nombre: string;
    public email: string;
    public user_cliente_id: number;
    public pedido_publicacion_id: number;
    public estado: string;

  
    constructor(
        id: number,
        titulo: string,
        nombre: string,
        email: string,
        user_cliente_id: number,
        pedido_publicacion_id: number,
        estado: string
        ) {
      this.id = id;
      this.titulo = titulo;
      this.nombre = nombre;
      this.email = email;
      this.user_cliente_id = user_cliente_id;
      this.pedido_publicacion_id = pedido_publicacion_id;
      this.estado = estado;
    }
  }
  