import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';
import { BaseAppError } from '../errors/base-app-error';
import { ISellData } from './i-sell-data';
import { IApiCommonResponse } from '../i-api-common-response';
import { ErrorsService } from '../errors/errors.service';
import { ISellCreateOrderResponseItem } from './i-sell-create-order-response';
import { QueryModel } from 'src/app/models/query.model';



const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'sell.service.ts',
  className: 'SellService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');

const _CONFIG = EnvironmentManager.getInstance().getConfig();

/**
 * Sell Service.
 */
@Injectable({
  providedIn: 'root'
})
export class SellService {
  private baseURL = `${_CONFIG.apiBaseURL}/toyotausados/publicacion`;

  constructor(
    private http: HttpClient,
    private errorsService: ErrorsService
  ) {
  }

  public createPedido(data: ISellData): Observable<QueryModel | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({methodName: 'createPedido'});
    __logger.info('Method start.');

    const endpointURL = `${this.baseURL}/pedido`;

    if (data.domain.length === 6) {
      data.domain = data.domain.substring(0, 3) + '-' + data.domain.substring(3);
    } else if (data.domain.length === 7) {
      data.domain = data.domain.substring(0, 2) + '-' + data.domain.substring(2, 5) + '-' + data.domain.substring(5);
    }

    const payload = {
      year: data.year,
      modelo_id: data.model.id,
      version_id: data.version ? data.version.id : null,
      kms: data.kms,
      domain: data.domain,
      province_id: data.province.id,
      locality_external_id: data.locality ? data.locality.postalCode : null,
      locality_name: data.locality ? data.locality.name : null,
      concesionario_id: data.dealer.dealer.id,
      sucursal_id: data.dealer.branch.id,
      user_id: (!!data.user && !!data.user.id) ? data.user.id : null
    };

    __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);
    return this.http.post<IApiCommonResponse<ISellCreateOrderResponseItem>>(endpointURL, payload, {observe: 'response'}).pipe(
      // Log operation.
      tap((response: HttpResponse<IApiCommonResponse<ISellCreateOrderResponseItem>>) => {
        __logger.debug('API response:', response);
      }),
      // Parse response.
      map((response: HttpResponse<IApiCommonResponse<ISellCreateOrderResponseItem>>) => 
      new QueryModel(
        response.body.data.consulta.id, 
        response.body.data.consulta.titulo,
        response.body.data.consulta.nombre,
        response.body.data.consulta.email,
        response.body.data.consulta.user_cliente_id,
        response.body.data.consulta.pedido_publicacion_id,
        response.body.data.consulta.estado
        ) 
      ),
      // Error handler.
      catchError(error => of(this.errorsService.getAppError(error))));
  }
}
