import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { Observable, of } from 'rxjs';
import { BaseAppError } from '../errors/base-app-error';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PersistenceService } from '../persistence/persistence.service';
import { ErrorsService } from '../errors/errors.service';
import { IApiCommonResponse } from '../i-api-common-response';
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { VehicleModelModel } from '../../models/vehicle-model.model';
import { IModelsGetModelsResponseBrandItem } from './i-models-get-models-response-brand-item';
import { VehicleBrandModel } from '../../models/vehicle-brand.model';

const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'brand.service.ts',
  className: 'BrandService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


const _CACHE_CONFIG = {
  GET_BRAND: {
    KEY: 'get_brand',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  }
};

@Injectable({
  providedIn: 'root'
})
export class BrandService {
  private baseURL = `${_CONFIG.apiBaseURL}/toyotausados/marcas`;

  /** Cache data. */
  private cacheGetBrands: Observable<VehicleBrandModel[] | BaseAppError>;

  constructor(
    private http: HttpClient,
    private persistenceService: PersistenceService,
    private errorsService: ErrorsService
  ) {
  }

  /**
   * Get marca TOYOTA. Este endpoint de la api devuelve un array con solo la marca toyota en la primera posición
   */
  public getBrand(): Observable<VehicleBrandModel[] | BaseAppError> {
      const __logger = _LOGGER.getDerivedContext({
        methodName: 'getBrand'
      });
      __logger.info('Method start.');

      const endpointURL = `${this.baseURL}`;
      __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

      return this.cacheGetBrands = this.http.get<IApiCommonResponse<IModelsGetModelsResponseBrandItem[]>>(endpointURL, {observe: 'response'})
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<IModelsGetModelsResponseBrandItem[]>>) => {
            __logger.debug('API response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<IApiCommonResponse<IModelsGetModelsResponseBrandItem[]>>) => response.body.data.map(
            vBrand => new VehicleBrandModel(vBrand.id, vBrand.mar_descripcion_s,  vBrand.id_fu ))),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );
    }

}
