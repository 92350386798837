import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { PublicationFiltersModel } from '../../models/publication-filters.model';
import { SessionService } from '../session/session.service';
import { Router } from '@angular/router';
import { PublicationsService } from '../publications/publications.service';
import { Observable } from 'rxjs';
import { PageDataModel } from '../../models/page-data.model';
import { PublicationModel } from '../../models/publication.model';
import { BaseAppError } from '../errors/base-app-error';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'commons.service.ts',
  className: 'CommonsService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');

/* Function facebook fbq*/
// tslint:disable-next-line:ban-types
declare let fbq: Function;
/* var function Google*/
declare let gtag;

/**
 * Commons Service.
 */
@Injectable({
  providedIn: 'root'
})
export class CommonsService {

  constructor(
    private sessionService: SessionService,
    private publicationsService: PublicationsService,
    private router: Router
  ) {
  }

  /**
   * "Usados Certificados Toyota" search function.
   */
  public execUsadosCertificadosToyotaSearchTerm(): void {
    // Facebook fbq
    fbq('track', 'Usados_Certificados_Toyota');
    // Google GDN Usados_Certificados_Toyota
    gtag('event', 'conversion', {
      event_label: 'CT8gCNTb6cIBEKfY38ED',
      value: 943189031
    });
    // Google SEM Usados_Certificados_Toyota
    gtag('event', 'conversion', {
      event_label: '1XcBCLrX38IBEMeE7qAD',
      value: 874218055
    });

    this.execSearchTermEnd(this.publicationsService.getPublicationsByAdvancedSearch(null, null, null, null,
      null, null, null, null, null, null, true), new PublicationFiltersModel(false, true));
  }

  public execAllPublicationsSearchTerm(): void {
    this.execSearchTermEnd(this.publicationsService.getPublicationsByAdvancedSearch(null, null, null, null,
      null, null, null, null, null, null), new PublicationFiltersModel());
  }

  private execSearchTermEnd(search: Observable<PageDataModel<PublicationModel> | BaseAppError>,
                            filters: PublicationFiltersModel): void {
    this.sessionService.setSessionData('publicationsResults', search);
    this.sessionService.setSessionData('publicationsFilters', filters);
    this.router.navigate(['/results']);
  }
}
