import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseAppError } from 'src/app/services/errors/base-app-error';
import { LegalsService } from 'src/app/services/legals/legals.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { ISellData } from 'src/app/services/sell/i-sell-data';
import { LoggerContext, SimpleLogger } from '../../../../../shared/simple-logger.shared';
import { TerminosCondicionesModalComponent } from '../../terminos-condiciones-modal/terminos-condiciones-modal.component';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'sell-step-four.component.ts',
  className: 'SellStepFourComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');

/**
 * Page Header component to place top of most views.
 */
@Component({
  selector: 'app-sell-step-four',
  templateUrl: './sell-step-four.component.html',
  styleUrls: ['./sell-step-four.component.scss']
})
export class SellStepFourComponent implements OnInit {
  @Input() nextClick: () => void;
  @Output()
  public backStep = new EventEmitter<any>();
  @Input() data: ISellData;

  public tycAccepted: boolean;
  public tycText: string;

  constructor(
    private readonly modalsService: ModalsService,
    private readonly legalsService: LegalsService
  ) {
  }

  public ngOnInit(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'ngOnInit'
    });
    __logger.info('Method start.');

    this.tycAccepted = false;
    this.tycText = null;
  }

  public onNextClick() {
    if (!this.tycAccepted) {
      return;
    }

    this.nextClick();
  }

  public onBackClick() {
    this.backStep.emit();
  }

  public onTycClick(e: Event) {
    const __logger = _LOGGER.getDerivedContext({methodName: 'onTycClick'});
    __logger.info('Method start.');

    e.preventDefault();

    this.getTycText().then(text => {
      const confirmModal = this.modalsService.showCustomModal(TerminosCondicionesModalComponent, 'terminos-condiciones-modal', 'lg',
        true, 'modal-basic-title', true, 'static');
      confirmModal.componentInstance.text = text;
    }).catch(error => {
      __logger.error('Error getting tyc text:', error);
      this.modalsService.showErrorModal('Ocurrió un error, por favor intente nuevamente en unos minutos.');
    });
  }

  public getFormattedKms() {
    return this.data ? new Intl.NumberFormat('es-AR').format(this.data.kms) + ' km' : '0 km';
  }

  private getTycText() {
    if (!this.tycText) {
      return new Promise((resolve, reject) => {
        const loadingModal = this.modalsService.showLoadingModal('Cargando...');
        this.legalsService.getTerminosMandatoVenta().subscribe(resultOrError => {
          if (resultOrError instanceof BaseAppError) {
            reject(resultOrError);
          } else {
            this.tycText = resultOrError.mandato_venta;
            resolve(this.tycText);
          }
        }, error => {
          reject(error);
        }, () => {
          loadingModal.then(modal => {
            modal.dismiss('done');
          });
        });
      });
    } else {
      return Promise.resolve(this.tycText);
    }
  }

}
