import { Component, OnInit, Input } from '@angular/core';
import { UserMessagesModel } from 'src/app/models/user-messages.model';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() public message: UserMessagesModel;

  constructor() { }

  ngOnInit() {
  }

}
