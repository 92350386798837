import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoggerContext, SimpleLogger} from '../../../../shared/simple-logger.shared';
import {SessionService} from '../../../../services/session/session.service';
import {PublicationModel} from '../../../../models/publication.model';
import {Observable, of, Subject} from 'rxjs';
import {BaseAppError} from '../../../../services/errors/base-app-error';
import {ChangeContext, Options, PointerType} from 'ng5-slider';
import {EnvironmentManager} from '../../../../shared/environment-manager.shared';
import {PublicationsService} from '../../../../services/publications/publications.service';
import {PublicationFiltersModel} from '../../../../models/publication-filters.model';
import {ProvinceModel} from '../../../../models/province.model';
import {VehicleBrandModel} from 'src/app/models/vehicle-brand.model';
import {VehicleVersionModel} from '../../../../models/vehicle-version.model';
import {VehicleModelModel} from '../../../../models/vehicle-model.model';
import {DealerModel} from '../../../../models/dealer.model';
import {ProvincesService} from '../../../../services/provinces/provinces.service';
import {ModelsService} from '../../../../services/models/models.service';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {DealersService} from '../../../../services/dealers/dealers.service';
import {SortingOptionModel} from '../../../../models/sorting-option.model';
import {PageDataModel} from '../../../../models/page-data.model';
import { UserActionModel } from '../../../../models/user-action-send.model';
import {filter, takeUntil} from 'rxjs/operators';
import {ParamsService} from '../../../../services/params/params.service';
import {ParamModel} from '../../../../models/param.model';
import {BrandService} from '../../../../services/brand/brand.service';
import {VersionsService} from '../../../../services/versions/versions.service';
import { CommonsService } from '../../../../services/commons/commons.service';
import { UserActionService } from '../../../../services/user-action/user-action.service';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'results-page.component.ts',
  className: 'ResultsComponent',
  tagName: 'PAGES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const __GENERIC_FORM_INIT_ERROR_MSG = 'Ha ocurrido un error durante la inicialización de los filtros.';


/**
 * Results page.
 */
@Component({
  selector: 'app-results-page',
  templateUrl: './results-page.component.html',
  styleUrls: ['./results-page.component.scss']
})
export class ResultsPageComponent implements OnInit, OnDestroy {
  public routerChangesSubscriptionIsDestroyed = new Subject<any>();

  protected showFilterMenu: boolean = false;
  protected filters: PublicationFiltersModel;

  /** Results control data. */
  protected viewCarsAsList: boolean = false;
  protected publicationsPageData: PageDataModel<PublicationModel>;
  protected publications: PublicationModel[];
  protected loadingResults: boolean;

  /** Form controls data. */
  protected priceInputMinValue: number = _CONFIG.vehiclePriceMinValueToFilter;
  protected priceInputMaxValue: number = _CONFIG.vehiclePriceMaxValueToFilter;
  protected priceInputOptions: Options = {
    floor: this.priceInputMinValue,
    ceil: this.priceInputMaxValue,
    hideLimitLabels: true,
    animate: false,
    translate: (value: number): string => {
      return `${_CONFIG.currencySymbol}${value.toLocaleString(_CONFIG.i18nLocaleFull, {maximumFractionDigits: 0})}`;
    }
  };
  protected mileageInputMinValue: number = _CONFIG.vehicleMileageMinValueToFilter;
  protected mileageInputMaxValue: number = _CONFIG.vehicleMileageMaxValueToFilter;
  protected mileageInputOptions: Options = {
    floor: this.mileageInputMinValue,
    ceil: this.mileageInputMaxValue,
    hideLimitLabels: true,
    animate: false,
    translate: (value: number): string => {
      return `${value.toLocaleString(_CONFIG.i18nLocaleFull, {maximumFractionDigits: 0})}km`;
    }
  };
  protected installmentsPriceInputMinValue: number = _CONFIG.vehicleInstallmentsPriceMinValueToFilter;
  protected installmentsPriceInputMaxValue: number = _CONFIG.vehicleInstallmentsPriceMaxValueToFilter;
  protected installmentsPriceInputOptions: Options = {
    floor: this.installmentsPriceInputMinValue,
    ceil: this.installmentsPriceInputMaxValue,
    hideLimitLabels: true,
    animate: false,
    translate: (value: number): string => {
      return `${_CONFIG.currencySymbol}${value.toLocaleString(_CONFIG.i18nLocaleFull, {maximumFractionDigits: 0})}`;
    }
  };

  /** Form data. */
  public disableForm: boolean;
  protected loadingSortingOptions: boolean;
  protected sortingOptions: SortingOptionModel[];
  protected selectedSortingOption: SortingOptionModel;
  public vehicleModels: VehicleModelModel[];
  public vehicleVersions: VehicleVersionModel[];
  protected dealers: DealerModel[];
  protected dealer: DealerModel;
  protected loadingDealers: boolean;
  public loadingVehicleModels: boolean;
  public loadingVehicleVersions: boolean;
  public vehicleBrands: VehicleBrandModel[];
  protected loadingVehicleBrands: boolean;
  protected years: number[];
  protected loadingYears: boolean;
  protected yearFrom: number;
  protected yearTo: number;
  public vehicleBrand: VehicleBrandModel = null;
  public vehicleModel: VehicleModelModel = null;
  public vehicleVersion: VehicleVersionModel = null;
  protected mileageFrom: number = this.mileageInputMinValue;                      // This needs to be...
  protected mileageTo: number = this.mileageInputMaxValue;                        // ...initialized here...
  protected installmentsPriceFrom: number = this.installmentsPriceInputMinValue;  // ...
  protected installmentsPriceTo: number = this.installmentsPriceInputMaxValue;    // ...
  protected priceFrom: number = this.priceInputMinValue;                          // ...in order to ng5-slider...
  protected priceTo: number = this.priceInputMaxValue;                            // ...to work properly.
  protected provinces: ProvinceModel[];
  protected loadingProvinces: boolean;
  protected province: ProvinceModel = null;
  protected quoteFinancedPercentage: number;
  protected loadingQuoteFinancedPercentage: boolean;

  constructor(
    private sessionService: SessionService,
    private publicationsService: PublicationsService,
    private provincesService: ProvincesService,
    private modelsService: ModelsService,
    private versionsService: VersionsService,
    private route: ActivatedRoute,
    private router: Router,
    private dealersService: DealersService,
    private brandService: BrandService,
    private paramsService: ParamsService,
    private userActionService: UserActionService,
    private commonsService: CommonsService
  ) {
  }

  /* Presentation logic. */

  private get certifiedFlagFilter(): boolean {
    let certifiedFlag: boolean = null;
    if (this.filters) {
      if (this.filters.certifiedFlag === true) {
        certifiedFlag = true;
      } else if (this.filters.usedFlag === true) {
        certifiedFlag = false;
      }
    }
    return certifiedFlag;
  }

  /**
   * Updates shown results by the given filters.
   */
  private getNextPageResults(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getNextPageResults'
    });
    __logger.info('Method start.');

    if (this.publicationsPageData && (this.publicationsPageData.currentPage < this.publicationsPageData.lastPage)) {
      this.loadingResults = true;
      this.publicationsService.getPublicationsByAdvancedSearch(this.filters.yearFrom, this.filters.yearTo,
        (this.filters.vehicleBrand ? this.filters.vehicleBrand.id : null),
        (this.filters.vehicleModel ? this.filters.vehicleModel.id : null),
        (this.filters.vehicleVersion ? this.filters.vehicleVersion.id : null),
        (this.filters.province ? this.filters.province.id : null), this.filters.mileageFrom, this.filters.mileageTo,
        this.filters.priceFrom, this.filters.priceTo, this.certifiedFlagFilter,
        (this.filters.dealer ? this.filters.dealer.id : null), this.filters.installmentsPriceFrom, this.filters.installmentsPriceTo,
        this.selectedSortingOption.key, this.publicationsPageData.currentPage + 1
      ).subscribe((resultOrError: PageDataModel<PublicationModel> | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          __logger.error('publicationsResults resulted in error.');
          __logger.errorVerbose('Error:', resultOrError);
        } else {
          __logger.debugVerbose('publicationsResults success.');
          this.publicationsPageData = resultOrError;
          this.publications = this.publications.concat(this.publicationsPageData.items);
        }
      }, (error: any) => {
        __logger.error('Error on publicationsResults.');
        __logger.errorVerbose('Error:', error);
      }, () => {
        this.loadingResults = false;
      });
    }
  }

  /**
   * Updates shown results by the given filters.
   */
  private updateResults(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'updateResults'
    });
    __logger.info('Method start.');

    this.loadingResults = true;
    const resultsPublicationsObservable = this.publicationsService.getPublicationsByAdvancedSearch(this.filters.yearFrom, this.filters.yearTo,
      (this.filters.vehicleBrand ? this.filters.vehicleBrand.id : null),
      (this.filters.vehicleModel ? this.filters.vehicleModel.id : null),
      (this.filters.vehicleVersion ? this.filters.vehicleVersion.id : null),
      (this.filters.province ? this.filters.province.id : null), this.filters.mileageFrom, this.filters.mileageTo,
      this.filters.priceFrom, this.filters.priceTo, this.certifiedFlagFilter,
      (this.filters.dealer ? this.filters.dealer.id : null), this.filters.installmentsPriceFrom, this.filters.installmentsPriceTo,
      (this.selectedSortingOption ? this.selectedSortingOption.key : null)
      )

      resultsPublicationsObservable.subscribe((resultOrError: PageDataModel<PublicationModel> | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          __logger.error('publicationsResults resulted in error.');
          __logger.errorVerbose('Error:', resultOrError);
          this.publications = [];
        } else {
          __logger.debugVerbose('publicationsResults success.');
          this.publicationsPageData = resultOrError;
          this.publications = this.publicationsPageData.items;
          this.sessionService.setSessionData('publicationsResults', resultsPublicationsObservable)
      }
    }, (error: any) => {
      __logger.error('Error on publicationsResults.');
      __logger.errorVerbose('Error:', error);
      this.publications = [];
    }, () => {
      this.loadingResults = false;
    });
  }

  /**
   * Gets Publications results & Publications Filters from session data.
   */
  private getResults(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getResults'
    });
    __logger.info('Method start.');

    this.loadingResults = true;
    let __resultsObservable: Observable<PageDataModel<PublicationModel> | BaseAppError> = this.sessionService.getSessionData('publicationsResults');
    const emptyPage = new PageDataModel(0, 0, 0, 0, 0, 0, 0, []);
    if (!__resultsObservable) {
      __resultsObservable = of(emptyPage);
    }
    __resultsObservable.subscribe((resultOrError: PageDataModel<PublicationModel> | BaseAppError) => {
      if (!resultOrError || (resultOrError instanceof BaseAppError)) {
        __logger.error('publicationsResults resulted in error.');
        __logger.errorVerbose('Error:', resultOrError);
        this.publicationsPageData = emptyPage;
        this.publications = this.publicationsPageData.items;
      } else {
        __logger.debugVerbose('publicationsResults success.');
        this.publicationsPageData = resultOrError;
        this.publications = this.publicationsPageData.items;
      }
    }, (error: any) => {
      __logger.error('Error on publicationsResults.');
      __logger.errorVerbose('Error:', error);
      this.publications = [];
    }, () => {
      this.loadingResults = false;
    });

    this.filters = this.sessionService.getSessionData('publicationsFilters');
    if (!this.filters) {
      this.filters = new PublicationFiltersModel();
    }

    this.formInit();
  }

  private updateVehicleModels(): Promise<VehicleModelModel[]> {
    const __logger = _LOGGER.getDerivedContext({methodName: 'updateVehicleModels'});
    __logger.info('Method start.');
    return new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
      try {
        this.vehicleModels = [];
        this.loadingVehicleModels = true;
        this.modelsService.getModels(this.filters.vehicleBrand ? this.filters.vehicleBrand : null)
          .subscribe((resultOrError: VehicleModelModel[] | BaseAppError) => {
            if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.length) {
              __logger.error('getModels resulted in error.');
              __logger.errorVerbose('Error:', resultOrError);
              reject(new Error(__GENERIC_FORM_INIT_ERROR_MSG));
            } else {
              __logger.debug('Vehicle Models initialized.');
              this.vehicleModels = resultOrError;
              resolve(this.vehicleModels);
            }
          }, (error: any) => {
            _LOGGER.error('Error on getModels.');
            __logger.errorVerbose('Error:', error);
            reject(error);
          });
      } catch (e) {
        _LOGGER.error('Error while initializing vehicle Models.');
        __logger.errorVerbose('Error:', e);
        reject(e);
      } finally {
        this.loadingVehicleModels = false;
      }
    });
  }

  private updateVehicleVersions(): Promise<VehicleVersionModel[]> {
    const __logger = _LOGGER.getDerivedContext({methodName: 'updateVehicleVersions'});
    __logger.info('Method start.');
    return new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
      try {
        this.vehicleVersions = [];
        if (!this.filters.vehicleModel) {
          resolve(this.vehicleVersions);
          return;
        }
        this.loadingVehicleVersions = true;
        this.versionsService.getVersions(this.filters.vehicleModel)
          .subscribe((resultOrError: VehicleVersionModel[] | BaseAppError) => {
            if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.length) {
              __logger.error('getVersions resulted in error.');
              __logger.errorVerbose('Error:', resultOrError);
              reject(new Error(__GENERIC_FORM_INIT_ERROR_MSG));
            } else {
              __logger.debug('Vehicle Versions initialized.');
              this.vehicleVersions = resultOrError;
              resolve(this.vehicleVersions);
            }
          }, (error: any) => {
            _LOGGER.error('Error on getVersions.');
            __logger.errorVerbose('Error:', error);
            reject(error);
          });
      } catch (e) {
        _LOGGER.error('Error while initializing vehicle Versions.');
        __logger.errorVerbose('Error:', e);
        reject(e);
      } finally {
        this.loadingVehicleVersions = false;
      }
    });
  }

  /**
   * Initializes form data.
   */
  private formInit(): void {
    const __logger = _LOGGER.getDerivedContext({methodName: 'formInit'});
    __logger.info('Method start.');

    this.disableForm = true;
    Promise.all([
      // Get params.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        if (this.quoteFinancedPercentage) {
          resolve(this.quoteFinancedPercentage);
          return;
        }
        try {
          this.quoteFinancedPercentage = 0;
          this.loadingQuoteFinancedPercentage = true;
          this.paramsService.getParamByKey('publicacion_financiacion_sugerida_usados_porc')
            .subscribe((resultOrError: ParamModel | BaseAppError) => {
              if (!resultOrError || (resultOrError instanceof BaseAppError) || (typeof resultOrError.paramValue !== 'number')) {
                __logger.error('getParamByKey resulted in error.');
                __logger.errorVerbose('Error:', resultOrError);
                reject(new Error(__GENERIC_FORM_INIT_ERROR_MSG));
              } else {
                __logger.debug('Params initialized.');
                this.quoteFinancedPercentage = resultOrError.paramValue;
                resolve(this.quoteFinancedPercentage);
              }
            }, (error: any) => {
              _LOGGER.error('Error on getParamByKey.');
              __logger.errorVerbose('Error:', error);
              reject(error);
            });
        } catch (e) {
          __logger.error('Error while initializing Params.');
          __logger.errorVerbose('Error:', e);
          reject(e);
        } finally {
          this.loadingQuoteFinancedPercentage = false;
        }
      }),

      // Get Sorting Options.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        if (this.sortingOptions && this.sortingOptions.length) {
          resolve(this.sortingOptions);
          return;
        }
        try {
          this.loadingSortingOptions = true;

          this.sortingOptions = [
            new SortingOptionModel('dest', 'Más Vistos'),
            new SortingOptionModel('priceAsc', 'Menor Precio'),
            new SortingOptionModel('priceDesc', 'Mayor Precio'),
            new SortingOptionModel('dateAsc', 'Más Reciente'),
            new SortingOptionModel('dateDesc', 'Menos Reciente'),
            new SortingOptionModel('brand', 'Marca')
          ];

          if (!this.sortingOptions || !this.sortingOptions.length) {
            reject(new Error(__GENERIC_FORM_INIT_ERROR_MSG));
          } else {
            resolve(this.sortingOptions);
          }
        } catch (e) {
          __logger.error('Error while initializing Sorting Options.');
          __logger.errorVerbose('Error:', e);
          reject(e);
        } finally {
          this.loadingSortingOptions = false;
        }
      }),

      // Get vehicle years.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        if (this.years && this.years.length) {
          resolve(this.years);
          return;
        }
        try {
          this.loadingYears = true;
          const currentYear = new Date().getFullYear();
          this.years = (Array.apply(null, {length: _CONFIG.vehicleYearsAgoToConsider + 1}) as any[])
            .map((v, i) => currentYear - i);

          if (!this.years || !this.years.length) {
            reject(new Error(__GENERIC_FORM_INIT_ERROR_MSG));
          } else {
            resolve(this.years);
          }
        } catch (e) {
          __logger.error('Error while initializing Years.');
          __logger.errorVerbose('Error:', e);
          reject(e);
        } finally {
          this.loadingYears = false;
        }
      }),

      // Get Provinces.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        if (this.provinces && this.provinces.length) {
          resolve(this.provinces);
          return;
        }
        try {
          this.provinces = [];
          this.loadingProvinces = true;
          this.provincesService.getProvinces()
            .subscribe((resultOrError: ProvinceModel[] | BaseAppError) => {
              if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.length) {
                __logger.error('getProvinces resulted in error.');
                __logger.errorVerbose('Error:', resultOrError);
                reject(new Error(__GENERIC_FORM_INIT_ERROR_MSG));
              } else {
                __logger.debug('Provinces initialized.');
                this.provinces = resultOrError;
                resolve(this.provinces);
              }
            }, (error: any) => {
              _LOGGER.error('Error on getProvinces.');
              __logger.errorVerbose('Error:', error);
              reject(error);
            });
        } catch (e) {
          _LOGGER.error('Error while initializing Provinces.');
          __logger.errorVerbose('Error:', e);
          reject(e);
        } finally {
          this.loadingProvinces = false;
        }
      }),

      // Get vehicle brands.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          this.vehicleBrands = [];
          this.loadingVehicleBrands = true;
          this.brandService.getBrand().subscribe((resultOrError: VehicleBrandModel[] | BaseAppError) => {
            if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.length) {
              __logger.error('getBrand resulted in error.');
              __logger.errorVerbose('Error:', resultOrError);
              reject(new Error(__GENERIC_FORM_INIT_ERROR_MSG));
            } else {
              __logger.debug('Vehicle Brand initialized.');
              this.vehicleBrands = resultOrError;
              resolve(this.vehicleBrands);
            }
          }, (error: any) => {
            _LOGGER.error('Error on getBrand.');
            __logger.errorVerbose('Error:', error);
            reject(error);
          });
        } catch (e) {
          _LOGGER.error('Error while initializing vehicle Brands.');
          __logger.errorVerbose('Error:', e);
          reject(e);
        } finally {
          this.loadingVehicleBrands = false;
        }
      }),

      // Get vehicle Models.
      this.updateVehicleModels(),

      // Get Vehicle Versions.
      this.updateVehicleVersions(),

      // Get Dealers.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        if (this.dealers && this.dealers.length) {
          resolve(this.dealers);
          return;
        }
        try {
          this.dealers = [];
          this.loadingDealers = true;
          this.dealersService.getMap()
            .subscribe((resultOrError: DealerModel[] | BaseAppError) => {
              if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.length) {
                __logger.error('getMap resulted in error.');
                __logger.errorVerbose('Error:', resultOrError);
                reject(new Error(__GENERIC_FORM_INIT_ERROR_MSG));
              } else {
                __logger.debug('Dealers initialized.');
                this.dealers = resultOrError;
                resolve(this.dealers);
              }
            }, (error: any) => {
              _LOGGER.error('Error on getMap.');
              __logger.errorVerbose('Error:', error);
              reject(error);
            });
        } catch (e) {
          _LOGGER.error('Error while initializing Dealers.');
          __logger.errorVerbose('Error:', e);
          reject(e);
        } finally {
          this.loadingDealers = false;
        }
      })
    ]).then(() => {
      __logger.debug('Init completed.');
    }).catch(error => {
      __logger.debug('Init error.', error);
    }).finally(() => {
      this.formReset();
    });
  }

  /**
   * Resets form (filters).
   */
  private formReset(): void {
    if (this.filters.vehicleBrand) {
      this.vehicleBrand = this.vehicleBrands.find(vb => vb.id === this.filters.vehicleBrand.id);
      if (!this.vehicleBrand) {
        this.onRemoveFilterVehicleBrand();
      }
    } else {
      this.vehicleBrand = null;
    }
    this.onChangeVehicleBrand(true);

    if (this.filters.vehicleModel) {
      this.vehicleModel = this.vehicleModels.find(vm => vm.id === this.filters.vehicleModel.id);
      if (!this.vehicleModel) {
        this.onRemoveFilterVehicleModel();
      }
    } else {
      this.vehicleModel = null;
    }
    this.onChangeVehicleModel(true);

    if (this.filters.vehicleVersion && !this.vehicleModel) {
      this.vehicleVersion = this.vehicleVersions.find(vm => vm.id === this.filters.vehicleVersion.id);
      if (!this.vehicleVersion) {
        this.onRemoveFilterVehicleVersion();
      }
    } else {
      this.vehicleVersion = null;
    }
    this.onChangeVehicleVersion(true);

    if (!this.selectedSortingOption) {
      this.selectedSortingOption = this.sortingOptions[0];
      this.onChangeSortingOption(true);
    }

    if (this.filters.dealer) {
      this.dealer = this.dealers.find(d => d.id === this.filters.dealer.id);
      if (!this.dealer) {
        this.onRemoveFilterDealer();
      }
    } else {
      this.dealer = null;
    }
    this.onChangeDealer(true);

    if (this.filters.yearFrom || (this.filters.yearFrom === 0)) {
      this.yearFrom = this.filters.yearFrom;
    } else {
      this.yearFrom = null;
    }
    this.onChangeYearFrom(true);

    if (this.filters.yearTo || (this.filters.yearTo === 0)) {
      this.yearTo = this.filters.yearTo;
    } else {
      this.yearTo = null;
    }
    this.onChangeYearTo(true);

    if (this.filters.priceFrom || (this.filters.priceFrom === 0)) {
      this.priceFrom = this.filters.priceFrom;
    } else {
      this.priceFrom = this.priceInputMinValue;
    }
    this.onChangePriceFrom(true);

    if (this.filters.priceTo || (this.filters.priceTo === 0)) {
      this.priceTo = this.filters.priceTo;
    } else {
      this.priceTo = this.priceInputMaxValue;
    }
    this.onChangePriceTo(true);

    if (this.filters.mileageFrom || (this.filters.mileageFrom === 0)) {
      this.mileageFrom = this.filters.mileageFrom;
    } else {
      this.mileageFrom = this.mileageInputMinValue;
    }
    this.onChangeMileageFrom(true);

    if (this.filters.mileageTo || (this.filters.mileageTo === 0)) {
      this.mileageTo = this.filters.mileageTo;
    } else {
      this.mileageTo = this.mileageInputMaxValue;
    }
    this.onChangeMileageTo(true);

    if (this.filters.installmentsPriceFrom || (this.filters.installmentsPriceFrom === 0)) {
      this.installmentsPriceFrom = this.filters.installmentsPriceFrom;
    } else {
      this.installmentsPriceFrom = this.installmentsPriceInputMinValue;
    }
    this.onChangeInstallmentsPriceFrom(true);

    if (this.filters.installmentsPriceTo || (this.filters.installmentsPriceTo === 0)) {
      this.installmentsPriceTo = this.filters.installmentsPriceTo;
    } else {
      this.installmentsPriceTo = this.installmentsPriceInputMaxValue;
    }
    this.onChangeInstallmentsPriceTo(true);

    if (this.filters.province) {
      this.province = this.provinces.find(p => p.id === this.filters.province.id);
      if (!this.province) {
        this.onRemoveFilterProvince();
      }
    } else {
      this.province = null;
    }
    this.onChangeProvince(true);

    this.disableForm = false;
  }

  /**
   * Returns TRUE if there are no filters being applied to results.
   * Ignores ranges setted to default min/max values.
   */
  protected get areThereNoFilters(): boolean {
    if (!this.filters) {
      return true;
    }

    return (!this.filters.usedFlag && !this.filters.certifiedFlag &&
      (this.filters.yearFrom === null) && (this.filters.yearTo === null) && !this.filters.vehicleBrand &&
      !this.filters.vehicleModel && !this.filters.vehicleVersion && !this.filters.province &&
      ((this.filters.mileageFrom === null) || (this.filters.mileageFrom === this.mileageInputMinValue)) &&
      ((this.filters.mileageTo === null) || (this.filters.mileageTo === this.mileageInputMaxValue)) &&
      ((this.filters.priceFrom === null) || (this.filters.priceFrom === this.priceInputMinValue)) &&
      ((this.filters.priceTo === null) || (this.filters.priceTo === this.priceInputMaxValue)) &&
      ((this.filters.installmentsPriceFrom === null) || (this.filters.installmentsPriceFrom === this.installmentsPriceInputMinValue)) &&
      ((this.filters.installmentsPriceTo === null) || (this.filters.installmentsPriceTo === this.installmentsPriceInputMaxValue)) &&
      !this.filters.title && !this.filters.dealer);
  }

  /**
   * Returns TRUE when mileage filters are at min./max. values.
   */
  protected get mileageFiltersToMinAndMax(): boolean {
    return !!this.filters && (this.filters.mileageFrom === this.mileageInputMinValue) && (this.filters.mileageTo === this.mileageInputMaxValue);
  }

  /**
   * Returns TRUE when price filters are at min./max. values.
   */
  protected get priceFiltersToMinAndMax(): boolean {
    return !!this.filters && (this.filters.priceFrom === this.priceInputMinValue) && (this.filters.priceTo === this.priceInputMaxValue);
  }

  /**
   * Returns TRUE when installments price filters are at min./max. values.
   */
  protected get installmentsPriceFiltersToMinAndMax(): boolean {
    return !!this.filters && (this.filters.installmentsPriceFrom === this.installmentsPriceInputMinValue) && (this.filters.installmentsPriceTo === this.installmentsPriceInputMaxValue);
  }

  /**
   * Enforces filters: yearFrom < yearTo.
   */
  private updateYearFilters(): void {
    if (!!this.yearFrom && !!this.yearTo) {
      const yFrom = this.yearFrom;
      const yTo = this.yearTo;
      this.yearFrom = Math.min(yFrom, yTo);
      this.yearTo = Math.max(yFrom, yTo);
    }
  }

  /**
   * Send user action landing results
   */
  public registrarUserActionLandingResults(): void{
    const logger = _LOGGER.getDerivedContext({methodName: 'registrarUserActionLandingResults'});
    logger.info('Method start.');
    this.userActionService.sendUserAction('landing results').subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        takeUntil(this.routerChangesSubscriptionIsDestroyed)
      )
      .subscribe(() => {
        this.getResults();
      });

    this.getResults();
    this.registrarUserActionLandingResults();
  }

  /**
   * Angular component OnDestroy event handler.
   */
  public ngOnDestroy(): void {
    this.routerChangesSubscriptionIsDestroyed.next();
    this.routerChangesSubscriptionIsDestroyed.complete();
  }

  /**
   * User changes Results visualization mode handler.
   *
   * @param displayAsList
   */
  protected onDisplayResultsAsList(displayAsList: boolean): void {
    this.viewCarsAsList = displayAsList;
  }

  /**
   * User changes Price From data handler.
   */
  private onChangePriceFrom(silent: boolean = false): void {
    this.filters.priceFrom = this.priceFrom;
    if (!silent) {
      this.updateResults();
    }
  }

  /**
   * User changes Price To data handler.
   */
  private onChangePriceTo(silent: boolean = false): void {
    this.filters.priceTo = this.priceTo;
    if (!silent) {
      this.updateResults();
    }
  }

  /**
   * User finish changes to Price From/To data handler.
   */
  protected onChangeEndPriceRange(changeContext: ChangeContext): void {
    switch (changeContext.pointerType) {
      case PointerType.Min:
        this.onChangePriceFrom();
        break;
      case PointerType.Max:
        this.onChangePriceTo();
        break;
    }
  }

  /**
   * User changes Mileage From data handler.
   */
  private onChangeMileageFrom(silent: boolean = false): void {
    this.filters.mileageFrom = this.mileageFrom;
    if (!silent) {
      this.updateResults();
    }
  }

  /**
   * User changes Mileage To data handler.
   */
  private onChangeMileageTo(silent: boolean = false): void {
    this.filters.mileageTo = this.mileageTo;
    if (!silent) {
      this.updateResults();
    }
  }


  /**
   * User finish changes to Mileage From/To data handler.
   */
  protected onChangeEndMileageRange(changeContext: ChangeContext): void {
    switch (changeContext.pointerType) {
      case PointerType.Min:
        this.onChangeMileageFrom();
        break;
      case PointerType.Max:
        this.onChangeMileageTo();
        break;
    }
  }

  /**
   * User changes Installments Price From data handler.
   */
  private onChangeInstallmentsPriceFrom(silent: boolean = false): void {
    this.filters.installmentsPriceFrom = this.installmentsPriceFrom;
    if (!silent) {
      this.updateResults();
    }
  }

  /**
   * User changes Installments Price To data handler.
   */
  private onChangeInstallmentsPriceTo(silent: boolean = false): void {
    this.filters.installmentsPriceTo = this.installmentsPriceTo;
    if (!silent) {
      this.updateResults();
    }
  }

  /**
   * User finish changes to Installments Price From/To data handler.
   */
  protected onChangeEndInstallmentsPriceRange(changeContext: ChangeContext): void {
    switch (changeContext.pointerType) {
      case PointerType.Min:
        this.onChangeInstallmentsPriceFrom();
        break;
      case PointerType.Max:
        this.onChangeInstallmentsPriceTo();
        break;
    }
  }

  /**
   * Resets Publications Results on session data.
   */
  public onSelectAllPublications(): void {
    this.commonsService.execAllPublicationsSearchTerm();
  }

  /**
   * User removes Title filter handler.
   */
  protected onRemoveFilterTitle(): void {
    this.filters.title = null;
    this.updateResults();
  }

  /**
   * User removes UsedFlag filter handler.
   */
  protected onRemoveFilterUsedFlag(): void {
    this.filters.usedFlag = null;
    this.updateResults();
  }

  /**
   * User removes CertifiedFlag filter handler.
   */
  protected onRemoveFilterCertifiedFlag(): void {
    this.filters.certifiedFlag = null;
    this.updateResults();
  }

  /**
   * User removes Province filter handler.
   */
  protected onRemoveFilterProvince(): void {
    this.province = null;
    this.onChangeProvince();
  }

  /**
   * User removes VehicleModel filter handler.
   */
  public onRemoveFilterVehicleModel(): void {
    this.vehicleModel = null;
    this.onChangeVehicleModel();
  }

  /**
   * User removes VehicleBrand filter handler.
   */
  public onRemoveFilterVehicleBrand(): void {
    this.vehicleBrand = null;
    this.onChangeVehicleBrand();
  }

  /**
   * User removes VehicleVersion filter handler.
   */
  public onRemoveFilterVehicleVersion(): void {
    this.vehicleVersion = null;
    this.onChangeVehicleVersion();
  }

  /**
   * Users removers Dealer filter handler.
   */
  protected onRemoveFilterDealer(): void {
    this.dealer = null;
    this.onChangeDealer();
  }

  /**
   * User removes YearFrom filter handler.
   */
  protected onRemoveFilterYearFrom(silent: boolean = false): void {
    this.yearFrom = null;
    this.onChangeYearFrom(silent);
  }

  /**
   * User removes YearTo filter handler.
   */
  protected onRemoveFilterYearTo(silent: boolean = false): void {
    this.yearTo = null;
    this.onChangeYearTo(silent);
  }

  /**
   * User removes YearFrom & YearTo filter handler.
   */
  protected onRemoveFilterYearFromAndTo(): void {
    this.onRemoveFilterYearFrom(true);
    this.onRemoveFilterYearTo();
  }

  /**
   * User removes MileageFrom & MileageTo filter handler.
   */
  protected onRemoveFilterMileageFromAndTo(): void {
    this.mileageFrom = this.mileageInputMinValue;
    this.onChangeMileageFrom(true);
    this.mileageTo = this.mileageInputMaxValue;
    this.onChangeMileageTo();
  }

  /**
   * User removes PriceFrom filter & PriceTo handler.
   */
  protected onRemoveFilterPriceFromAndTo(): void {
    this.priceFrom = this.priceInputMinValue;
    this.onChangePriceFrom(true);
    this.priceTo = this.priceInputMaxValue;
    this.onChangePriceTo();
  }

  /**
   * User removes InstallmentsPriceFrom filter & PriceTo handler.
   */
  protected onRemoveFilterInstallmentsPriceFromAndTo(): void {
    this.installmentsPriceFrom = this.installmentsPriceInputMinValue;
    this.onChangeInstallmentsPriceFrom(true);
    this.installmentsPriceTo = this.installmentsPriceInputMaxValue;
    this.onChangeInstallmentsPriceTo();
  }

  /**
   * User changes vehicle Model data handler.
   */
  public onChangeVehicleModel(silent: boolean = false): void {
    this.filters.vehicleModel = this.vehicleModel;
    if (!silent) {
      this.onRemoveFilterVehicleVersion();
      this.updateVehicleVersions().then(() => {
        // No action needed.
      });
    }
  }

  /**
   * User changes vehicle Version data handler.
   */
  public onChangeVehicleVersion(silent: boolean = false): void {
    this.filters.vehicleVersion = this.vehicleVersion;
    if (!silent) {
      this.updateResults();
    }
  }

  /**
   * User changes vehicle Model data handler.
   */
  public onChangeVehicleBrand(silent: boolean = false): void {
    this.filters.vehicleBrand = this.vehicleBrand;
    if (!silent) {
      this.onRemoveFilterVehicleModel();
      this.updateVehicleModels().then(() => {
        // No action needed.
      });
    }
  }


  /**
   * User changes Sorting option data handler.
   */
  protected onChangeSortingOption(silent: boolean = false): void {
    if (!silent) {
      this.updateResults();
    }
  }

  /**
   * User changes Dealer data handler.
   */
  protected onChangeDealer(silent: boolean = false): void {
    this.filters.dealer = this.dealer;
    if (!silent) {
      this.updateResults();
    }
  }

  /**
   * User changes Year From data handler.
   */
  protected onChangeYearFrom(silent: boolean = false): void {
    this.updateYearFilters();
    this.filters.yearFrom = this.yearFrom;
    if (this.yearTo) {
      this.filters.yearTo = this.yearTo;
    }
    if (!silent) {
      this.updateResults();
    }
  }

  /**
   * User changes Year To data handler.
   */
  protected onChangeYearTo(silent: boolean = false): void {
    this.updateYearFilters();
    this.filters.yearTo = this.yearTo;
    if (this.yearFrom) {
      this.filters.yearFrom = this.yearFrom;
    }
    if (!silent) {
      this.updateResults();
    }
  }

  /**
   * User changes Province data handler.
   */
  protected onChangeProvince(silent: boolean = false): void {
    this.filters.province = this.province;
    if (!silent) {
      this.updateResults();
    }
  }

  /**
   * User scrolls down results to the bottom limit.
   */
  protected onScrollResults(): void {
    this.getNextPageResults();
  }

  /**
   * User clicks "Filtrar" button handler.
   */
  protected onShowFilters(): void {
    this.showFilterMenu = true;
  }

  /**
   * User clicks "Aplicar" button handler.
   */
  protected onFiltersApply(): void {
    this.showFilterMenu = false;
  }

}
