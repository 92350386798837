import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PhoneValidatedModel } from 'src/app/models/phone-validated.model';
import { UserModel } from 'src/app/models/user.model';
import { PhoneVerifyModalComponent } from 'src/app/modules/main/components/phone-verify-modal/phone-verify-modal.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BaseAppError } from 'src/app/services/errors/base-app-error';
import { ErrorsService } from 'src/app/services/errors/errors.service';
import { FacebookService } from 'src/app/services/facebook/facebook.service';
import { GoogleAuthService } from 'src/app/services/google-auth/google-auth.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { LinkedinService } from 'src/app/services/linkedin/linkedin.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { ISellData } from 'src/app/services/sell/i-sell-data';
import { UsersService } from 'src/app/services/users/users.service';
import { LoggerContext, SimpleLogger } from '../../../../../shared/simple-logger.shared';
import { PersistenceService } from '../../../../../services/persistence/persistence.service';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'sell-step-three.component.ts',
  className: 'SellStepThreeComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');

/* Function facebook fbq*/
declare let fbq: Function;
/* var function Google*/
declare let gtag;

const _LOGIN_PAGE_URL = '/login',
  _METHOD_START_MSG = 'Method start.',
  _LINKEDIN_CALLBACK_URL = 'auth/linkedin/callback';

/**
 * Page Header component to place top of most views.
 */
@Component({
  selector: 'app-sell-step-three',
  templateUrl: './sell-step-three.component.html',
  styleUrls: ['./sell-step-three.component.scss']
})
export class SellStepThreeComponent implements OnInit {
  /** Form controls. */
  @ViewChild('signUpForm') protected signUpForm: NgForm;

  /** Form data. */
  protected disableForm: boolean;
  protected userName: string;
  protected userSurname: string;
  protected userEmail: string;
  protected userRepeatEmail: string;
  protected userCellphone: string;
  protected userPassword: string;
  protected userRepeatPassword: string;
  protected loginUserEmail: string;
  protected loginUserPassword: string;
  protected disableGoogleLogin: boolean;
  protected disableFacebookLogin: boolean;
  protected disableInstagramLogin: boolean;
  protected disableLinkedinLogin: boolean;

  @Input() nextClick: () => void;
  @Input() data: ISellData;

  /** Form controls. */
  @ViewChild('loginForm') protected loginForm: NgForm;

  /** Password Recovery Form data. */
  protected disablePasswordRecoveryForm: boolean;
  protected userEmailForPasswordRecovery: string;

  /** Password Change Modal. */
  @ViewChild('passwordChangeModalContent') protected passwordChangeModalContent: ElementRef;
  protected passwordChangeModal: NgbModalRef;

  /** Password Change Form controls. */
  @ViewChild('changePasswordForm') protected changePasswordForm: NgForm;

  /** Password Change Form data. */
  protected disablePasswordChangeForm: boolean;
  protected userPasswordChange: string;
  protected userPasswordChangeRepeat: string;

  private passwordChangeOperationToken: string;

  constructor(
    private modalsService: ModalsService,
    private usersService: UsersService,
    private router: Router,
    private errorsService: ErrorsService,
    private viewportScroller: ViewportScroller,
    private helpersService: HelpersService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private readonly googleAuthService: GoogleAuthService,
    private readonly facebookService: FacebookService,
    private readonly linkedinService: LinkedinService,
    private persistenceService: PersistenceService
  ) {
  }

  public ngOnInit(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'ngOnInit'
    });
    __logger.info('Method start.');

    if (this.authService.isUserLoggedIn()) {
      this.data.user = this.authService.getUserData();
      this.onNextClick();
    } else {
      this.registerFormInit();
      this.loginFormInit();
      this.initSocialLogin();
    }
  }

  public onNextClick() {
    this.nextClick();
  }


  /**
   *
   *  REGISTER
   *
   */

  /* Presentation logic. */

  /**
   * Initializes form data.
   */
  private registerFormInit(): void {
    this.disableForm = true;

    // No init needed (simple form).

    this.registerFormReset();
  }

  /**
   * Resets the form.
   */
  private registerFormReset(): void {
    if (this.signUpForm && this.signUpForm.pristine) {
      this.signUpForm.control.reset();
    }

    this.userName = null;
    this.userSurname = null;
    this.userEmail = null;
    this.userRepeatEmail = null;
    this.userCellphone = null;
    this.userPassword = null;
    this.userRepeatPassword = null;

    this.disableForm = false;
  }

  /**
   * Returns TRUE if e-mail for Password Recovery is invalid.
   */
  protected get isEmailInvalid(): boolean {
    return !this.helpersService.isEmailValid(this.userEmail);
  }

  /**
   * Return TRUE if Cellphone is invalid
   */
  protected get isPhoneInvalid(): boolean {
    return !this.helpersService.isCellphoneValid(this.userCellphone);
  }


  /* Handlers. */

  /**
   * LogIn operation failure handler.
   */
  private onSignUpFail(error: any): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'onSignUpFail'
    });
    _logger.info(_METHOD_START_MSG);

    const appError: BaseAppError = this.errorsService.getAppError(error);
    _logger.error('Error:', appError.getMessage());

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            _logger.debug('Error modal closed with result:', result);
          })
          .catch(reason => {
            _logger.debug('Error modal dismissed with reason:', reason);
          })
          .finally(() => {
          });
      });
  }

  /**
   * SignUp submit handler.
   */
  public onSignUpSubmit(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'onSignUpSubmit'
    });
    _logger.info(_METHOD_START_MSG);

    if (!this.userName || !this.userSurname || !this.userEmail || !this.userRepeatEmail || !this.userCellphone ||
      !this.userPassword || !this.userRepeatPassword) {
      this.onSignUpFail('Todos los campos son obligatorios.');
      return;
    }

    if (this.userEmail !== this.userRepeatEmail) {
      this.onSignUpFail('Los e-mails no coinciden.');
      return;
    }

    if (this.userPassword !== this.userRepeatPassword) {
      this.onSignUpFail('Las contraseñas no coinciden.');
      return;
    }

    /**
     * Se verifica si la contraseña cumple con los requisitos, de ser asi se procede con el envío del codigo.
     * En caso contrario se envía un mensaje de error en un modal.
     * Al menos 8 caracteres, una letra mayúscula, una minúscula y un número.
     */

    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

    if (strongRegex.test(this.userPassword)) {
      const verifyModal = this.modalsService.showCustomModal(PhoneVerifyModalComponent, 'phone-verify-modal', 'sm',
        true, 'modal-basic-title', false, 'static');
      verifyModal.componentInstance.phone = this.userCellphone;
      verifyModal.componentInstance.email = this.userEmail;

      verifyModal.result.then(result => {
        _logger.debug('Celular verificado correctamente:', result);
        this.registerUser();
      }).catch(error => {
        _logger.error('Error al verificar celular:', error);
        this.onSignUpFail('Ocurrió un error al intentar verificar su número de celular, ' +
          'por favor intente nuevamente.');
      });
    } else {
      this.modalsService.showErrorModal('La contraseña debe contener al menos 8 caracteres, una mayúscula y un número');
    }
  }

  private registerUser(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'registerUser'
    });
    _logger.info(_METHOD_START_MSG);

    // Facebook fbq
    fbq('track', 'Registro_Formulario');
    // Google GDN Registro_Formulario
    gtag('event', 'conversion', {
      event_label: 'EOJ2CN3T38IBEKfY38ED',
      value: 943189031
    });
    // Google SEM Registro_Formulario
    gtag('event', 'conversion', {
      event_label: 'qrOyCO-Vz8IBEMeE7qAD',
      value: 874218055
    });

    this.disableForm = true;
    const loadingModal = this.modalsService.showLoadingModal('Registrando...');
    this.usersService.doRegister(this.userName, this.userSurname, this.userEmail, this.userRepeatEmail, this.userPassword,
      this.userRepeatPassword, this.userCellphone, null).subscribe((resultOrError: boolean | BaseAppError) => {
      if (!resultOrError || (resultOrError instanceof BaseAppError)) {
        _logger.error('doRegister resulted in error.');
        _logger.errorVerbose('Error:', resultOrError);
        this.onSignUpFail(resultOrError);
      } else {
        _logger.debugVerbose('doRegister success.');
        this.persistenceService.store('sellWizzardDataAfterRegister', this.data);
        this.modalsService.showSuccessModal('Revise su casilla de correo e inicie sesión.',
          'Registro exitoso').then(modal => {
          modal.result.then(result => {
            _logger.debug('Error modal closed with result:', result);
          }).catch(reason => {
            _logger.debug('Error modal dismissed with reason:', reason);
          }).finally(() => {
            this.registerFormReset();
          });
        });
      }
    }, (error: any) => {
      _logger.error('Error on doRegister.');
      _logger.errorVerbose('Error:', error);
      this.onSignUpFail(error);
    }, () => {
      this.disableForm = false;
      this.closeLoadingModal(loadingModal);
    });
  }

  /**
   * User blurs Cellphone
   */
  protected onBlurCellPhone(): void {
    if (!this.isPhoneInvalid) {
      const _logger = _LOGGER.getDerivedContext({
        methodName: 'onBlurCellPhone'
      });
      _logger.info(_METHOD_START_MSG);

      const loadingModal = this.modalsService.showLoadingModal('Validando teléfono. Por favor espere.');
      this.usersService.validatePhone(this.userCellphone).subscribe((resultOrError: PhoneValidatedModel | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          const appError: BaseAppError = this.errorsService.getAppError(resultOrError);
          _logger.error('onBlurCellPhone resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          this.userCellphone = '';
          this.modalsService.showErrorModal(appError.getMessage());
        } else {
          _logger.debugVerbose('onBlurCellPhone success.');
          this.userCellphone = resultOrError.phone;
        }
      }, (error: BaseAppError) => {
        _logger.error('Error on onBlurCellPhone.');
        _logger.errorVerbose('Error:', error);
        this.userCellphone = '';
        this.modalsService.showErrorModal('Ha ocurrido un error al validar el teléfono. Intente nuevamente en unos minutos.');
      }, () => {
        this.disableForm = false;
        this.closeLoadingModal(loadingModal);
      });
    }
  }

  private closeLoadingModal(loadingModal: Promise<NgbModalRef>): void {
    loadingModal.then(modal => {
      modal.dismiss('done');
    });
  }

  /* Presentation logic. */

  /**
   * Initializes form data.
   */
  private loginFormInit(): void {
    this.disableForm = true;

    this.disableGoogleLogin = true;
    this.disableFacebookLogin = true;
    this.disableInstagramLogin = true;
    this.disableLinkedinLogin = false;

    // No init needed (simple form).

    this.loginFormReset();
  }

  /**
   * Resets the form.
   */
  private loginFormReset(): void {
    if (this.loginForm && this.loginForm.pristine) {
      this.loginForm.control.reset();
    }

    this.loginUserEmail = null;
    this.loginUserPassword = null;

    this.disableForm = false;
  }

  /**
   * Resets the Password Change Form.
   */
  private changePasswordFormReset(): void {
    if (this.changePasswordForm && this.changePasswordForm.pristine) {
      this.changePasswordForm.control.reset();
    }

    this.userPasswordChange = null;
    this.userPasswordChangeRepeat = null;

    this.disablePasswordRecoveryForm = false;
  }

  /**
   * Opens the Password Change Modal.
   */
  private openPasswordChangeModal(): void {
    this.passwordChangeModal = this.modalsService.showCustomModal(this.passwordChangeModalContent, 'passwordChangeModal', 'lg');
  }

  /**
   * Returns TRUE if e-mail for Password Recovery is invalid.
   */
  protected get isEmailForPasswordRecoveryInvalid(): boolean {
    return !this.helpersService.isEmailValid(this.userEmailForPasswordRecovery);
  }

  /**
   * Display an error in an common error modal.
   */
  private showErrorModalWithMessage(error: any, scrollTop: boolean = false): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'showErrorModalWithMessage'
    });
    __logger.info('Method start.');

    const appError: BaseAppError = this.errorsService.getAppError(error);
    __logger.error('Error:', appError.getMessage());

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            __logger.debug('Error modal closed with result:', result);
          })
          .catch(reason => {
            __logger.debug('Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            if (scrollTop) {
              this.viewportScroller.scrollToAnchor('app-main-header');
            }
          });
      });
  }

  /**
   *
   *  LOGIN
   *
   */

  /**
   * Init social login services.
   */
  public initSocialLogin(): void {
    // Init Google Auth SDK
    this.googleAuthService.init()
      .then(() => {
        this.disableGoogleLogin = false;
      });

    // Init Facebook SDK
    this.facebookService.init()
      .then(() => {
        this.disableFacebookLogin = false;
      });

    // Get Password Change Operation token.
    this.passwordChangeOperationToken = this.route.snapshot.paramMap.get('passwordChangeToken');
    if (this.passwordChangeOperationToken) {
      this.changePasswordFormReset();
      this.openPasswordChangeModal();

      // Removes token from params.
      this.router.navigate([_LOGIN_PAGE_URL], {replaceUrl: true});
    }

    if (this.route.routeConfig.path === _LINKEDIN_CALLBACK_URL) {
      this.route.queryParams.subscribe(params => {
        if (params['code']) {
          this.onLoginByLinkedinCallback(params['code']);
        } else if (params['error']) {
          this.onLoginFail('Ocurrió un error al iniciar sesión con Linkedin, disculpe las molestias.');
          this.router.navigate([_LOGIN_PAGE_URL], {replaceUrl: true});
        } else {
          this.router.navigate([_LOGIN_PAGE_URL], {replaceUrl: true});
        }
      });
    }
  }

  /**
   * LogIn operation failure handler.
   */
  private onLoginFail(error: any): void {
    this.showErrorModalWithMessage(error, true);
  }

  /**
   * Login submit handler.
   */
  public onLoginSubmit(): void {
    const __logger = _LOGGER.getDerivedContext({methodName: 'onLoginSubmit'});
    __logger.info('Method start.');

    if (!this.loginUserEmail || !this.loginUserPassword) {
      this.onLoginFail('Se requiere usuario y contraseña para realizar el LogIn.');
      return;
    }

    this.disableForm = true;
    const loadingModal = this.modalsService.showLoadingModal('Ingresando...');
    this.authService.doLogin(this.loginUserEmail, this.loginUserPassword)
      .subscribe((resultOrError: UserModel | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          __logger.error('doLogin resulted in error.');
          __logger.errorVerbose('Error:', resultOrError);
          this.onLoginFail(resultOrError);
        } else {
          __logger.debugVerbose('doLogin success.');
          this.onLoginSuccess(resultOrError);
        }
      }, (error: any) => {
        __logger.error('Error on doLogin.');
        __logger.errorVerbose('Error:', error);
        this.onLoginFail(error);
      }, () => {
        this.onLoginEnded(loadingModal);
      });
  }

  /**
   * Login by Google submit handler.
   */
  protected onLoginByGoogle(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'onLoginByGoogle'
    });
    _logger.info('Method start.');

    this.disableForm = true;
    const loadingModal = this.modalsService.showLoadingModal('Ingresando...');
    this.googleAuthService.login()
      .then((resultOrError: UserModel | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('onLoginByGoogle resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          this.onLoginFail(resultOrError);
        } else {
          _logger.debugVerbose('onLoginByGoogle success.');
          this.onLoginSuccess(resultOrError);
        }
      })
      .catch((error: BaseAppError) => {
        _logger.error('Error on onLoginByGoogle.');
        _logger.errorVerbose('Error:', error);
        this.onLoginFail(error);
      })
      .finally(() => {
        this.onLoginEnded(loadingModal);
      });
  }

  /**
   * Login by Facebook submit handler.
   */
  protected onLoginByFacebook(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'onLoginByFacebook'
    });
    _logger.info('Method start.');

    this.disableForm = true;
    const loadingModal = this.modalsService.showLoadingModal('Ingresando...');
    this.facebookService.login()
      .then((resultOrError: UserModel | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('onLoginByFacebook resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          this.onLoginFail(resultOrError);
        } else {
          _logger.debugVerbose('onLoginByFacebook success.');
          this.onLoginSuccess(resultOrError);
        }
      })
      .catch((error: BaseAppError) => {
        _logger.error('Error on onLoginByFacebook.');
        _logger.errorVerbose('Error:', error);
        this.onLoginFail(error);
      })
      .finally(() => {
        this.onLoginEnded(loadingModal);
      });
  }

  /**
   * Login by Linkedin submit handler.
   */
  protected onLoginByLinkedin(): void {
    this.linkedinService.openAuthTab();
  }

  protected onLoginByLinkedinCallback(accessToken: string): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'onSocialLoginSubmit'
    });
    __logger.info('Method start.');

    this.disableForm = true;
    const loadingModal = this.modalsService.showLoadingModal('Ingresando...');
    this.authService.doSocialLogin('linkedin', accessToken)
      .subscribe((resultOrError: UserModel | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          __logger.error('doSocialLogin resulted in error.');
          __logger.errorVerbose('Error:', resultOrError);
          this.onLoginFail(resultOrError);
        } else {
          __logger.debugVerbose('doSocialLogin success.');
          this.onLoginSuccess(resultOrError);
        }
      }, (error: any) => {
        __logger.error('Error on doSocialLogin.');
        __logger.errorVerbose('Error:', error);
        this.onLoginFail(error);
      }, () => {
        this.onLoginEnded(loadingModal);
      });
  }

  protected onLoginEnded(loadingModal: Promise<NgbModalRef>): void {
    this.disableForm = false;
    loadingModal.then(modal => {
      modal.dismiss('done');
    });
  }

  protected onLoginSuccess(userData: UserModel): void {
    if (userData && userData.id) {
      this.data.user = userData;
      this.onNextClick();
    }
  }

  /**
   * Password Change operation failure handler.
   */
  private onPasswordChangeFail(error: any): void {
    this.showErrorModalWithMessage(error);
  }

  /**
   * Password Change submit handler.
   */
  protected onPasswordChangeSubmit(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'onPasswordChangeSubmit'
    });
    __logger.info('Method start.');

    if (this.passwordChangeModal && (this.userPasswordChange === this.userPasswordChangeRepeat) && this.passwordChangeOperationToken) {
      this.disablePasswordChangeForm = true;
      const loadingModal = this.modalsService.showLoadingModal('Enviando...');
      this.usersService.doPasswordChange(this.userPasswordChange, this.userPasswordChangeRepeat, this.passwordChangeOperationToken)
        .subscribe((resultOrError: boolean | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError)) {
            __logger.error('doPasswordChange resulted in error.');
            __logger.errorVerbose('Error:', resultOrError);
            this.onPasswordChangeFail(resultOrError);
          } else {
            __logger.debugVerbose('doPasswordChange success.');

            this.passwordChangeOperationToken = null;
            // Close input modal.
            this.passwordChangeModal.close('Submit');
            // Open success modal.
            this.modalsService.showSuccessModal('Su contraseña ha sido cambiada. Vuelva a iniciar sesión.', 'Éxito')
              .then(modal => {
                modal.result
                  .then(result => {
                    __logger.debug('Success modal closed with result:', result);
                    this.loginFormReset();
                  })
                  .catch(reason => {
                    __logger.debug('Success modal dismissed with reason:', reason);
                    this.loginFormReset();
                  });
              });
          }
        }, (error: any) => {
          __logger.error('Error on doPasswordChange.');
          __logger.errorVerbose('Error:', error);
          this.onPasswordChangeFail(error);
        }, () => {
          this.disablePasswordChangeForm = false;
          loadingModal.then(modal => {
            modal.dismiss('Done');
          });
        });
    }
  }

  /**
   * Password Change cancel handler.
   */
  protected onPasswordChangeCancel(): void {
    this.passwordChangeModal.dismiss('Cancel');
    this.passwordChangeOperationToken = null;
  }

}
