import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ErrorsService } from '../errors/errors.service';
import { Observable, of } from 'rxjs';
import { BaseAppError } from '../errors/base-app-error';
import { ProvinceModel } from '../../models/province.model';
import { IApiCommonResponse } from '../i-api-common-response';
import { ILocalitiesGetLocalitiesResponseItem } from './i-localities-get-localities-response-item';
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { LocalityModel } from 'src/app/models/locality.model';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'localities.service.ts',
  className: 'LocalitiesService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


/**
 * Localities Service.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalitiesService {
  private baseURL = `${_CONFIG.apiBaseURL}/toyotausados/localidades`;

  constructor(
    private http: HttpClient,
    private errorsService: ErrorsService
  ) {
  }

  /**
   * Gets all localities from province.
   */
  public getLocalities(province: ProvinceModel): Observable<LocalityModel[] | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getLocalities'
    });
    __logger.info('Method start.');

      const endpointURL = `${this.baseURL}`;
      __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

      return this.http.get<IApiCommonResponse<ILocalitiesGetLocalitiesResponseItem[]>>(endpointURL, {params: {provincia_cod: province.fuId}, observe: 'response'})
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<ILocalitiesGetLocalitiesResponseItem[]>>) => {
            __logger.debug('API response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<IApiCommonResponse<ILocalitiesGetLocalitiesResponseItem[]>>) => response.body.data.map(
            locality => new LocalityModel(locality.id, locality.nombre, locality.codigo_postal))),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );
  }
}
