import { Component, OnInit } from '@angular/core';
import {EnvironmentManager} from '../../../../shared/environment-manager.shared';

const _CONFIG = EnvironmentManager.getInstance().getConfig();


@Component({
  selector: 'app-sell-page',
  templateUrl: './sell-page.component.html',
  styleUrls: ['./sell-page.component.scss']
})
export class SellPageComponent implements OnInit {
  protected ventaUsadosURL: string = _CONFIG.ventaUsadosURL;
  constructor() {
  }

  public ngOnInit(): void {
  }
  
  onOpenVentaUsadosTab(){
    window.open(this.ventaUsadosURL, "_blank");
  }

}
