import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PublicationsService } from '../../../../services/publications/publications.service';
import { LoggerContext, SimpleLogger } from 'src/app/shared/simple-logger.shared';
import { NgForm } from '@angular/forms';
import { HelpersService } from '../../../../services/helpers/helpers.service';
import { BaseAppError } from 'src/app/services/errors/base-app-error';
import { ErrorsService } from "../../../../services/errors/errors.service";
import { ViewportScroller } from "@angular/common";
import { ModalsService } from "../../../../services/modals/modals.service";


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'share-friend.component.ts',
  className: 'ShareFriendModalComponent',
  tagName: 'PAGES'
});
_LOGGER.debugVerbose('Loaded.');

/**
 * Share Friend Component
 */
@Component({
  selector: 'app-share-friend',
  templateUrl: './share-friend.component.html',
  styleUrls: ['./share-friend.component.scss']
})
export class ShareFriendModalComponent implements OnInit {
  /** Form controls */
  @ViewChild('shareFriendForm') protected shareFriendForm: NgForm;

  /** Form data. */
  protected disableForm: boolean;
  @Input() publicationId: number;
  protected name: string;
  protected email: string;
  protected friendName: string;
  protected friendEmail: string;
  protected message: string;

  constructor(
    public shareModal: NgbActiveModal,
    private publicationsService: PublicationsService,
    private helpersService: HelpersService,
    private errorsService: ErrorsService,
    private viewportScroller: ViewportScroller,
    private modalsService: ModalsService
  ) {
  }

  /**
   * Initializes form data
   */
  private formInit(): void {
    this.disableForm = true;

    this.formReset();
  }


  /**
   * Resets the form
   */
  private formReset(): void {
    if (this.shareFriendForm && this.shareFriendForm.pristine) {
      this.shareFriendForm.control.reset();
    }

    this.name = null;
    this.onChangeName();
    this.email = null;
    this.onChangeEmail();
    this.friendName = null;
    this.onChangeFriendName();
    this.friendEmail = null;
    this.onChangeFriendEmail();
    this.disableForm = false;
    this.message = null;
    this.onChangeMessage();
  }

  /**
   * Returns TRUE if e-mail is invalid.
   */
  protected get isEmailInvalid(): boolean {
    return !this.helpersService.isEmailValid(this.email);
  }

  /**
   * Returns TRUE if friend's e-mail is invalid,
   */
  protected get isFriendEmailInvalid(): boolean {
    return !this.helpersService.isEmailValid(this.friendEmail);
  }

  ngOnInit() {
    this.formInit();
  }

  /**
   * User changes Name data handler.
   */
  protected onChangeName(): void {
    //No action needed.
  }

  /**
   * User changes Email data handler.
   */
  protected onChangeEmail(): void {
    //No action needed.
  }

  /**
   * User changes FriendName data handler.
   */
  protected onChangeFriendName(): void {
    //No action needed.
  }

  /**
   * User changes FriendEmail data handler.
   */
  protected onChangeFriendEmail(): void {
    //No action needed.
  }

  /**
   * User changes Message data handler.
   */
  protected onChangeMessage(): void {
    //No action needed.
  }

  /**
   * Share operation failure handler.
   */
  private onShareFail(error: any): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'onShareFail'
    });
    __logger.info('Method start.');

    const appError: BaseAppError = this.errorsService.getAppError(error);
    __logger.error('Error:', appError.getMessage());

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            __logger.debug('Error modal closed with result:', result);
          })
          .catch(reason => {
            __logger.debug('Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
          });
      });
  }

  /**
   * Share publication with friend handler.
   */
  protected onShareViaEmail(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'onShareViaEmail'
    });
    __logger.info('Method start.');

    if (!this.name || !this.email || !this.friendName || !this.friendEmail || !this.message) {
      this.onShareFail('Todos los campos son obligatorios.');
      return;
    }

    this.disableForm = true;

    const loadingModal = this.modalsService.showLoadingModal('Enviando...');
    this.publicationsService.doShareViaEmail(this.publicationId, this.name, this.email,
      this.friendName, this.friendEmail, this.message)
      .subscribe((resultOrError: boolean | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          __logger.error('doShareViaEmail resulted in error.');
          __logger.errorVerbose('Error:' + resultOrError);
          this.onShareFail(resultOrError);
        } else {
          __logger.debugVerbose('doShareViaEmail success.');
          this.modalsService.showSuccessModal('La publicación se ha compartido con éxito.', 'Publicación compartida.')
            .then(modal => {
              modal.result
                .then(result => {
                  __logger.debug('Error modal closed with result:', result);
                })
                .catch(reason => {
                  __logger.debug('Error modal dismissed with reason:', reason);
                })
                .finally(() => {
                  this.viewportScroller.scrollToAnchor('app-main-header');
                  this.onAccept();
                });
            });
        }
      }, (error: any) => {
        __logger.error('Error on doShareViaEmail.');
        __logger.errorVerbose('Error:', error);
        this.onShareFail(error);
      }, () => {
        this.disableForm = false;
        loadingModal.then(modal => {
          modal.dismiss('done');
        });
      });
  }

  /**
   * Closes modal.
   */
  protected onAccept(): void {
    this.shareModal.close('Dismiss');
  }
}
