import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { LocalityModel } from 'src/app/models/locality.model';
import { ParamModel } from 'src/app/models/param.model';
import { ProvinceModel } from 'src/app/models/province.model';
import { VehicleBrandModel } from 'src/app/models/vehicle-brand.model';
import { VehicleModelModel } from 'src/app/models/vehicle-model.model';
import { VehicleVersionModel } from 'src/app/models/vehicle-version.model';
import { BaseAppError } from 'src/app/services/errors/base-app-error';
import { LocalitiesService } from 'src/app/services/localities/localities.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { ModelsService } from 'src/app/services/models/models.service';
import { BrandService } from 'src/app/services/brand/brand.service';
import { ParamsService } from 'src/app/services/params/params.service';
import { ProvincesService } from 'src/app/services/provinces/provinces.service';
import { PublicationsService } from 'src/app/services/publications/publications.service';
import { ISellData } from 'src/app/services/sell/i-sell-data';
import { VersionsService } from 'src/app/services/versions/versions.service';
import { LoggerContext, SimpleLogger } from '../../../../../shared/simple-logger.shared';
import { UserActionService } from '../../../../../services/user-action/user-action.service';
import { UserActionModel } from '../../../../../models/user-action-send.model';

const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'sell-step-one.component.ts',
  className: 'SellStepOneComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');

enum INTERNAL_STEP {
  YEAR,
  MODEL,
  VERSION,
  MILEAGE,
  DOMAIN
}

interface InternalStepStatus {
  [key: number]: {
    label: string;
    value?: string;
  };
}

/**
 * Page Header component to place top of most views.
 */
@Component({
  selector: 'app-sell-step-one',
  templateUrl: './sell-step-one.component.html',
  styleUrls: ['./sell-step-one.component.scss']
})
export class SellStepOneComponent implements OnInit, OnDestroy {
  public validYears: string[];
  public models: VehicleModelModel[];
  public versions: VehicleVersionModel[];
  public provinces: ProvinceModel[];
  public localities: LocalityModel[];
  public kms: string;
  public domainExists: boolean;
  public domainInvalid: boolean;
  private brand: VehicleBrandModel;

  @Input() nextClick: () => void;
  @Input() backClick: () => void;
  @Input() data: ISellData;
  @Input() internalBackStep: Observable<void>;

  protected loadingModal: Promise<NgbModalRef>;
  protected internalBackStepEventSubscription: Subscription;

  public readonly INTERNAL_STEP_YEAR = INTERNAL_STEP.YEAR;
  public readonly INTERNAL_STEP_MODEL = INTERNAL_STEP.MODEL;
  public readonly INTERNAL_STEP_VERSION = INTERNAL_STEP.VERSION;
  public readonly INTERNAL_STEP_MILEAGE = INTERNAL_STEP.MILEAGE;
  public readonly INTERNAL_STEP_DOMAIN = INTERNAL_STEP.DOMAIN;
  public readonly availableSteps: INTERNAL_STEP[] = [this.INTERNAL_STEP_YEAR, this.INTERNAL_STEP_MODEL,
  this.INTERNAL_STEP_VERSION, this.INTERNAL_STEP_MILEAGE, this.INTERNAL_STEP_DOMAIN];
  public internalStep: INTERNAL_STEP = this.availableSteps[0];
  public internalStepStatus: InternalStepStatus = {
    [this.INTERNAL_STEP_YEAR]: {
      label: 'Año'
    },
    [this.INTERNAL_STEP_MODEL]: {
      label: 'Modelo'
    },
    [this.INTERNAL_STEP_VERSION]: {
      label: 'Versión'
    },
    [this.INTERNAL_STEP_YEAR]: {
      label: 'Año'
    },
    [this.INTERNAL_STEP_MILEAGE]: {
      label: 'Cantidad de Kilómetros'
    },
    [this.INTERNAL_STEP_DOMAIN]: {
      label: 'Patente'
    }
  };

  constructor(
    private paramsService: ParamsService,
    private modelsService: ModelsService,
    private versionsService: VersionsService,
    private provincesService: ProvincesService,
    private localitiesService: LocalitiesService,
    private publicationsService: PublicationsService,
    private modalsService: ModalsService,
    private brandService: BrandService,
    private userActionService: UserActionService
  ) {
    this.domainExists = false;
    this.domainInvalid = false;
  }

  public ngOnInit(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'ngOnInit'
    });
    _logger.info('Method start.');

    this.openLoadingModal();

    Promise.all(
      [
        this.getValidYears(),
        this.getProvinces(),
        this.getBrandToyota()
      ]
    )
      .then(() => this.closeLoadingModal())
      .then(() => this.reloadIfNeeded())
      .catch(() => this.closeLoadingModal());

    this.internalBackStepEventSubscription = this.internalBackStep.subscribe(() => {
      if (this.internalStep > 0) {
        this.internalStep--;
      } else {
        this.backClick();
      }
    });
  }

  public ngOnDestroy(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'ngOnDestroy'
    });
    _logger.info('Method start.');

    this.internalBackStepEventSubscription.unsubscribe();
  }

  public onNextClick(): void {
    this.nextClick();
  }

  public onInternalNextClick(): void {
    switch (this.internalStep) {
      case INTERNAL_STEP.YEAR:
        if (!this.data.year) {
          return;
        } else {
          this.internalStepStatus[INTERNAL_STEP.YEAR].value = this.data.year;
          this.getModels(this.data.year, this.brand);
          this.sendUserActionSellStepOne('venta_año', this.data.year);
        }

        break;
      case INTERNAL_STEP.MODEL:
        if (!this.data.model) {
          return;
        } else {
          this.internalStepStatus[INTERNAL_STEP.MODEL].value = this.data.model.name;
          this.getVersions();
          this.sendUserActionSellStepOne('venta_modelo', this.data.model.id);
        }
        break;
      case INTERNAL_STEP.VERSION:
        if (!this.versions || this.versions.length && !this.data.version) {
          return;
        } else {
          this.internalStepStatus[INTERNAL_STEP.VERSION].value = this.data.version ? this.data.version.name : 'Única';
          this.sendUserActionSellStepOne('venta_version', this.data.version.id);
        }
        break;
      case INTERNAL_STEP.MILEAGE:
        if (!this.data.kms) {
          return;
        } else {
          this.internalStepStatus[INTERNAL_STEP.MILEAGE].value = this.kms + ' km';
          this.sendUserActionSellStepOne('venta_kilometros', this.kms);
        }
        break;
      case INTERNAL_STEP.DOMAIN:
        if (!this.data.domain || this.domainExists || this.domainInvalid || !this.data.province || !this.data.locality) {
          return;
        } else {
          let paramsAction = "patente="+this.data.domain+"&provincia="+this.data.province.id+"&localidad="+this.data.locality.id;
          this.sendUserActionSellStepOne('venta_patente_prov_loc', paramsAction);
          this.onNextClick();
        }
        break;
    }

    this.internalStep = this.internalStep + 1;
  }

  public onChangeVehicleModel(): void {
  }

  public onChangeProvince() {
    this.getLocalities();
  }

  public onVehicleDomainLostFocus() {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'onVehicleDomainLostFocus'
    });
    _logger.info('Method start.');

    if (!this.data.domain) {
      return;
    }

    if (!/^([A-Z]{3}[0-9]{3})$/g.test(this.data.domain) && !/^([A-Z]{2}[0-9]{3}[A-Z]{2})$/g.test(this.data.domain)) {
      this.domainInvalid = true;
      return;
    }

    this.openLoadingModal();
    this.publicationsService.domainExists(this.data.domain).subscribe(result => {
      if (result instanceof BaseAppError) {
        _logger.error(result.getMessage());
        return;
      }

      this.domainExists = result;
      if (this.domainExists) {
        this.modalsService.showAlertModal(
          'El dominio ingresado ya se encuentra publicado por uno de nuestros concesionarios.',
          'Dominio en uso'
        );
      }
    }, error => {
      _logger.error(error.getMessage());
    }, () => {
      this.closeLoadingModal();
    });
  }

  public onVehicleDomainInput(e: Event) {
    this.domainExists = false;
    this.domainInvalid = false;

    if (!!this.data.domain) {
      this.data.domain = this.data.domain.toUpperCase();
      this.data.domain = this.data.domain.replace(/[^A-Z0-9]/gi, '');
      this.data.domain = this.data.domain.substring(0, 7);
      (e.target as HTMLInputElement).value = this.data.domain;
    }
  }

  public onVehicleKmsInput(e: Event) {
    this.domainExists = false;
    this.domainInvalid = false;

    if (!!this.kms) {
      this.data.kms = +this.kms.replace(/[^0-9]/g, '');
      this.kms = new Intl.NumberFormat('es-AR').format(this.data.kms);
      (e.target as HTMLInputElement).value = this.kms;
    } else {
      this.data.kms = null;
    }
  }

  public reloadIfNeeded() {
    if (!this.data || !this.data.year || !this.data.locality) {
      return;
    }

    this.openLoadingModal();
    Promise.all(
      [
        this.getVersions(false),
        this.getLocalities(false)
      ]
    ).finally(() => this.closeLoadingModal());

    this.kms = new Intl.NumberFormat('es-AR').format(this.data.kms);

    this.internalStep = INTERNAL_STEP.DOMAIN;
    this.internalStepStatus[INTERNAL_STEP.YEAR].value = this.data.year;
    this.internalStepStatus[INTERNAL_STEP.MODEL].value = this.data.model.name;
    this.internalStepStatus[INTERNAL_STEP.VERSION].value = this.data.version ? this.data.version.name : 'Única';
    this.internalStepStatus[INTERNAL_STEP.MILEAGE].value = this.kms + ' km';
    this.internalStepStatus[INTERNAL_STEP.DOMAIN].value = this.data.domain;
  }

  private getValidYears() {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'getValidYears'
    });
    _logger.info('Method start.');

    return new Promise((resolve, reject) => {
      this.paramsService.getParamByKey('anios_permitidos').subscribe((resultOrError: ParamModel | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('getValidYears resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          reject(resultOrError);
        } else {
          _logger.debugVerbose('getValidYears success.');
          const year = new Date().getFullYear()
          const minYear = year - (!!resultOrError.paramValue ? +resultOrError.paramValue : 1);
          const yearRange = [];

          for (let i = minYear; i <= year; i++) {
            yearRange.push(i);
          }
          this.validYears = yearRange.reverse();
          resolve(this.validYears);
        }
      }, (error: BaseAppError) => {
        _logger.error('Error on getValidYears.');
        _logger.errorVerbose('Error:', error);
        reject(error);
      });
    });
  }

  private getModels(year: string, brand: VehicleBrandModel) {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'getModels'
    });
    _logger.info('Method start.');

    return new Promise((resolve, reject) => {
      this.modelsService.getModelsByYear(year, brand).subscribe((resultOrError: VehicleModelModel[] | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('getModels resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          reject(resultOrError);
          this.modalsService
            .showErrorModal("Ha ocurrido un error, por favor comuníquese con el administrador.")
            .then(modal => {
              modal.result
                .then(result => {
                  _logger.debug('Error modal closed with result:', result);
                })
                .catch(reason => {
                  _logger.debug('Error modal dismissed with reason:', reason);
                })
                .finally(() => {
                  this.models = [];
                  this.internalStep--;
                });
            });

        } else {
          _logger.debugVerbose('getModels success.');

          this.models = resultOrError;
          resolve(resultOrError);
        }
      }, (error: BaseAppError) => {
        _logger.error('Error on getModels.');
        _logger.errorVerbose('Error:', error);
        reject(error);
      });
    });
  }

  private getBrandToyota() {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'getBrand'
    });
    _logger.info('Method start.');

    return new Promise((resolve, reject) => {
      this.brandService.getBrand().subscribe((resultOrError: VehicleBrandModel[] | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('getBrand resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          reject(resultOrError);
        } else {
          _logger.debugVerbose('getBrand success.');

          this.brand = resultOrError[0];
          resolve(resultOrError);
        }
      }, (error: BaseAppError) => {
        _logger.error('Error on getBrand.');
        _logger.errorVerbose('Error:', error);
        reject(error);
      });
    });
  }

  private getVersions(showLoading = true) {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'getVersions'
    });
    _logger.info('Method start.');

    if (showLoading) {
      this.openLoadingModal();
    }

    return new Promise((resolve, reject) => {
      this.versionsService.getVersionsByYearAndModel(this.data.model, this.data.year).subscribe((resultOrError: VehicleVersionModel[] | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('getVersions resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          reject(resultOrError);
          this.modalsService
            .showErrorModal("Ha ocurrido un error, por favor comuníquese con el administrador.")
            .then(modal => {
              modal.result
                .then(result => {
                  _logger.debug('Error modal closed with result:', result);
                })
                .catch(reason => {
                  _logger.debug('Error modal dismissed with reason:', reason);
                })
                .finally(() => {
                  this.versions = [];
                  this.internalStep--;
                });
            });
        } else {
          _logger.debugVerbose('getVersions success.');

          this.versions = resultOrError;
          resolve(resultOrError);
        }
      }, (error: BaseAppError) => {
        _logger.error('Error on getVersions.');
        _logger.errorVerbose('Error:', error);
        reject(error);
      }, () => {
        if (showLoading) {
          this.closeLoadingModal();
        }
      });
    });
  }

  private getProvinces() {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'getProvinces'
    });
    _logger.info('Method start.');

    return new Promise((resolve, reject) => {
      this.provincesService.getProvinces().subscribe((resultOrError: ProvinceModel[] | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('getProvinces resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          reject(resultOrError);
        } else {
          _logger.debugVerbose('getProvinces success.');

          this.provinces = resultOrError;
          resolve(resultOrError);
        }
      }, (error: BaseAppError) => {
        _logger.error('Error on getProvinces.');
        _logger.errorVerbose('Error:', error);
        reject(error);
      });
    });
  }

  private getLocalities(showLoading = true) {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'getLocalities'
    });
    _logger.info('Method start.');

    if (showLoading) {
      this.openLoadingModal();
    }
    return new Promise((resolve, reject) => {
      this.localitiesService.getLocalities(this.data.province).subscribe((resultOrError: LocalityModel[] | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('getLocalities resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          reject(resultOrError);
        } else {
          _logger.debugVerbose('getLocalities success.');

          this.localities = resultOrError;
          resolve(resultOrError);
        }
      }, (error: BaseAppError) => {
        _logger.error('Error on getLocalities.');
        _logger.errorVerbose('Error:', error);
        reject(error);
      }, () => {
        if (showLoading) {
          this.closeLoadingModal();
        }
      });
    });
  }

  public onSelectYear(year: string): void {
    if (this.data.year === year) {
      this.data.year = null;
    } else {
      this.data.year = year.toString();
    }
  }

  public equals(a, b) {
    if (a && b && a.id && b.id) {
      return a.id === b.id;
    } else {
      return a === b;
    }
  }

  public equalsLocality(a, b) {
    if (a && b && a.name && b.name) {
      return a.name === b.name && a.postalCode === b.postalCode;
    } else {
      return a === b;
    }
  }

  private openLoadingModal(): void {
    if (!this.loadingModal) {
      this.loadingModal = this.modalsService.showLoadingModal('Cargando...');
    }
  }

  private closeLoadingModal() {
    if (this.loadingModal) {
      return this.loadingModal.then(modal => {
        modal.dismiss('done');
        this.loadingModal = null;
        return true;
      });
    } else {
      return Promise.resolve(false);
    }
  }

  private sendUserActionSellStepOne(action, data) {
    const logger = _LOGGER.getDerivedContext({methodName: 'sendUserActionSellStepOne'});
    logger.info('Method start.');
    this.userActionService.sendUserAction(action, data).subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
  }
}
