import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ErrorsService } from '../errors/errors.service';
import { Observable, of } from 'rxjs';
import { BaseAppError } from '../errors/base-app-error';
import { IApiCommonResponse } from '../i-api-common-response';
import { catchError, map, tap } from 'rxjs/operators';
import { IPublicationsGetPublicationsResponsePublicationItem } from './i-publications-get-publications-response-publication-item';
import { PublicationModel } from '../../models/publication.model';
import { VehicleModelModel } from '../../models/vehicle-model.model';
import { VehicleVersionModel } from '../../models/vehicle-version.model';
import { ProvinceModel } from '../../models/province.model';
import { IPublicationsSearchAutocompleteResponseResultItem } from './i-publications-search-autocomplete-response-result-item';
import { PublicationSearchResultModel } from '../../models/publication-search-result.model';
import { VehicleBrandModel } from '../../models/vehicle-brand.model';
import { IPublicationsGetPublicationsResponsePublicationItemFull } from './i-publications-get-publications-response-publication-item-full';
import { IApiCommonPaginatedResponse } from '../i-api-common-paginated-response';
import { PageDataModel } from "../../models/page-data.model";
import { IPublicationsGetPublicationByIdResponse } from "./i-publications-get-publication-by-id-response";
import { PublicationDetailModel } from "../../models/publication-detail.model";
import { IPublicationsGetPublicationByIdResponseAutoDataAmenity } from "./i-publications-get-publication-by-id-response-auto-data";
import { PublicationAmenityCategoryModel } from "../../models/publication-amenity-category.model";
import { PublicationAmenityModel } from "../../models/publication-amenity.model";
import { IPublicationsGetAmenitiesCategoriesResponse } from "./i-publications-get-amenities-categories-response";
import { PublicationDealerModel } from "../../models/publication-dealer.model";
import { AuthService } from "../auth/auth.service";
import * as moment from 'moment';
import { PlanSubplanModel } from '../../models/plan-subplan.model';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'publications.service.ts',
  className: 'PublicationsService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


/**
 * Publications Service.
 */
@Injectable({
  providedIn: 'root'
})
export class PublicationsService {
  private baseURL = `${_CONFIG.apiBaseURL}/toyotausados/publicacion`;
  private baseURLAmenities = `${_CONFIG.apiBaseURL}/comodidades`;
  private baseURLConsultas = `${_CONFIG.apiBaseURL}/toyotausados/consulta`;

  private publicationsAmenitiesCategoriesNames: { id: number, name: string }[] = [{
    id: 1,
    name: 'Sonido'
  }, { id: 2, name: 'Seguridad' }, { id: 3, name: 'Confort' }, { id: 4, name: 'Exterior' }];

  constructor(
    private http: HttpClient,
    private errorsService: ErrorsService,
    private authService: AuthService
  ) {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'constructor'
    });
    __logger.infoVerbose('Created.');

    this.getAmenitiesCategories()
      .subscribe((resultOrError: PublicationAmenityCategoryModel[] | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          __logger.errorVerbose('Amenities Error:', resultOrError);
        } else {
          __logger.debugVerbose('Amenities success.');
          this.publicationsAmenitiesCategoriesNames = resultOrError.map(amenityCategory => {
            return { id: amenityCategory.id, name: amenityCategory.name };
          });
        }
      }, (error: any) => {
        __logger.errorVerbose('Error on Amenities:', error);
      });
  }

  /**
   * Get Publications from a given Endpoint.
   */
  private getPublications(endpointURL: string): Observable<PublicationModel[] | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getPublications'
    });
    __logger.info('Method start.');

    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);
    return this.http.get<IApiCommonResponse<IPublicationsGetPublicationsResponsePublicationItem[]>>(endpointURL, { observe: 'response' })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<IPublicationsGetPublicationsResponsePublicationItem[]>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IApiCommonResponse<IPublicationsGetPublicationsResponsePublicationItem[]>>) => response.body.data.map(
          publication => new PublicationModel(publication.id,
            publication.car_images.map(i => `${_CONFIG.publicationsImagesContextUrl}/${publication.id}/search${i.url}`),
            parseFloat(publication.price.toString()), parseFloat(publication.cuota.toString()), parseFloat(publication.price_calculated.toString()), publication.year, publication.kms, publication.tittle,
            new VehicleBrandModel(publication.version.modelo.marca.id, publication.version.modelo.marca.mar_descripcion_s,
              publication.version.modelo.marca.id_fu),
            new VehicleModelModel(publication.version.modelo.id, publication.version.modelo.mod_descripcion_s, publication.version.modelo.id_fu),
            new VehicleVersionModel(publication.version.id, publication.version.ver_descripcion_s, publication.version_id_fu),
            new ProvinceModel(publication.provincia.id, publication.provincia.name),
            !!publication.is_certificado
          ))),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }

  /**
   * Get Publications full data from a given Endpoint.
   */
  private getPublicationsFullPaginated(endpointURL: string): Observable<PageDataModel<PublicationModel> | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getPublicationsFullPaginated'
    });
    __logger.info('Method start.');

    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);
    return this.http.get<IApiCommonPaginatedResponse<IPublicationsGetPublicationsResponsePublicationItemFull>>(endpointURL, { observe: 'response' })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonPaginatedResponse<IPublicationsGetPublicationsResponsePublicationItemFull>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IApiCommonPaginatedResponse<IPublicationsGetPublicationsResponsePublicationItemFull>>) => new PageDataModel<PublicationModel>(
          response.body.data.total, response.body.data.per_page, response.body.data.from, response.body.data.to,
          1, response.body.data.current_page, response.body.data.last_page, response.body.data.data.map(
            publication => new PublicationModel(publication.id,
              publication.car_images.map(i => `${_CONFIG.publicationsImagesContextUrl}/${publication.id}/search${i.url}`),
              parseFloat(publication.price.toString()), parseFloat(publication.cuota.toString()), parseFloat(publication.price_calculated.toString()), publication.year, publication.kms, publication.tittle,
              new VehicleBrandModel(publication.version.modelo.marca.id, publication.version.modelo.marca.mar_descripcion_s,
                publication.version.modelo.marca.id_fu),
              new VehicleModelModel(publication.version.modelo.id, publication.version.modelo.mod_descripcion_s, publication.version.modelo.id_fu),
              new VehicleVersionModel(publication.version.id, publication.version.ver_descripcion_s, publication.version_id_fu),
              new ProvinceModel(publication.provincia.id, publication.provincia.name),
              !!publication.is_certificado
            )))),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }

  /**
   * Gets Publications filtered by title.
   */
  public getPublicationsByTitle(title: string): Observable<PageDataModel<PublicationModel> | BaseAppError> {
    const endpointURL = `${this.baseURL}/search?title=${encodeURIComponent(title)}`;
    return this.getPublicationsFullPaginated(endpointURL);
  }

  /**
   * Gets Publications filtered by various fields (advanced search).
   */
  public getPublicationsByAdvancedSearch(yearFrom?: number, yearTo?: number, idMarca?: number, idModel?: number, idVersion?: number, idProvince?: number,
    mileageFrom?: number, mileageTo?: number, priceFrom?: number, priceTo?: number,
    certified?: boolean, idDealer?: number, installmentsFrom?: number,
    installmentsTo?: number, sorting: string = 'dest', page?: number): Observable<PageDataModel<PublicationModel> | BaseAppError> {
    let queryParams: { [key: string]: string } = {};
    if (yearFrom) {
      queryParams.year_from = yearFrom.toString();
    }
    if (yearTo) {
      queryParams.year_to = yearTo.toString();
    }
    if (idMarca) {
      queryParams.marca_id = idMarca.toString();
    }
    if (idModel) {
      queryParams.modelo_id = idModel.toString();
    }
    if (idVersion) {
      queryParams.version_id = idVersion.toString();
    }
    if (idProvince) {
      queryParams.provincia_id = idProvince.toString();
    }
    if (mileageFrom || (mileageFrom === 0)) {
      queryParams.kms_from = mileageFrom.toString();
    }
    if (mileageTo || (mileageTo === 0)) {
      queryParams.kms_to = mileageTo.toString();
    }
    if (priceFrom || (priceFrom === 0)) {
      queryParams.price_from = priceFrom.toString();
    }
    if (priceTo || (priceTo === 0)) {
      queryParams.price_to = priceTo.toString();
    }
    if (installmentsFrom || (installmentsFrom === 0)) {
      queryParams.cuota_from = installmentsFrom.toString();
    }
    if (installmentsTo || (installmentsTo === 0)) {
      queryParams.cuota_to = installmentsTo.toString();
    }
    if ((certified !== null) && (certified !== undefined)) {
      queryParams.is_certificado = certified.toString();
    }
    if (idDealer) {
      queryParams.concesionario_id = idDealer.toString();
    }
    if (sorting) {
      queryParams.sort = sorting;
    }
    if (page || (page === 0)) {
      queryParams.page = page.toString();
    }
    let params = new HttpParams({ fromObject: queryParams });

    const endpointURL = `${this.baseURL}/search?${params.toString()}`;
    return this.getPublicationsFullPaginated(endpointURL);
  }

  /**
   * Gets all Publications nearby a given location (Lat/Long).
   */
  public getPublicationsNearby(latitude: number, longitude: number): Observable<PublicationModel[] | BaseAppError> {
    if (!latitude || !longitude) {
      latitude = -39.4520765;
      longitude = -63.9034918;
    }
    const endpointURL = `${this.baseURL}/cercanas?latitude=${latitude}&longitude=${longitude}`;
    return this.getPublications(endpointURL);
  }

  /**
   * Gets all Certified Publications nearby a given location (Lat/Long).
   */
  public getCertifiedPublicationsNearby(latitude: number, longitude: number): Observable<PublicationModel[] | BaseAppError> {
    if (!latitude || !longitude) {
      latitude = -39.4520765;
      longitude = -63.9034918;
    }
    const endpointURL = `${this.baseURL}/certificadas?latitude=${latitude}&longitude=${longitude}`;
    return this.getPublications(endpointURL);
  }

  /**
   * Get Publications by its Dealer ID.
   */
  public getPublicationsByDealerId(id: number): Observable<PageDataModel<PublicationModel> | BaseAppError> {
    const endpointURL = `${this.baseURL}/concesionario?concesionario_id=${id}`;
    return this.getPublicationsFullPaginated(endpointURL);
  }

  /**
   * Get Publications filtered by a given criteria.
   */
  public searchPublications(queryText: string): Observable<PublicationSearchResultModel[] | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'searchPublications'
    });
    __logger.info('Method start.');

    const endpointURL = `${this.baseURL}/search/autocomplete?query=${queryText}`;
    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

    return this.http.get<IApiCommonResponse<IPublicationsSearchAutocompleteResponseResultItem[]>>(endpointURL, { observe: 'response' })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<IPublicationsSearchAutocompleteResponseResultItem[]>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IApiCommonResponse<IPublicationsSearchAutocompleteResponseResultItem[]>>) => response.body.data.map(
          pResult => new PublicationSearchResultModel(pResult.tittle))),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }

  /**
   * Get a Publication by its ID.
   */
  public getAmenitiesCategories(): Observable<PublicationAmenityCategoryModel[] | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getAmenitiesCategories'
    });
    __logger.info('Method start.');

    const endpointURL = `${this.baseURLAmenities}`;
    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

    return this.http.get<IApiCommonResponse<IPublicationsGetAmenitiesCategoriesResponse>>(endpointURL, { observe: 'response' })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<IPublicationsGetAmenitiesCategoriesResponse>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IApiCommonResponse<IPublicationsGetAmenitiesCategoriesResponse>>) => response.body.data.categorias
          .map((catItem) => new PublicationAmenityCategoryModel(catItem.id, catItem.descripcion, []))),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }

  private createPublicationDetail(publicationInterfaceAPI: IPublicationsGetPublicationByIdResponse): PublicationDetailModel {
    let amenitiesByCat: PublicationAmenityCategoryModel[] = [];
    let lastAmenityCatId: number = -1;
    publicationInterfaceAPI.auto.comodidades
      .sort((a, b) => parseInt(a.id_categoria, 10) - parseInt(b.id_categoria, 10))
      .forEach((amenityItem: IPublicationsGetPublicationByIdResponseAutoDataAmenity) => {
        const catId = parseInt(amenityItem.id_categoria, 10);
        let category: PublicationAmenityCategoryModel;
        if (lastAmenityCatId !== catId) {
          lastAmenityCatId = catId;
          category = new PublicationAmenityCategoryModel(catId,
            this.publicationsAmenitiesCategoriesNames[catId] ? this.publicationsAmenitiesCategoriesNames[catId].name : 'Comodidades', []);
          amenitiesByCat.push(category);
        } else {
          category = amenitiesByCat[amenitiesByCat.length - 1];
        }
        category.amenities.push(new PublicationAmenityModel(amenityItem.id, amenityItem.descripcion))
      });
    return new PublicationDetailModel(
      publicationInterfaceAPI.id, moment(publicationInterfaceAPI.startsAt).toDate(), moment(publicationInterfaceAPI.endsAt).toDate(), publicationInterfaceAPI.auto.vehiculo_id_fu,
      publicationInterfaceAPI.auto.car_images.map(i => `${_CONFIG.publicationsImagesContextUrl}/${publicationInterfaceAPI.id}/${i.url}`),
      publicationInterfaceAPI.auto.youtube_url,
      publicationInterfaceAPI.auto.price,
      publicationInterfaceAPI.auto.price_calculated,
      publicationInterfaceAPI.auto.year, publicationInterfaceAPI.auto.kms, publicationInterfaceAPI.auto.tittle,
      new VehicleBrandModel(publicationInterfaceAPI.auto.version.modelo.marca.id, publicationInterfaceAPI.auto.version.modelo.marca.mar_descripcion_s,
        publicationInterfaceAPI.auto.version.modelo.marca.id_fu),
      new VehicleModelModel(publicationInterfaceAPI.auto.version.modelo.id, publicationInterfaceAPI.auto.version.modelo.mod_descripcion_s,
        publicationInterfaceAPI.auto.version.modelo.id_fu),
      new VehicleVersionModel(publicationInterfaceAPI.auto.version.id, publicationInterfaceAPI.auto.version.ver_descripcion_s,
        publicationInterfaceAPI.auto.version.version_id_fu),
      publicationInterfaceAPI.auto.description, amenitiesByCat,
      new PublicationDealerModel(publicationInterfaceAPI.concesionario.id, publicationInterfaceAPI.concesionario.razon_social, publicationInterfaceAPI.concesionario.cuit,
        `${_CONFIG.dealerImagesContextUrl}/${publicationInterfaceAPI.concesionario.cuit}/logo_pdf.png`),
      !!publicationInterfaceAPI.auto.is0km,
      !!publicationInterfaceAPI.auto.is_certificado,
      false,
      false,
      publicationInterfaceAPI.auto.planes.map(p => new PlanSubplanModel(p.p_pla_id_i, p.plazos.map(pl => pl.plazo))),
      publicationInterfaceAPI.auto.mandato_venta ? new Date(publicationInterfaceAPI.auto.fecha_inspeccion) : undefined
    );
  }

  /**
   * Get a Publication by its ID.
   */
  public getPublicationById(id: number): Observable<PublicationDetailModel | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getPublicationById'
    });
    __logger.info('Method start.');

    const endpointURL = `${this.baseURL}/detalle?id=${id}`;
    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

    return this.http.get<IApiCommonResponse<IPublicationsGetPublicationByIdResponse>>(endpointURL, { observe: 'response' })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<IPublicationsGetPublicationByIdResponse>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IApiCommonResponse<IPublicationsGetPublicationByIdResponse>>) => this.createPublicationDetail(response.body.data)),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }

  /**
   * Get a Publication by a preview token.
   */
  public getPublicationPreview(token: string): Observable<PublicationDetailModel | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getPublicationPreview'
    });
    __logger.info('Method start.');

    const endpointURL = `${this.baseURL}/preview/?token=${token}`;
    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

    return this.http.get<IApiCommonResponse<IPublicationsGetPublicationByIdResponse>>(endpointURL, { observe: 'response' })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<IPublicationsGetPublicationByIdResponse>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IApiCommonResponse<IPublicationsGetPublicationByIdResponse>>) => this.createPublicationDetail(response.body.data)),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }

  /**
   * Share a Publication via E-mail.
   */
  public doShareViaEmail(publicationId: number, name: string, email: string, friendName: string, friendEmail: string, message: string): Observable<boolean | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'doShareViaEmail'
    });
    __logger.info('Method start.');

    const endpointURL = `${this.baseURL}/share/email`;
    const payload = {
      id: publicationId,
      nombre: name,
      email: email,
      nombre_amigo: friendName,
      email_amigo: friendEmail,
      mensaje: message
    };
    __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);

    return this.http.post<IApiCommonResponse<any>>(endpointURL, payload, { observe: 'response' })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<any>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response. Get User data.
        map((response: HttpResponse<IApiCommonResponse<any>>) => response.ok),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }

  /**
   * Send Message.
   */
  public sendMessage(publicationId: number, message: string, celular: string, telefono: string): Observable<boolean | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'sendMessage'
    });
    __logger.info('Method start.');

    const endpointURL = `${this.baseURLConsultas}/publicacion`;
    const payload = {
      publicacion_id: publicationId,
      celular: celular,
      telefono: telefono,
      texto: message
    };
    __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);
    return this.http.post<IApiCommonResponse<any>>(endpointURL, payload, {
      observe: 'response',
      headers: this.authService.getAuthorizationHeaders()
    })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<any>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response. Get User data.
        map((response: HttpResponse<IApiCommonResponse<any>>) => response.ok),
        // Error handler.
        catchError(error => of(this.authService.checkSessionError(error)))
      );
  }

  /**
   * Send Message for Solicitud.
   */
     public sendMessageForSolicitud(pedidoPublicationId: number, message: string): Observable<boolean | BaseAppError> {
      const __logger = _LOGGER.getDerivedContext({
        methodName: 'sendMessage'
      });
      __logger.info('Method start.');

      const endpointURL = `${this.baseURLConsultas}/solicitud`;
      const payload = {
        pedido_publicacion_id: pedidoPublicationId,
        texto: message
      };
      __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);
      return this.http.post<IApiCommonResponse<any>>(endpointURL, payload, {
        observe: 'response',
        headers: this.authService.getAuthorizationHeaders()
      })
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<any>>) => {
            __logger.debug('API response:', response);
          }),
          // Parse response. Get User data.
          map((response: HttpResponse<IApiCommonResponse<any>>) => response.ok),
          // Error handler.
          catchError(error => of(this.authService.checkSessionError(error)))
        );
    }

  /**
   * Share a publication via Facebook
   */
  public doShareViaFacebook() {
    return `${_CONFIG.apiBaseURL}/toyotausados/showSocial/`;
  }

  public domainExists(domain: string): Observable<boolean | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'domainExists'
    });
    __logger.info('Method start.');

    const endpointURL = `${_CONFIG.apiBaseURL}/toyotausados/dominio/exists`;

    if (domain.length === 6) {
      domain = domain.substring(0, 3) + '-' + domain.substring(3);
    } else if (domain.length === 7) {
      domain = domain.substring(0, 2) + '-' + domain.substring(2, 5) + '-' + domain.substring(5);
    }

    const payload = { domain };

    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`, 'Payload:', payload);
    return this.http.get<any>(endpointURL, {
      observe: 'response',
      params: payload
    })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<any>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response. Get User data.
        map((response: HttpResponse<any>) => response.body.data.exists! as boolean),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }
}
