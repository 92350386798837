import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reserve-item',
  templateUrl: './reserve-item.component.html',
  styleUrls: ['./reserve-item.component.scss']
})
export class ReserveItemComponent implements OnInit {
  modalRemove = false;
  modalInfo = false;
  modalMensaje = false;
  constructor() { }

  ngOnInit() {
  }
  openRemove(){
    this.modalRemove = true;
  }
  closeRemove(){
    this.modalRemove = false;
  }
  openInfo(){
    this.modalInfo = true;
  }
  closeInfo(){
    this.modalInfo = false;
  }
  openMensaje(){
    this.modalMensaje = true;
  }
  closeMensaje(){
    this.modalMensaje = false;
  }
}
