import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from "../../shared/simple-logger.shared";
import { EnvironmentManager } from "../../shared/environment-manager.shared";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { ErrorsService } from "../errors/errors.service";
import { BaseAppError } from "../errors/base-app-error";
import { Observable, of } from "rxjs";
import { IApiCommonResponse } from "../i-api-common-response";
import { ICotizadorGetTcfautosCarInfoResponse } from "./i-cotizador-get-tcfautos-car-info-response";
import { catchError, map, tap } from "rxjs/operators";
import { TcfautosCarInfoModel } from "../../models/tcfautos-car-info.model";


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'cotizador.service.ts',
  className: 'CotizadorService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


/**
 * Cotizador Service.
 */
@Injectable({
  providedIn: 'root'
})
export class CotizadorService {
  private baseURL = `${_CONFIG.apiBaseURL}/cotizador`;

  constructor(
    private http: HttpClient,
    private errorsService: ErrorsService
  ) {
  }

  /**
   * Get TCFAutos Car's info.
   */
  public getTcfautosCarInfo(cuitDealer: string, operationCode: string, personCode: string, vehicleId: number, vehicleIsNew: boolean,
                            vehicleUsedPrice: number): Observable<TcfautosCarInfoModel | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getTcfautosCarInfo'
    });
    __logger.info('Method start.');

    const endpointURL = `${this.baseURL}/auto_tcfautos`;
    const payload = {
      p_cuit_dealer_c: cuitDealer,
      p_tipo_operacion_c: operationCode,
      p_tipo_persona_c: personCode,
      p_veh_id_i: vehicleId,
      p_veh_nuevo_i: (vehicleIsNew ? 1 : 0),
      p_veh_precio_usado_d: vehicleUsedPrice
    };
    __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, '; Payload:', payload);

    return this.http.post<IApiCommonResponse<ICotizadorGetTcfautosCarInfoResponse>>(endpointURL, payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<ICotizadorGetTcfautosCarInfoResponse>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IApiCommonResponse<ICotizadorGetTcfautosCarInfoResponse>>) => new TcfautosCarInfoModel(
          response.body.data.financiacion_sugerida, response.body.data.max_financiacion, parseInt(response.body.data.min_financiacion, 10)
        )),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }
}
