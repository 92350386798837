import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageHeaderComponent} from './components/page-header/page-header.component';
import {PageHeaderLegacyComponent} from './components/page-header-legacy/page-header-legacy.component';
import {PageFooterComponent} from './components/page-footer/page-footer.component';
import {VehicleCardComponent} from './components/vehicle-card/vehicle-card.component';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {MainModule} from '../main/main.module';
import {PublicRoutingModule} from './public-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {LoginPageComponent} from './pages/login-page/login-page.component';
import {RegisterPageComponent} from './pages/register-page/register-page.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Ng5SliderModule} from 'ng5-slider';
import {ResultsPageComponent} from './pages/results-page/results-page.component';
import {NguiAutoCompleteModule} from '@ngui/auto-complete';
import {VehicleListComponent} from './components/vehicle-list/vehicle-list.component';
import {PublicationsComponent} from './pages/publications/publications.component';
import {ErrorModalComponent} from '../main/components/error-modal/error-modal.component';
import {AlertModalComponent} from '../main/components/alert-modal/alert-modal.component';
import {LoadingModalComponent} from '../main/components/loading-modal/loading-modal.component';
import {SuccessModalComponent} from '../main/components/success-modal/success-modal.component';
import {ProfilePageComponent} from './pages/profile-page/profile-page.component';
import {MessagesPageComponent} from './pages/messages-page/messages-page.component';
import {ListMessagesPageComponent} from './pages/list-messages-page/list-messages-page.component';
import {MessageComponent} from './components/message/message.component';
import {SideNavComponent} from './components/side-nav/side-nav.component';
import {MobNavComponent} from './components/mob-nav/mob-nav.component';
import {MyReservesComponent} from './pages/my-reserves-page/my-reserves.component';
import {FavoritesPageComponent} from './pages/favorites-page/favorites-page.component';
import {FavoriteComponent} from './components/favorite/favorite.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ReserveItemComponent} from './components/reserve-item/reserve-item.component';
import {ShareModalComponent} from './components/share/share.component';
import {ShareFriendModalComponent} from './components/share-friend/share-friend.component';
import {FacebookComponent} from './components/facebook/facebook.component';
import {PhoneVerifyModalComponent} from '../main/components/phone-verify-modal/phone-verify-modal.component';
import {sanitizeHtmlPipe} from './pages/messages-page/sanitize-html.pipe';
import {YTPlayerModule} from 'angular-youtube-player';
import {SellPageComponent} from './pages/sell-page/sell-page.component';
import {SellWizardPageComponent} from './pages/sell-wizard-page/sell-wizard-page.component';
import {SellStepOneComponent} from './components/sell-wizard-steps/step-one/sell-step-one.component';
import {SellStepFourComponent} from './components/sell-wizard-steps/step-four/sell-step-four.component';
import {SellStepThreeComponent} from './components/sell-wizard-steps/step-three/sell-step-three.component';
import {SellStepTwoComponent} from './components/sell-wizard-steps/step-two/sell-step-two.component';
import { SendSolicitudModalComponent } from './components/send-solicitud-modal/send-solicitud-modal.component';
import { TerminosCondicionesModalComponent } from './components/terminos-condiciones-modal/terminos-condiciones-modal.component';
import { PreguntasFrecuentesComponent } from './components/preguntas-frecuentes/preguntas-frecuentes.component';


@NgModule({
  declarations: [
    PageHeaderComponent,
    PageHeaderLegacyComponent,
    PageFooterComponent,
    VehicleCardComponent,
    HomePageComponent,
    LoginPageComponent,
    RegisterPageComponent,
    ResultsPageComponent,
    VehicleListComponent,
    PublicationsComponent,
    ProfilePageComponent,
    MessagesPageComponent,
    ListMessagesPageComponent,
    MessageComponent,
    SideNavComponent,
    MobNavComponent,
    MyReservesComponent,
    FavoritesPageComponent,
    FavoriteComponent,
    ReserveItemComponent,
    ShareModalComponent,
    ShareFriendModalComponent,
    FacebookComponent,
    PhoneVerifyModalComponent,
    sanitizeHtmlPipe,
    SellPageComponent,
    SellWizardPageComponent,
    SellStepOneComponent,
    SellStepTwoComponent,
    SellStepThreeComponent,
    SellStepFourComponent,
    SendSolicitudModalComponent,
    TerminosCondicionesModalComponent,
    PreguntasFrecuentesComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    YTPlayerModule,
    SwiperModule,
    MainModule,
    PublicRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    NguiAutoCompleteModule,
    InfiniteScrollModule,
    YTPlayerModule
  ],
  entryComponents: [
    AlertModalComponent,
    ErrorModalComponent,
    LoadingModalComponent,
    SuccessModalComponent,
    ShareModalComponent,
    ShareFriendModalComponent,
    PhoneVerifyModalComponent,
    SendSolicitudModalComponent,
    TerminosCondicionesModalComponent
  ]
})
export class PublicModule {
}
