import { VehicleModelModel } from './vehicle-model.model';
import { VehicleBrandModel } from './vehicle-brand.model';
import { VehicleVersionModel } from './vehicle-version.model';
import { ProvinceModel } from './province.model';
import { DealerModel } from "./dealer.model";


export class PublicationFiltersModel {
  public usedFlag: boolean;
  public certifiedFlag: boolean;
  public yearFrom: number;
  public yearTo: number;
  public mileageFrom: number;
  public mileageTo: number;
  public vehicleBrand: VehicleBrandModel;
  public vehicleModel: VehicleModelModel;
  public vehicleVersion: VehicleVersionModel;
  public province: ProvinceModel;
  public priceFrom: number;
  public priceTo: number;
  public installmentsPriceFrom: number;
  public installmentsPriceTo: number;
  public title: string;
  public dealer: DealerModel;

  constructor(usedFlag: boolean = false, certifiedFlag: boolean = false, yearFrom: number = null, yearTo: number = null,
              mileageFrom: number = null, mileageTo: number = null, priceFrom: number = null, priceTo: number = null,
              vehicleBrand: VehicleBrandModel = null,
              vehicleModel: VehicleModelModel = null, vehicleVersion: VehicleVersionModel = null, province: ProvinceModel = null,
              installmentsPriceFrom: number = null, installmentsPriceTo: number = null, title = null, dealer: DealerModel = null) {
    this.usedFlag = usedFlag;
    this.certifiedFlag = certifiedFlag;
    this.yearFrom = yearFrom;
    this.yearTo = yearTo;
    this.mileageFrom = mileageFrom;
    this.mileageTo = mileageTo;
    this.vehicleBrand = vehicleBrand;
    this.vehicleModel = vehicleModel;
    this.vehicleVersion = vehicleVersion;
    this.province = province;
    this.priceFrom = priceFrom;
    this.priceTo = priceTo;
    this.installmentsPriceFrom = installmentsPriceFrom;
    this.installmentsPriceTo = installmentsPriceTo;
    this.title = title;
    this.dealer = dealer;
  }
}
