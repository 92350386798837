interface IUserProfileOptions {
  id: number,
  name: string,
  surname: string,
  email: string,
  origin: string | null,
  location: string | null,
  cellphone: string,
  cellphonePrefix: string,
  cellphoneValidated: boolean
}

export class UserProfileModel {
  public id: number;
  public name: string;
  public surname: string;
  public email: string;
  public origin: string | null;
  public location: string | null;
  public cellphone: string;
  public cellphoneValidated: boolean;

  constructor(options: IUserProfileOptions) {
    this.id = options.id;
    this.name = options.name;
    this.surname = options.surname;
    this.email = options.email;
    this.origin = options.origin;
    this.location = options.location;
    this.cellphone = this.parseCellphone(options.cellphonePrefix, options.cellphone);
    this.cellphoneValidated = options.cellphoneValidated;
  }

  private parseCellphone(cellphonePrefix: string, cellphone: string): string {
    if (!cellphone) {
      return null;
    }

    let fullCellphone = '';

    if (cellphonePrefix) {
      if (cellphonePrefix.charAt(0) === '0') {
        fullCellphone = cellphonePrefix.substring(1);
      } else {
        fullCellphone = cellphonePrefix;
      }
    }

    fullCellphone += cellphone;

    return fullCellphone;
  }
}
