import { DealerBranchModel } from "src/app/models/dealer-branch.model";
import { DealerModel } from "src/app/models/dealer.model";

export class DealerBranchMerged {
  dealer: Pick<DealerModel, Exclude<keyof DealerModel, "branches">>;
  branch: DealerBranchModel;

  constructor(dealer: DealerModel, branch: DealerBranchModel) {
    delete dealer.branches;
    this.dealer = dealer;
    this.branch = branch;
  }
}

export interface ISellStepTwoData {
  dealer: DealerBranchMerged | null;
}
