import { VehicleModelModel } from './vehicle-model.model';
import { VehicleVersionModel } from './vehicle-version.model';
import { VehicleBrandModel } from './vehicle-brand.model';
import { PublicationAmenityCategoryModel } from "./publication-amenity-category.model";
import { PublicationDealerModel } from "./publication-dealer.model";
import { SafeResourceUrl } from "@angular/platform-browser";
import { PlanSubplanModel } from './plan-subplan.model';

export class PublicationDetailModel {
  public id: number;
  public startsAt: Date;
  public endsAt: Date;
  public vehicleIdFu: number;
  public images: string[];
  public video: string;
  public price: number;
  public priceCalculated: number;
  public year: number;
  public mileage: number;
  public title: string;
  public vehicleBrand: VehicleBrandModel;
  public vehicleModel: VehicleModelModel;
  public vehicleVersion: VehicleVersionModel;
  public description: string;
  public amenitiesByCategory: PublicationAmenityCategoryModel[];
  public dealer: PublicationDealerModel;
  public liked: boolean;
  public isNew: boolean;
  public isCertificated: boolean;
  public isReserved: boolean;
  public plans: PlanSubplanModel[];
  public inspectionDate?: Date;

  constructor(id: number, startsAt:Date, endsAt:Date,  vehicleIdFu: number, images: string[], video: string, price: number, priceCalculated: number, year: number, mileage: number, title: string,
              vehicleBrand: VehicleBrandModel, vehicleModel: VehicleModelModel,
              vehicleVersion: VehicleVersionModel, description: string,
              amenitiesByCategory: PublicationAmenityCategoryModel[] = [],
              dealer: PublicationDealerModel, isNew: boolean = false, isCertificated: boolean = false,
              isReserved = false, liked = false, plans: PlanSubplanModel[], inspectionDate?: Date) {
    this.id = id;
    this.startsAt = startsAt;
    this.endsAt = endsAt;
    this.vehicleIdFu = vehicleIdFu;
    this.images = images;
    this.video = video;
    this.price = price;
    this.priceCalculated = priceCalculated;
    this.year = year;
    this.mileage = mileage;
    this.title = title;
    this.vehicleBrand = vehicleBrand;
    this.vehicleModel = vehicleModel;
    this.vehicleVersion = vehicleVersion;
    this.description = description;
    this.amenitiesByCategory = amenitiesByCategory;
    this.dealer = dealer;
    this.liked = liked;
    this.isNew = isNew;
    this.isCertificated = isCertificated;
    this.isReserved = isReserved;
    this.plans = plans;
    this.inspectionDate = inspectionDate;
  }
}
