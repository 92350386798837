export class DealerBranchModel {
  public id: number;
  public isCentralBranch: boolean;
  public latitude: number;
  public longitude: number;
  public address: string;
  public name: string;
  public location: string;
  public phone: string;
  public email: string;

  constructor(id: number, isCentralBranch: boolean, latitude: number, longitude: number, address: string, name: string,
    location: string, phone: string, email: string) {
    this.id = id;
    this.isCentralBranch = isCentralBranch;
    this.latitude = latitude;
    this.longitude = longitude;
    this.address = address;
    this.name = name;
    this.location = location;
    this.phone = phone;
    this.email = email;
  }
}
