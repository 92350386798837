import { Component, OnInit, Query } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { ISellData } from 'src/app/services/sell/i-sell-data';
import { SellService } from 'src/app/services/sell/sell.service';
import { LoggerContext, SimpleLogger } from 'src/app/shared/simple-logger.shared';
import { SendSolicitudModalComponent } from '../../components/send-solicitud-modal/send-solicitud-modal.component';
import { AuthService } from '../../../../services/auth/auth.service';
import { PersistenceService } from '../../../../services/persistence/persistence.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseAppError } from '../../../../services/errors/base-app-error';
import { QueryModel } from 'src/app/models/query.model';
import { UserActionService } from '../../../../services/user-action/user-action.service';
import { UserActionModel } from '../../../../models/user-action-send.model';

const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'sell-wizard-page.component.ts',
  className: 'SellWizardPageComponent',
  tagName: 'PAGES'
});
_LOGGER.debugVerbose('Loaded.');

const TOTAL_STEPS = 4;

const STEPS_TITLES = ['Datos de tu Toyota', 'Selección del concesionario', 'Creá tu cuenta o inicia sesión', 'Solicitud de Inspección'];

@Component({
  selector: 'app-sell-wizard-page',
  templateUrl: './sell-wizard-page.component.html',
  styleUrls: ['./sell-wizard-page.component.scss']
})
export class SellWizardPageComponent implements OnInit {
  public step: number;
  public data: ISellData;
  public internalBackSubject: Subject<void>;
  private recoverRegisterData: boolean;

  constructor(
    private router: Router,
    private sellService: SellService,
    private modalsService: ModalsService,
    private authService: AuthService,
    private persistenceService: PersistenceService,
    private userActionService: UserActionService
  ) {
    this.onNextClick = this.onNextClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    const navigation = this.router.getCurrentNavigation();
    this.recoverRegisterData = !!navigation && !!navigation.extras && !!navigation.extras.state &&
      !!navigation.extras.state.recoverRegisterData;
  }

  public ngOnInit(): void {
    this.data = {
      year: null,
      model: null,
      version: null,
      kms: null,
      domain: null,
      province: null,
      locality: null,
      dealer: null,
      user: null
    };
    this.step = 1;

    if (this.recoverRegisterData) {
      const SELL_WIZZARD_DATA_KEY = 'sellWizzardDataAfterRegister';
      this.data = this.persistenceService.retrieve(SELL_WIZZARD_DATA_KEY, 24 * 60 * 60 * 1000) || this.data;
      this.persistenceService.store(SELL_WIZZARD_DATA_KEY, null);
      if (!!this.data.year && !!this.data.model && !!this.data.version) {
        this.step = 3;
      }
    }

    this.internalBackSubject = new Subject<void>();
  }

  public getTotalSteps() {
    return TOTAL_STEPS;
  }

  public getStepTitle() {
    return STEPS_TITLES[this.step - 1];
  }

  public onNextClick() {
    const logger = _LOGGER.getDerivedContext({methodName: 'onSignUpSubmit'});
    logger.info('Method start.');

    if (this.step === TOTAL_STEPS) {
      const confirmModal = this.modalsService.showCustomModal(SendSolicitudModalComponent, 'send-solicitud-modal', 'sm',
        true, 'modal-basic-title', false, 'static');
      confirmModal.componentInstance.data = this.data;
      let loadingModal: Promise<NgbModalRef>;
      confirmModal.result.then(resultConfirm => {
        logger.debug('Envío aceptado');
        loadingModal = this.modalsService.showLoadingModal('Enviando...');
        this.sellService.createPedido(this.data).subscribe((resultOrError: QueryModel | BaseAppError)  => {
  
          if (!resultOrError || (resultOrError instanceof BaseAppError) ) {
            this.modalsService.showErrorModal('ERROR');
          } else {
            this.modalsService.showSuccessModal(
              'El concesionario seleccionado se contactará con vos a la brevedad.',
              'La solicitud de inspección se envió con éxito.'
            ).finally(() => {
              this.router.navigate(['/home']);
            });
            let consulta_id = resultOrError.id;
          this.sendUserActionSellWizardPage('ped_inspección_ok', consulta_id);
          }
        }, error => {
          logger.debug('Suscription error', error);
          this.modalsService.showErrorModal('ERROR');
        }, () => {
          if (!!loadingModal) {
            loadingModal.then(modal => modal.dismiss('done'));
          }
        });
      }).catch(error => {
        logger.debug('Envío cancelado', error);
        if (!!loadingModal) {
          loadingModal.then(modal => modal.dismiss('done'));
        }
      });
    } else {
      this.step++;
    }
  }

  public onBackClick() {
    if (!!this.internalBackSubject) {
      this.internalBackSubject.next();
    }

    if (!this.internalBackSubject || !this.internalBackSubject.observers.length) {
      this.stepBack();
    }
  }

  public stepBack() {
    if (this.step === 1) {
      this.router.navigate(['/vender']);
    } else if ((this.step === 4) && this.authService.isUserLoggedIn()) {
      this.step = 2;
    } else {
      this.step--;
    }
  }

  private sendUserActionSellWizardPage(action, data) {
    const logger = _LOGGER.getDerivedContext({methodName: 'sendUserActionSellWizardPage'});
    logger.info('Method start.');
    this.userActionService.sendUserAction(action, data).subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
  }

  public onClickLogoToyota(): void{
    const logger = _LOGGER.getDerivedContext({methodName: 'onClickLogoToyota'});
    logger.info('Method start.');
    this.userActionService.sendUserAction('home').subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
    this.router.navigate(['/home'], {state: {recoverRegisterData: true}});
  }

}
