import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from "../../shared/simple-logger.shared";
import { EnvironmentManager } from "../../shared/environment-manager.shared";


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'helpers.service.ts',
  className: 'HelpersService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


/**
 * Helpers Service.
 */
@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  constructor() {
  }

  /**
   * Returns TRUE if the input E-mail is in a valid format (RFC2822).
   * Returns FALSE otherwise.
   */
  public isEmailValid(email: string): boolean {
    if (!email) {
      return false;
    }

    // RFC2822 Email Validation.
    const regExp = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/); // tslint:disable-line:max-line-length
    if (!regExp.test(email)) {
      return false;
    }
    const regMatch = email.match(regExp);
    if (!regMatch) {
      return false;
    }
    return (regMatch.length === 1) && (regMatch[0] === regMatch.input);
  }

  /**
   * Returns a normalized cellphone number.
   *
   * @param cellphoneNumber   The cellphone to normalize.
   */
  public normalizeCellphone(cellphoneNumber: string): string {
    if (!cellphoneNumber) {
      return null;
    }

    // Extract all numbers.
    const regExpNumbers = new RegExp(/[\D]/g);
    let normalized = cellphoneNumber.trim().replace(regExpNumbers, '');

    // Remove initial 0 (once).
    if (normalized.startsWith('0')) {
      normalized = normalized.substr(1);
    }

    return normalized;
  }

  /**
   * Returns TRUE if the input Cellphone is in a valid format (checks characters used, initial digits, and digits count).
   * Returns FALSE otherwise.
   */
  public isCellphoneValid(cellphoneNumber: string): boolean {
    if (!cellphoneNumber) {
      return false;
    }

    // Check invalid chars.
    const regExpInvalidChars = new RegExp(/[^\d \-()]/g);
    if (cellphoneNumber.match(regExpInvalidChars)) {
      return false;
    }

    // Get Normalized.
    let normalized = this.normalizeCellphone(cellphoneNumber);

    // Invalid second 0.
    if (normalized.startsWith('0')) {
      return false;
    }

    // Check digit count.
    return (normalized.length <= 12) && (normalized.length >= 8);
  }
}
