import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';
import { DealerModel } from 'src/app/models/dealer.model';
import { DealersService } from 'src/app/services/dealers/dealers.service';
import { BaseAppError } from 'src/app/services/errors/base-app-error';
import { GoogleMapService } from 'src/app/services/google-map/google-map.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { ISellData } from 'src/app/services/sell/i-sell-data';
import { DealerBranchMerged } from 'src/app/services/sell/i-sell-step-two-data';
import { LoggerContext, SimpleLogger } from '../../../../../shared/simple-logger.shared';
import { UserActionService } from '../../../../../services/user-action/user-action.service';
import { UserActionModel } from '../../../../../models/user-action-send.model';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'sell-step-two.component.ts',
  className: 'SellStepTwoComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');

/**
 * Page Header component to place top of most views.
 */
@Component({
  selector: 'app-sell-step-two',
  templateUrl: './sell-step-two.component.html',
  styleUrls: ['./sell-step-two.component.scss']
})
export class SellStepTwoComponent implements OnInit {
  public searchText: string;

  @Input() nextClick: () => void;
  @Input() data: ISellData;

  /* Form controls. */
  @ViewChild('map') protected mapElement: ElementRef;

  /* View data. */
  protected map: any;
  protected loadingMap: boolean = true;
  protected mapError: boolean = false;
  protected googleMapSdk: any;
  protected selectedDealer: DealerBranchMerged;
  protected loadingModal: Promise<NgbModalRef>;

  private openInfoWindow: any;

  constructor(
    private dealersService: DealersService,
    private googleMapService: GoogleMapService,
    private modalsService: ModalsService,
    private userActionService: UserActionService
  ) {
  }

  public ngOnInit(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'ngOnInit'
    });
    __logger.info('Method start.');

    this.loadDealersMap();
  }


  public onClickSeleccionar(): void{
    const logger = _LOGGER.getDerivedContext({methodName: 'onClickSeleccionar'});
    logger.info('Method start.');
    
    this.userActionService.sendUserAction('registrarse_venta').subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });

    this.userActionService.sendUserAction('seleccionar_concesionario', this.data.dealer.dealer.id).subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
    this.nextClick();
  }

  /**
   * User selects a search result.
  */
  public onSelectSearchDealer(result: DealerBranchMerged): void {
    if (result && result instanceof DealerBranchMerged &&
      (!this.data.dealer || this.data.dealer.branch.id !== result.branch.id)) {
      this.selectDealer(result);
    }
  }

  /**
   * Transforms a Dealer into a search result.
   */
  public onDisplaySearchDealer(data: DealerBranchMerged): string {
    return `${data.dealer.name} - ${data.branch.address}, ${data.branch.location}`;
  }

  /**
   * Searches Dealers by user's input.
   */
  public onKeyPressSearchDealers(keyword: string): Observable<DealerBranchMerged[]> {
    this.data.dealer = null;
    return from(this.getDealers(keyword));
  }

  public centerMapOnUser(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'centerMapOnUser'
    });
    __logger.info('Method start.');

    this.data.dealer = null;

    if (this.map) {
      this.googleMapService.getUserPosition()
        .then((userPosition: any) => {
          __logger.debug('Got User position:', userPosition);

          const __userLatitude = userPosition.coords.latitude;
          const __userLongitude = userPosition.coords.longitude;

          /*__addMarkerToDealerMap(__userLatitude, __userLongitude, 'Ud. se encuentra aquí.');*/
          this.map.panTo(new this.googleMapSdk.LatLng(__userLatitude, __userLongitude));
          this.map.setZoom(11);
        });
    }
  }

  /**
  * Get Dealers Map array.
  */
  protected getDealers(keyword?: string): Promise<DealerBranchMerged[]> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getDealers'
    });
    __logger.info('Method start.');

    return new Promise<DealerBranchMerged[]>((resolve) => {
      const __onGetDealersFail = () => {
        resolve([]);
      };

      const __onGetDealersSuccess = (dealers: DealerBranchMerged[]) => {
        resolve(dealers);
      };

      this.dealersService.getByNameOrAddress(keyword)
        .subscribe(
          // Success response.
          (resultOrError: DealerModel[] | BaseAppError) => {
            if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.length) {
              __logger.error('Cannot resolve Dealers array.', resultOrError);
              __onGetDealersFail();
            } else {
              __logger.debug('Dealers initialized.');
              const dealers = resultOrError.reduce(
                (p, c) => p.concat(c.branches.map(b => new DealerBranchMerged(c, b))),
                new Array<DealerBranchMerged>());
              __onGetDealersSuccess(dealers);
            }
          },
          // Error response.
          (error: any) => {
            __logger.error('Error on get Dealers Map.', error);
            __onGetDealersFail();
          });
    });
  }

  /**
   * Loads Dealer Map.
   */
  protected loadDealersMap(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'loadDealersMap'
    });
    __logger.info('Method start.');

    this.loadingMap = true;
    this.mapError = false;

    this.openLoadingModal();

    // Get Dealers.
    const __dealersPromise: Promise<DealerBranchMerged[]> = this.getDealers();

    // Get Google Map SDK.
    this.googleMapService
      .getGoogleMapSdk()
      .then((googleMapSdk) => {
        this.googleMapSdk = googleMapSdk;


        this.map = new googleMapSdk.Map(this.mapElement.nativeElement, {
          center: new googleMapSdk.LatLng(-39.4520765, -63.9034918), // Centered on Argentina by default.
          zoom: 4,
          mapTypeId: googleMapSdk.MapTypeId.ROADMAP
        });

        return __dealersPromise
          .then((dealers: DealerBranchMerged[]) => {
            dealers
              .forEach((dealer: DealerBranchMerged) => {
                const title = `${dealer.dealer.name} - ${dealer.branch.name}`;
                __logger.debugVerbose(`Adding Marker position (${dealer.branch.latitude}, ${dealer.branch.longitude}). Title: ${title}; Address:`, dealer.branch.address);

                const svgMarker = {
                  path: "M 12 22 z M 12 2.016 q 2.906 0 4.945 2.039 t 2.039 4.945 q 0 1.453 -0.727 3.328 t -1.758 3.516 t -2.039 3.07 t -1.711 2.273 l -0.75 0.797 q -0.281 -0.328 -0.75 -0.867 t -1.688 -2.156 t -2.133 -3.141 t -1.664 -3.445 t -0.75 -3.375 q 0 -2.906 2.039 -4.945 t 4.945 -2.039 z",
                  fillColor: "red",
                  fillOpacity: 0.8,
                  strokeWeight: 0,
                  rotation: 0,
                  scale: 2,
                  anchor: new googleMapSdk.Point(12, 20)
                };

                let __markerOptions: any = {
                  map: this.map,
                  animation: this.googleMapSdk.Animation.DROP,
                  position: new this.googleMapSdk.LatLng(dealer.branch.latitude, dealer.branch.longitude)
                };
                if (dealer.branch.address) {
                  __markerOptions.icon = svgMarker;
                }
                let __marker = new this.googleMapSdk.Marker(__markerOptions);

                this.googleMapSdk.event.addListener(__marker, 'click', () => {
                  this.selectDealer(dealer);
                });
              });
              this.loadingMap = false;
              this.closeLoadingModal();
          });
      })
      .catch(() => {
        this.loadingMap = false;
        this.mapError = true;
        this.closeLoadingModal();
      });
  }

  protected selectDealer(dealer: DealerBranchMerged): void {
    this.data.dealer = dealer;

    if (this.map) {
      this.map.panTo(new this.googleMapSdk.LatLng(dealer.branch.latitude, dealer.branch.longitude));
      this.map.setZoom(13);
    }
  }

  private openLoadingModal(): void {
    if (!this.loadingModal) {
      this.loadingModal = this.modalsService.showLoadingModal('Cargando...');
    }
  }

  private closeLoadingModal(): void {
    if (this.loadingModal) {
      this.loadingModal.then(modal => {
        modal.dismiss('done');
        this.loadingModal = null;
      });
    }
  }

}
