export class ProvinceModel {
  public id: number;
  public name: string;
  public fuId?: string;

  constructor(id: number, name: string, fuId?: string) {
    this.id = id;
    this.name = name;
    this.fuId = fuId;
  }
}
