/**
 * Available Log Levels.
 */
export enum LOGLEVEL {
  NONE = 0,
  ERROR = 10,
  INFO = 20,
  DEBUG = 30,
  VERBOSE = 40
}


/**
 * Logger Context Options.
 */
export interface ILoggerContextOptions {
  fileName?: string;
  className?: string;
  methodName?: string;
  tagName?: string;
}

abstract class LoggerProvider {
  public abstract error(message?: any, ...params: any[]): void;

  public abstract errorVerbose(message?: any, ...params: any[]): void;

  public abstract info(message?: any, ...params: any[]): void;

  public abstract infoVerbose(message?: any, ...params: any[]): void;

  public abstract debug(message?: any, ...params: any[]): void;

  public abstract debugVerbose(message?: any, ...params: any[]): void;
}


/**
 * Logger Context.
 */
export abstract class LoggerContext {
  private readonly fileName: string;
  private readonly className: string;
  private readonly methodName: string;
  private readonly tagName: string;
  private readonly loggerProvider: LoggerProvider;

  protected constructor(loggerProvider: LoggerProvider, options: ILoggerContextOptions) {
    this.loggerProvider = loggerProvider;

    if (options.fileName) {
      this.fileName = options.fileName.toLowerCase();
    }
    if (options.className) {
      this.className = options.className;
    }
    if (options.methodName) {
      this.methodName = options.methodName;
    }
    if (options.tagName) {
      this.tagName = options.tagName.toUpperCase();
    }
  }

  public getDerivedContext(options: ILoggerContextOptions): LoggerContext {
    return new InnerLoggerContext(this.loggerProvider, {
      fileName: options.fileName || this.fileName,
      className: options.className || this.className,
      methodName: options.methodName || this.methodName,
      tagName: options.tagName || this.tagName
    });
  }

  private getMessagePrefix(): string {
    return `${this.tagName || 'DEFAULT'} #${this.className || 'Default'}${this.methodName ? '.' + this.methodName : ''}:`;
  }

  public error(message?: any, ...params: any[]): void {
    this.loggerProvider.error(`${this.getMessagePrefix()} ${message}`, ...params);
  }

  public errorVerbose(message?: any, ...params: any[]): void {
    this.loggerProvider.errorVerbose(`${this.getMessagePrefix()} ${message}`, ...params);
  }

  public info(message?: any, ...params: any[]): void {
    this.loggerProvider.info(`${this.getMessagePrefix()} ${message}`, ...params);
  }

  public infoVerbose(message?: any, ...params: any[]): void {
    this.loggerProvider.infoVerbose(`${this.getMessagePrefix()} ${message}`, ...params);
  }

  public debug(message?: any, ...params: any[]): void {
    this.loggerProvider.debug(`${this.getMessagePrefix()} ${message}`, ...params);
  }

  public debugVerbose(message?: any, ...params: any[]): void {
    this.loggerProvider.debugVerbose(`${this.getMessagePrefix()} ${message}`, ...params);
  }
}


/**
 * Private Logger Context.
 */
class InnerLoggerContext extends LoggerContext {
  public constructor(logger: LoggerProvider, options: ILoggerContextOptions) {
    super(logger, options);
  }
}


/**
 * <Singleton>
 *     Simple Logger. Used to encapsulate all logging logic.
 */
export class SimpleLogger extends LoggerProvider {
  private static instance: SimpleLogger = new SimpleLogger();

  private logLevel: LOGLEVEL = LOGLEVEL.DEBUG;
  private readonly defaultLoggerContext: LoggerContext;

  private constructor() {
    super();
    this.defaultLoggerContext = new InnerLoggerContext(this, {
      fileName: 'simple-logger.shared.ts',
      className: 'SimpleLogger',
      methodName: '',
      tagName: 'GLOBAL'
    });
  }

  // Class members.

  public static getInstance(): SimpleLogger {
    if (!SimpleLogger.instance) {
      SimpleLogger.instance = new SimpleLogger();
    }
    return SimpleLogger.instance;
  }


  // Instance members.

  public setLogLevel(logLevel: LOGLEVEL): void {
    this.logLevel = logLevel;
  }

  public getContext(options?: ILoggerContextOptions): LoggerContext {
    if (!options) {
      return this.defaultLoggerContext;
    }
    return new InnerLoggerContext(this, options);
  }

  public error(message?: any, ...params: any[]): void {
    if (this.logLevel >= LOGLEVEL.ERROR) {
      window.console.error(message, ...params);
    }
  }

  public errorVerbose(message?: any, ...params: any[]): void {
    if (this.logLevel >= LOGLEVEL.VERBOSE) {
      this.error(message, ...params);
    }
  }

  public debug(message?: any, ...params: any[]): void {
    if (this.logLevel >= LOGLEVEL.DEBUG) {
      window.console.debug(message, ...params);
    }
  }

  public debugVerbose(message?: any, ...params: any[]): void {
    if (this.logLevel >= LOGLEVEL.VERBOSE) {
      this.debug(message, ...params);
    }
  }

  public info(message?: any, ...params: any[]): void {
    if (this.logLevel >= LOGLEVEL.INFO) {
      window.console.info(message, ...params);
    }
  }

  public infoVerbose(message?: any, ...params: any[]): void {
    if (this.logLevel >= LOGLEVEL.VERBOSE) {
      this.info(message, ...params);
    }
  }
}


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext();
_LOGGER.debugVerbose('Loaded.');
