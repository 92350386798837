import {Injectable} from '@angular/core';
import {LoggerContext, SimpleLogger} from '../../shared/simple-logger.shared';
import {EnvironmentManager} from '../../shared/environment-manager.shared';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {ErrorsService} from '../errors/errors.service';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {BaseAppError} from '../errors/base-app-error';
import {IApiCommonResponse} from '../i-api-common-response';
import {IAuthDoLoginResponse} from './i-auth-do-login-response';
import {UserModel} from '../../models/user.model';
import { SessionService } from '../session/session.service';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'auth.service.ts',
  className: 'AuthService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


/**
 * Auth. Service.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseURL = `${_CONFIG.apiBaseURL}/toyotausados/auth`;

  private userLoggedIn: boolean;
  private userToken: string;
  private userData: UserModel;

  constructor(
    private http: HttpClient,
    private errorsService: ErrorsService,
    private sessionService: SessionService
  ) {
    this.resetUserData();
  }

  /**
   * Resets LoggedIn User stored data.
   */
  private resetUserData(): void {
    this.userLoggedIn = false;
    this.userToken = null;
    this.userData = null;
  }

  /**
   * Expires User session data.
   */
  public expireSession(): void {
    this.resetUserData();
  }

  public checkSessionError(error: any): BaseAppError {
    const appError = this.errorsService.getAppError(error);
    if (appError.getCode() === ErrorsService.getApiHttpErrorCode(401)) {
      this.expireSession();
    }
    return appError;
  }

  /**
   * Returns TRUE if the user has logged in.
   */
  public isUserLoggedIn(): boolean {
    return this.userLoggedIn;
  }

  public getUserData(): UserModel {
    if (this.isUserLoggedIn()) {
      return this.userData;
    }
    return null;
  }

  public setUserDataImageUrl(imageUrl: string): void {
    if (!this.isUserLoggedIn()) {
      return;
    }

    this.userData.imageUrl = imageUrl;
  }

  /**
   * Returns the logged in user's token.
   */
  private getUserToken(): string {
    if (this.isUserLoggedIn() && !!this.userToken) {
      return this.userToken;
    }
    return null;
  }

  public getAuthorizationHeaders(addTcfautosSessionHeader: boolean = false): { [header: string]: string } {
    const userToken = this.getUserToken();
    const headers: { [header: string]: string } = {};
    if (userToken) {
      headers.Authorization = `Bearer ${userToken}`;
    }
    if (addTcfautosSessionHeader) {
      headers['X-Session-Token'] = this.sessionService.getSessionData('session_token');
    }
    return headers;
  }

  /**
   * User LogIn operation.
   */
  public doLogin(username: string, password: string): Observable<UserModel | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({methodName: 'doLogin'});
    __logger.info('Method start.');

    if (this.isUserLoggedIn()) {
      return of(this.userData);
    }

    const endpointURL = `${this.baseURL}/login`;
    const payload = {
      usuario: username,
      password
    };
    __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);

    return this.http.post<IApiCommonResponse<IAuthDoLoginResponse>>(endpointURL, payload, {observe: 'response'}).pipe(
      // Log operation.
      tap((response: HttpResponse<IApiCommonResponse<IAuthDoLoginResponse>>) => {
        __logger.debug('API response:', response);
      }),
      // Parse response. Get User data.
      map((response: HttpResponse<IApiCommonResponse<IAuthDoLoginResponse>>) => this.mapLoginResponse(response)),
      // Error handler.
      catchError(error => of(this.errorsService.getAppError(error)))
    );
  }

  /**
   * User Social Login operation.
   */
  public doSocialLogin(network: string, accessToken: string): Observable<UserModel | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({methodName: 'doSocialLogin'});
    __logger.info('Method start.');

    if (this.isUserLoggedIn()) {
      return of(this.userData);
    }

    const endpointURL = `${this.baseURL}/login/social`;
    const payload = {
      network,
      access_token: accessToken,
      redirectURI: `${window.location.origin}/auth/linkedin/callback/`
    };
    __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);

    return this.http.post<IApiCommonResponse<IAuthDoLoginResponse>>(endpointURL, payload, {observe: 'response'}).pipe(
      // Log operation.
      tap((response: HttpResponse<IApiCommonResponse<IAuthDoLoginResponse>>) => {
        __logger.debug('API response:', response);
      }),
      // Parse response. Get User data.
      map((response: HttpResponse<IApiCommonResponse<IAuthDoLoginResponse>>) => this.mapLoginResponse(response)),
      // Error handler.
      catchError(error => of(this.errorsService.getAppError(error)))
    );
  }

  private mapLoginResponse(response: HttpResponse<IApiCommonResponse<IAuthDoLoginResponse>>): UserModel {
    if (response.ok && response.body.data.token && response.body.data.user) {
      this.userLoggedIn = true;
      this.userToken = response.body.data.token;
      const uData = response.body.data.user;
      this.userData = new UserModel(uData.id_usuario, uData.username, uData.nombre, uData.apellido,
        parseInt(uData.idCliente, 10), uData.native, uData.tasa, uData.url, uData.change_password,
        uData.change_password_token, (uData.profile_image && uData.profile_image.url) ?
          `${_CONFIG.profilesImagesContextUrl}/${uData.profile_image.url}` : null);
      return this.userData;
    }
    throw new Error('No se pudo recuperar la información del usuario.');
  }

  /**
   * User LogOut operation.
   */
  public doLogOut(): void {
    const __logger = _LOGGER.getDerivedContext({methodName: 'doLogOut'});
    __logger.info('Method start.');

    if (this.isUserLoggedIn()) {
      const endpointURL = `${this.baseURL}/logout`;
      const payload = {};
      __logger.debug('Calling API.', 'Endpoint:', `POST ${endpointURL}`, 'Payload:', payload);

      this.http.post<any>(endpointURL, payload, {observe: 'response'}).pipe(
        // Log operation.
        tap((response: HttpResponse<any>) => {
          __logger.debug('API response:', response);
        }, (error: any) => {
          __logger.error('Error API response:', error);
        }),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );

      this.resetUserData();
    }
  }
}
