import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { Observable, of } from 'rxjs';
import { PersistenceService } from '../persistence/persistence.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { IDealersGetMapResponseDealerItem } from './i-dealers-get-map-response-dealer-item';
import { DealerModel } from '../../models/dealer.model';
import { BaseAppError } from '../errors/base-app-error';
import { ErrorsService } from '../errors/errors.service';
import { IApiCommonResponse } from '../i-api-common-response';
import { DealerBranchModel } from '../../models/dealer-branch.model';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'dealers.service.ts',
  className: 'DealersService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


const _CACHE_CONFIG = {
  GET_DEALERS: {
    KEY: 'get_dealers',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  }
};


/**
 * Dealers Service.
 */
@Injectable({
  providedIn: 'root'
})
export class DealersService {
  private baseURL = `${_CONFIG.apiBaseURL}/toyotausados/concesionario`;

  /** Cache data. */
  private cacheGetDealers: Observable<DealerModel[] | BaseAppError>;

  constructor(
    private http: HttpClient,
    private persistenceService: PersistenceService,
    private errorsService: ErrorsService
  ) {
  }

  /**
   * Gets all Dealers geo-localized.
   */
  public getMap(): Observable<DealerModel[] | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getMap'
    });
    __logger.info('Method start.');

    // Check cache.
    if (this.persistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_DEALERS.KEY, _CACHE_CONFIG.GET_DEALERS.AGE)) {
      const endpointURL = `${this.baseURL}/map`;
      __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

      this.cacheGetDealers = this.http.get<IApiCommonResponse<IDealersGetMapResponseDealerItem[]>>(endpointURL, { observe: 'response' })
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<IDealersGetMapResponseDealerItem[]>>) => {
            __logger.debug('API response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<IApiCommonResponse<IDealersGetMapResponseDealerItem[]>>) => response.body.data.map(
            dealer => new DealerModel(dealer.id, dealer.razon_social, dealer.sucursales.map(
              dealerBranch => new DealerBranchModel(dealerBranch.id, dealerBranch.casa_central, parseFloat(dealerBranch.lat), parseFloat(dealerBranch.lon),
                dealerBranch.direccion, dealerBranch.descripcion, dealerBranch.localidad,
                !!dealerBranch.telefono ? dealerBranch.telefono : '-', '-')
            ), parseInt(dealer.cant_publicaciones, 10), dealer.logo
            ))
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              } else if (a.name > b.name) {
                return 1;
              }
              return 0;
            })),
          // Store in cache.
          tap((data: DealerModel[]) => {
            this.persistenceService.store(_CACHE_CONFIG.GET_DEALERS.KEY, data);
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetDealers) {
        const cacheData: DealerModel[] = this.persistenceService.retrieve(_CACHE_CONFIG.GET_DEALERS.KEY, _CACHE_CONFIG.GET_DEALERS.AGE);
        this.cacheGetDealers = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: DealerModel[]) => {
              __logger.debug('Cache response:', data);
            }),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorsService.getAppError(error)))
          );
      }
    }

    return this.cacheGetDealers;
  }

  /**
 * Gets all Dealers geo-localized.
 */
  public getByNameOrAddress(searchString: string = ''): Observable<DealerModel[] | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getByNameOrAddress'
    });
    __logger.info('Method start.');

    const endpointURL = `${this.baseURL}/search`;
    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

    return this.http.get<IApiCommonResponse<IDealersGetMapResponseDealerItem[]>>(endpointURL, { params: { searchString }, observe: 'response' })
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IApiCommonResponse<IDealersGetMapResponseDealerItem[]>>) => {
          __logger.debug('API response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IApiCommonResponse<IDealersGetMapResponseDealerItem[]>>) => response.body.data.map(
          dealer => new DealerModel(dealer.id, dealer.razon_social, dealer.sucursales.map(
            dealerBranch => new DealerBranchModel(dealerBranch.id, dealerBranch.casa_central, parseFloat(dealerBranch.lat), parseFloat(dealerBranch.lon),
              dealerBranch.direccion, dealerBranch.descripcion, dealerBranch.localidad,
              !!dealerBranch.telefono ? dealerBranch.telefono : '-', '-')
          ), parseInt(dealer.cant_publicaciones, 10), dealer.logo
          ))
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            } else if (a.name > b.name) {
              return 1;
            }
            return 0;
          })),
        // Replay last response.
        publishReplay(1),
        refCount(),
        // Error handler.
        catchError(error => of(this.errorsService.getAppError(error)))
      );
  }
}
