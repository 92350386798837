export class PublicationDealerExtraInfoModel {
  public address: string;
  public addressZone: string;
  public phone: string;
  public email: string;

  constructor(address: string, addressZone: string, phone: string, email: string) {
    this.address = address;
    this.addressZone = addressZone;
    this.phone = phone;
    this.email = email;
  }
}
