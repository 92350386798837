import { DealerBranchModel } from './dealer-branch.model';


export class DealerModel {
  public id: number;
  public name: string;
  public branches: DealerBranchModel[];
  public publicationsCount: number;
  public logo?: string;

  constructor(id: number, name: string, branches: DealerBranchModel[], publicationsCount: number, logo?: string) {
    this.id = id;
    this.name = name;
    this.branches = branches;
    this.publicationsCount = publicationsCount;
    this.logo = logo;
  }
}
