import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'persistence.service.ts',
  className: 'PersistenceService',
  methodName: '',
  tagName: 'GLOBAL'
});
_LOGGER.debugVerbose('Loaded.');


const _TIMESTAMP_KEY_ACCESSOR = '__$timestamp';
const _SUFFIX_KEY_ACCESSOR = '__$suffix';


@Injectable({
  providedIn: 'root'
})
export class PersistenceService {
  constructor() {
  }

  public store(key: string, data: any, suffix: string = ''): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'store'
    });
    __logger.info('Method start.');
    __logger.infoVerbose('-- Input Data:', 'Key:', key, '; Data:', data, '; Suffix:', suffix);

    try {
      localStorage.setItem(key + _SUFFIX_KEY_ACCESSOR, suffix);
      localStorage.setItem(key + suffix, JSON.stringify(data));
      localStorage.setItem(key + suffix + _TIMESTAMP_KEY_ACCESSOR, moment().format());
    } catch (e) {
      __logger.debug('Error while storing data.', 'Key:', key, '; Error:', e);
    }
  }

  public clear(key: string, data: any, suffix: string = ''): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'clear'
    });
    __logger.info('Method start.');
    __logger.infoVerbose('-- Input Data:', 'Key:', key, '; Data:', data, '; Suffix:', suffix);

    try {
      localStorage.removeItem(key + _SUFFIX_KEY_ACCESSOR);
      localStorage.removeItem(key + suffix);
      localStorage.removeItem(key + suffix + _TIMESTAMP_KEY_ACCESSOR);
    } catch (e) {
      __logger.debug('Error while clearing data.', 'Key:', key, '; Error:', e);
    }
  }

  public retrieve(key: string, maxAge: number, suffix: string = ''): any {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'retrieve'
    });
    __logger.info('Method start.');
    __logger.infoVerbose('-- Input Data:', 'Key:', key, '; Age:', maxAge, '; Suffix:', suffix);

    try {
      const storedTimestamp = moment(localStorage.getItem(key + suffix + _TIMESTAMP_KEY_ACCESSOR));
      const now = moment();
      if (now.diff(storedTimestamp) <= maxAge) {
        __logger.debug('Retrieving item.', 'Key:', key);
        return JSON.parse(localStorage.getItem(key + suffix));
      }
      __logger.debug('Item expired or invalid.', 'Key:', key);
    } catch (e) {
      __logger.debug('Error while retrieving data.', 'Key:', key, '; Error:', e);
    }
    return null;
  }

  public isExpiredOrEmpty(key: string, maxAge: number, suffix: string = ''): boolean {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'isExpiredOrEmpty'
    });
    __logger.info('Method start.');
    __logger.infoVerbose('-- Input Data:', 'Key:', key, '; Age:', maxAge, '; Suffix:', suffix);

    try {
      const storedTimestamp = moment(localStorage.getItem(key + suffix + _TIMESTAMP_KEY_ACCESSOR));
      const now = moment();
      if (now.diff(storedTimestamp) <= maxAge) {
        __logger.debug('Cache data is not expired.', 'Key:', key);
        const data = JSON.parse(localStorage.getItem(key + suffix));
        const dataIsNull = (data === null) || (data === undefined) || (data.hasOwnProperty('length') && (data.length === 0));
        __logger.debug('Cache data is null:', dataIsNull);
        return dataIsNull;
      }
    } catch (e) {
      __logger.debug('Error while checking data.', 'Key:', key, '; Error:', e);
    }
    return true;
  }
}
