export class ParamModel {
  public id: number;
  public paramKey: string;
  public paramValue: number | boolean | string;
  public paramType: string;

  constructor(id: number, paramKey: string, paramValue: number | boolean | string, paramType: string) {
    this.id = id;
    this.paramKey = paramKey;
    this.paramValue = paramValue;
    this.paramType = paramType;
  }
}
