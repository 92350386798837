import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoggerContext, SimpleLogger } from '../../../../shared/simple-logger.shared';
import { AuthService } from '../../../../services/auth/auth.service';
import { BaseAppError } from '../../../../services/errors/base-app-error';
import { ErrorsService } from '../../../../services/errors/errors.service';
import { ModalsService } from '../../../../services/modals/modals.service';
import { FacebookService } from '../../../../services/facebook/facebook.service';
import { UserModel } from '../../../../models/user.model';
import { HelpersService } from '../../../../services/helpers/helpers.service';
import { UsersService } from '../../../../services/users/users.service';
import { SessionService } from '../../../../services/session/session.service';
import { LinkedinService } from '../../../../services/linkedin/linkedin.service';
import { GoogleAuthService } from '../../../../services/google-auth/google-auth.service';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'login-page.component.ts',
  className: 'LoginPageComponent',
  tagName: 'PAGES'
});
_LOGGER.debugVerbose('Loaded.');


const _REGISTER_PAGE_URL = '/register',
  _HOME_PAGE_URL = '/home',
  _PROFILE_PAGE_URL = '/profile',
  _LOGIN_PAGE_URL = '/login',
  _SESSION_TAG_REDIRECT_AFTER_LOGIN = 'redirectAfterLogin',
  _SESSION_TAG_REDIRECT_AFTER_LOGIN_QUERY = 'redirectAfterLoginQuery',
  _SESSION_TAG_REDIRECT_AFTER_REGISTER = 'redirectAfterRegister',
  _LINKEDIN_CALLBACK_URL = 'auth/linkedin/callback';

/**
 * LogIn Page.
 */
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  /** Form controls. */
  @ViewChild('loginForm') protected loginForm: NgForm;

  /** Form data. */
  protected disableForm: boolean;
  protected userEmail: string;
  protected userPassword: string;
  protected disableGoogleLogin: boolean;
  protected disableFacebookLogin: boolean;
  protected disableInstagramLogin: boolean;
  protected disableLinkedinLogin: boolean;

  /** Forgot Password Modal. */
  @ViewChild('forgotPasswordModalContent') protected forgotPasswordModalContent: ElementRef;
  protected forgotPasswordModal: NgbModalRef;

  /** Password Recovery Form controls. */
  @ViewChild('forgotPasswordForm') protected forgotPasswordForm: NgForm;

  /** Password Recovery Form data. */
  protected disablePasswordRecoveryForm: boolean;
  protected userEmailForPasswordRecovery: string;

  /** Password Change Modal. */
  @ViewChild('passwordChangeModalContent') protected passwordChangeModalContent: ElementRef;
  protected passwordChangeModal: NgbModalRef;

  /** Password Change Form controls. */
  @ViewChild('changePasswordForm') protected changePasswordForm: NgForm;

  /** Password Change Form data. */
  protected disablePasswordChangeForm: boolean;
  protected userPasswordChange: string;
  protected userPasswordChangeRepeat: string;

  private passwordChangeOperationToken: string;
  private redirectAfterPasswordChange: string;

  private redirectAfterLogin: string;
  private redirectAfterLoginQuery: any;

  constructor(
    private authService: AuthService,
    private errorsService: ErrorsService,
    private viewportScroller: ViewportScroller,
    private modalsService: ModalsService,
    private router: Router,
    private helpersService: HelpersService,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private readonly sessionService: SessionService,
    private readonly googleAuthService: GoogleAuthService,
    private readonly facebookService: FacebookService,
    private readonly linkedinService: LinkedinService
  ) {
  }


  /* Presentation logic. */

  /**
   * Initializes form data.
   */
  private formInit(): void {
    this.disableForm = true;

    this.disableGoogleLogin = true;
    this.disableFacebookLogin = true;
    this.disableInstagramLogin = true;
    this.disableLinkedinLogin = false;

    // No init needed (simple form).

    this.formReset();
  }

  /**
   * Resets the form.
   */
  private formReset(): void {
    if (this.loginForm && this.loginForm.pristine) {
      this.loginForm.control.reset();
    }

    this.userEmail = null;
    this.onChangeUserEmail();
    this.userPassword = null;
    this.onChangeUserPassword();

    this.disableForm = false;
  }

  /**
   * Resets the Password Change Form.
   */
  private changePasswordFormReset(): void {
    if (this.changePasswordForm && this.changePasswordForm.pristine) {
      this.changePasswordForm.control.reset();
    }

    this.userPasswordChange = null;
    this.onChangeUserPasswordChange();
    this.userPasswordChangeRepeat = null;
    this.onChangeUserPasswordChangeRepeat();

    this.disablePasswordRecoveryForm = false;
  }

  /**
   * Opens the Password Change Modal.
   */
  private openPasswordChangeModal(): void {
    this.passwordChangeModal = this.modalsService.showCustomModal(this.passwordChangeModalContent, 'passwordChangeModal', 'lg');
  }

  /**
   * Returns TRUE if e-mail for Password Recovery is invalid.
   */
  protected get isEmailForPasswordRecoveryInvalid(): boolean {
    return !this.helpersService.isEmailValid(this.userEmailForPasswordRecovery);
  }

  /**
   * Display an error in an common error modal.
   */
  private showErrorModalWithMessage(error: any, scrollTop: boolean = false): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'showErrorModalWithMessage'
    });
    __logger.info('Method start.');

    const appError: BaseAppError = this.errorsService.getAppError(error);
    __logger.error('Error:', appError.getMessage());

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            __logger.debug('Error modal closed with result:', result);
          })
          .catch(reason => {
            __logger.debug('Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            if (scrollTop) {
              this.viewportScroller.scrollToAnchor('app-main-header');
            }
          });
      });
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
    this.redirectAfterLogin = this.sessionService.getSessionData(_SESSION_TAG_REDIRECT_AFTER_LOGIN);
    this.sessionService.setSessionData(_SESSION_TAG_REDIRECT_AFTER_LOGIN, null);
    if (!this.redirectAfterLogin) {
      this.redirectAfterLogin = _PROFILE_PAGE_URL;
    }
    this.redirectAfterLoginQuery = this.sessionService.getSessionData(_SESSION_TAG_REDIRECT_AFTER_LOGIN_QUERY);
    this.sessionService.setSessionData(_SESSION_TAG_REDIRECT_AFTER_LOGIN_QUERY, null);
    if (!this.redirectAfterLoginQuery) {
      this.redirectAfterLoginQuery = null;
    }

    this.formInit();

    // Init Google Auth SDK
    this.googleAuthService.init().then(() => {
      this.disableGoogleLogin = false;
    });

    // Init Facebook SDK
    this.facebookService.init().then(() => {
      this.disableFacebookLogin = false;
    });

    // Get Password Change Operation token.
    this.passwordChangeOperationToken = this.route.snapshot.paramMap.get('passwordChangeToken');
    if (this.passwordChangeOperationToken) {
      this.redirectAfterPasswordChange = null;
      this.changePasswordFormReset();
      this.openPasswordChangeModal();

      // Removes token from params.
      this.router.navigate([_LOGIN_PAGE_URL], {replaceUrl: true});
    }

    if (this.route.routeConfig.path === _LINKEDIN_CALLBACK_URL) {
      this.route.queryParams.subscribe(params => {
        if (params['code']) {
          this.onLoginByLinkedinCallback(params['code']);
        } else if (params['error']) {
          this.onLoginFail('Ocurrió un error al iniciar sesión con Linkedin, disculpe las molestias.');
          this.router.navigate([_LOGIN_PAGE_URL], {replaceUrl: true});
        } else {
          this.router.navigate([_LOGIN_PAGE_URL], {replaceUrl: true});
        }
      });
    }
  }

  /**
   * Angular component OnDestroy event handler.
   * Preserves login redirect after register.
   */
  public ngOnDestroy(): void {
    if (this.router.routerState.snapshot.url === _REGISTER_PAGE_URL) {
      this.sessionService.setSessionData(_SESSION_TAG_REDIRECT_AFTER_REGISTER, this.redirectAfterLogin);
      this.sessionService.setSessionData(_SESSION_TAG_REDIRECT_AFTER_LOGIN_QUERY, this.redirectAfterLoginQuery);
    }
  }

  /**
   * User changes E-mail data handler.
   */
  protected onChangeUserEmail(): void {
    // No action needed.
  }

  /**
   * User changes Password data handler.
   */
  protected onChangeUserPassword(): void {
    // No action needed.
  }

  /**
   * LogIn operation failure handler.
   */
  private onLoginFail(error: any): void {
    this.showErrorModalWithMessage(error, true);
  }

  /**
   * Login submit handler.
   */
  public onLoginSubmit(): void {
    const __logger = _LOGGER.getDerivedContext({methodName: 'onLoginSubmit'});
    __logger.info('Method start.');

    if (!this.userEmail || !this.userPassword) {
      this.onLoginFail('Se requiere usuario y contraseña para realizar el LogIn.');
      return;
    }

    this.disableForm = true;
    const loadingModal = this.modalsService.showLoadingModal('Ingresando...');
    this.authService.doLogin(this.userEmail, this.userPassword)
      .subscribe((resultOrError: UserModel | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          __logger.error('doLogin resulted in error.');
          __logger.errorVerbose('Error:', resultOrError);
          this.onLoginFail(resultOrError);
        } else {
          __logger.debugVerbose('doLogin success.');
          this.checkTasaAndRedirect(resultOrError);
        }
      }, (error: any) => {
        __logger.error('Error on doLogin.');
        __logger.errorVerbose('Error:', error);
        this.onLoginFail(error);
      }, () => {
        this.onLoginEnded(loadingModal);
      });
  }

  /**
   * Login by Google submit handler.
   */
  protected onLoginByGoogle(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'onLoginByGoogle'
    });
    _logger.info('Method start.');

    this.disableForm = true;
    const loadingModal = this.modalsService.showLoadingModal('Ingresando...');
    this.googleAuthService.login()
      .then((resultOrError: UserModel | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('onLoginByGoogle resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          this.onLoginFail(resultOrError);
        } else {
          _logger.debugVerbose('onLoginByGoogle success.');
          this.checkTasaAndRedirect(resultOrError);
        }
      })
      .catch((error: BaseAppError) => {
        _logger.error('Error on onLoginByGoogle.');
        _logger.errorVerbose('Error:', error);
        this.onLoginFail(error);
      })
      .finally(() => {
        this.onLoginEnded(loadingModal);
      });
  }

  /**
   * Login by Facebook submit handler.
   */
  protected onLoginByFacebook(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'onLoginByFacebook'
    });
    _logger.info('Method start.');

    this.disableForm = true;
    const loadingModal = this.modalsService.showLoadingModal('Ingresando...');
    this.facebookService.login()
      .then((resultOrError: UserModel | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('onLoginByFacebook resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          this.onLoginFail(resultOrError);
        } else {
          _logger.debugVerbose('onLoginByFacebook success.');
          this.checkTasaAndRedirect(resultOrError);
        }
      })
      .catch((error: BaseAppError) => {
        _logger.error('Error on onLoginByFacebook.');
        _logger.errorVerbose('Error:', error);
        this.onLoginFail(error);
      })
      .finally(() => {
        this.onLoginEnded(loadingModal);
      });
  }

  /**
   * Login by Instagram submit handler.
   */
  protected onLoginByInstagram(): void {
    // No action defined.
  }

  /**
   * Login by Linkedin submit handler.
   */
  protected onLoginByLinkedin(): void {
    // No action defined.
    this.linkedinService.openAuthTab();
  }

  protected onLoginByLinkedinCallback(accessToken: string): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'onSocialLoginSubmit'
    });
    __logger.info('Method start.');

    this.disableForm = true;
    const loadingModal = this.modalsService.showLoadingModal('Ingresando...');
    this.authService.doSocialLogin('linkedin', accessToken)
      .subscribe((resultOrError: UserModel | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          __logger.error('doSocialLogin resulted in error.');
          __logger.errorVerbose('Error:', resultOrError);
          this.onLoginFail(resultOrError);
        } else {
          __logger.debugVerbose('doSocialLogin success.');
          this.checkTasaAndRedirect(resultOrError);
        }
      }, (error: any) => {
        __logger.error('Error on doSocialLogin.');
        __logger.errorVerbose('Error:', error);
        this.onLoginFail(error);
      }, () => {
        this.onLoginEnded(loadingModal);
      });
  }

  protected onLoginEnded(loadingModal: Promise<NgbModalRef>): void {
    this.disableForm = false;
    loadingModal.then(modal => {
      modal.dismiss('done');
    });
  }

  protected checkTasaAndRedirect(userData: UserModel): void {
    if (userData.isTasa) { // Redirect to Admin ABM.
      if (userData.requiresChangePassword) {
        this.passwordChangeOperationToken = userData.changePasswordToken;
        this.redirectAfterPasswordChange = userData.adminUrl;
        this.changePasswordFormReset();
        this.openPasswordChangeModal();
      } else {
        window.location.href = userData.adminUrl;
      }
    } else { // Redirect to HomePage or previous set page.
      this.router.navigate([this.redirectAfterLogin], {queryParams: this.redirectAfterLoginQuery});
    }
  }

  /**
   * User selects link "¿Olvidaste tu password?" handler.
   */
  protected onForgotPassword(): void {
    // Reset form.
    if (this.forgotPasswordForm && this.forgotPasswordForm.pristine) {
      this.forgotPasswordForm.control.reset();
    }
    this.userEmailForPasswordRecovery = null;
    this.onChangeUserEmailForPasswordRecovery();
    this.disablePasswordRecoveryForm = false;

    // Open modal.
    this.forgotPasswordModal = this.modalsService.showCustomModal(this.forgotPasswordModalContent, 'forgotPasswordModalContent', 'lg');
  }

  /**
   * Password Recovery operation failure handler.
   */
  private onPasswordRecoveryFail(error: any): void {
    this.showErrorModalWithMessage(error);
  }

  /**
   * User submits e-mail for password recovery handler.
   */
  protected onForgotPasswordSubmit(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'onForgotPasswordSubmit'
    });
    __logger.info('Method start.');

    if (this.forgotPasswordModal && !this.isEmailForPasswordRecoveryInvalid) {
      this.disablePasswordRecoveryForm = true;
      const loadingModal = this.modalsService.showLoadingModal('Enviando...');
      this.usersService.doPasswordRecovery(this.userEmailForPasswordRecovery)
        .subscribe((resultOrError: boolean | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError)) {
            __logger.error('doPasswordRecovery resulted in error.');
            __logger.errorVerbose('Error:', resultOrError);
            this.onPasswordRecoveryFail(resultOrError);
          } else {
            __logger.debugVerbose('doPasswordRecovery success.');
            // Close input modal.
            this.forgotPasswordModal.close('Submit');
            // Open success modal.
            this.modalsService.showSuccessModal('Revise su casilla de correo.', 'Contraseña recuperada');
          }
        }, (error: any) => {
          __logger.error('Error on doPasswordRecovery.');
          __logger.errorVerbose('Error:', error);
          this.onPasswordRecoveryFail(error);
        }, () => {
          this.disablePasswordRecoveryForm = false;
          loadingModal.then(modal => {
            modal.dismiss('done');
          });
        });
    }
  }

  /**
   * User changes E-mail for password recovery data handler.
   */
  protected onChangeUserEmailForPasswordRecovery(): void {
    // No action defined.
  }

  /**
   * User changes Password for password change data handler.
   */
  protected onChangeUserPasswordChange(): void {
    // No action defined.
  }

  /**
   * User changes Password Repetition for password change data handler.
   */
  protected onChangeUserPasswordChangeRepeat(): void {
    // No action defined.
  }

  /**
   * Password Change operation failure handler.
   */
  private onPasswordChangeFail(error: any): void {
    this.showErrorModalWithMessage(error);
  }

  /**
   * Password Change submit handler.
   */
  protected onPasswordChangeSubmit(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'onPasswordChangeSubmit'
    });
    __logger.info('Method start.');

    if (this.passwordChangeModal && (this.userPasswordChange === this.userPasswordChangeRepeat) && this.passwordChangeOperationToken) {
      this.disablePasswordChangeForm = true;
      const loadingModal = this.modalsService.showLoadingModal('Enviando...');
      this.usersService.doPasswordChange(this.userPasswordChange, this.userPasswordChangeRepeat, this.passwordChangeOperationToken)
        .subscribe((resultOrError: boolean | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError)) {
            __logger.error('doPasswordChange resulted in error.');
            __logger.errorVerbose('Error:', resultOrError);
            this.onPasswordChangeFail(resultOrError);
          } else {
            __logger.debugVerbose('doPasswordChange success.');
            const redirectAfterClosesModal = () => {
              if (this.redirectAfterPasswordChange) {
                const redirectUrl = this.redirectAfterPasswordChange;
                this.redirectAfterPasswordChange = null;
                window.location.href = redirectUrl;
              }
            };
            this.passwordChangeOperationToken = null;
            // Close input modal.
            this.passwordChangeModal.close('Submit');
            // Open success modal.
            this.modalsService.showSuccessModal('Su contraseña ha sido cambiada.', 'Éxito')
              .then(modal => {
                modal.result
                  .then(result => {
                    __logger.debug('Success modal closed with result:', result);
                    redirectAfterClosesModal();
                  })
                  .catch(reason => {
                    __logger.debug('Success modal dismissed with reason:', reason);
                    redirectAfterClosesModal();
                  });
              });
          }
        }, (error: any) => {
          __logger.error('Error on doPasswordChange.');
          __logger.errorVerbose('Error:', error);
          this.onPasswordChangeFail(error);
        }, () => {
          this.disablePasswordChangeForm = false;
          loadingModal.then(modal => {
            modal.dismiss('Done');
          });
        });
    }
  }

  /**
   * Password Change cancel handler.
   */
  protected onPasswordChangeCancel(): void {
    this.passwordChangeModal.dismiss('Cancel');
    this.passwordChangeOperationToken = null;
  }
}
