import { ViewportScroller } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { LoggerContext, SimpleLogger } from '../../../../shared/simple-logger.shared';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'preguntas-frecuentes.component.ts',
  className: 'PreguntasFrecuentesComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');


@Component({
  selector: 'app-preguntas-frecuentes',
  templateUrl: './preguntas-frecuentes.component.html',
  styleUrls: ['./preguntas-frecuentes.component.scss']
})
export class PreguntasFrecuentesComponent implements OnInit {
  @ViewChild('acc') protected acc: NgbAccordion;

  /* Accordion Toggle */
  public accordionValue = '+';

  constructor(
    private viewportScroller: ViewportScroller
  ) {
  }

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
  }


  public accordionToggleHandler(): void {
    this.accordionValue == '+' ? this.accordionValue = '-' : this.accordionValue = '+';
  }

  public onOpenAccAndScroll(accId: string, offset: number = 0): void {
    if (!this.acc.isExpanded(accId)) {
      this.acc.toggle(accId);
    }
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor('accWrap');
      const currentPos = this.viewportScroller.getScrollPosition();
      this.viewportScroller.scrollToPosition([currentPos[0], currentPos[1] - 100 + offset]);
    }, 100);
  }
}
