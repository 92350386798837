import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ResultsPageComponent } from './pages/results-page/results-page.component';
import { PublicationsComponent } from './pages/publications/publications.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { MessagesPageComponent } from './pages/messages-page/messages-page.component';
import { ListMessagesPageComponent } from './pages/list-messages-page/list-messages-page.component';
import { MyReservesComponent } from './pages/my-reserves-page/my-reserves.component';
import { FavoritesPageComponent } from './pages/favorites-page/favorites-page.component';
import { AuthGuard } from '../../guards/auth/auth.guard';
import {SellPageComponent} from './pages/sell-page/sell-page.component';
import { SellWizardPageComponent } from './pages/sell-wizard-page/sell-wizard-page.component';


const _ROUTES: Routes = [
  {path: 'home', component: HomePageComponent},
  {path: 'vender', component: SellPageComponent},
  {path: 'vender/wizard', component: SellWizardPageComponent},
  {path: 'user/password/change/:passwordChangeToken', component: LoginPageComponent},
  {path: 'login', component: LoginPageComponent},
  {path: 'register', component: RegisterPageComponent},
  {path: 'results', component: ResultsPageComponent},
  {path: 'publications', component: PublicationsComponent}, // FIXME: quitar ruta...
  {path: 'publications/:pubId', component: PublicationsComponent},
  {
    path: 'publications/:pubId/:tipoOpe/:tipoPer/:condImp/:monFin/:plazo/:subplan/:cp/:prov/:p_cobertura_id_c/:p_familia_cod_c/:p_compania_id_i',
    component: PublicationsComponent
  },
  {path: 'profile', component: ProfilePageComponent, canActivate: [AuthGuard]},
  {path: 'messages', component: MessagesPageComponent},
  {path: 'list-messages', component: ListMessagesPageComponent},
  {path: 'reserves', component: MyReservesComponent},
  {path: 'favorites', component: FavoritesPageComponent},
  {path: 'auth/linkedin/callback', component: LoginPageComponent}
];

@NgModule({
  imports: [RouterModule.forChild(_ROUTES)],
  exports: [RouterModule]
})
export class PublicRoutingModule {
}
