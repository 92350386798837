import { Component, Input, OnInit } from '@angular/core';
import { PublicationModel } from '../../../../models/publication.model';
import { Router } from "@angular/router";
import { AuthService } from "../../../../services/auth/auth.service";


@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent implements OnInit {
  @Input() publication: PublicationModel;

  protected swiperConfig = {
    spaceBetween: 0,
    centeredSlides: true,
    allowTouchMove: false,
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  protected get isVehicleLiked(): boolean {
    return this.isUserLoggedIn && !!this.publication && this.publication.liked;
  }

  protected get isUserLoggedIn(): boolean {
    return this.authService.isUserLoggedIn();
  }


  ngOnInit() {
  }

  protected onLiked(event: Event): void {
    event.stopPropagation();
    if (this.authService.isUserLoggedIn()) {
      this.publication.liked = !this.publication.liked;
    }
  }

  protected onImageNotFound(imageIndex: number): void {
    this.publication.images[imageIndex] = '../../../../../assets/img/car_image_not_found.png';
  }

  protected onVehicleCardClick(): void {
    this.router.navigate([`/publications/${this.publication.id}`]);
  }

  protected onClickArrow(event: Event): void {
    event.stopPropagation();
  }
}
