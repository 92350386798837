import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalsService } from "../../../../services/modals/modals.service";
import { ShareFriendModalComponent } from "../share-friend/share-friend.component";
import { PublicationsService } from "../../../../services/publications/publications.service";


@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']

})
export class ShareModalComponent implements OnInit {
  @Input() publicationId: number;
  public url: string;

  constructor(
    public shareModal: NgbActiveModal,
    private modalsService: ModalsService,
    private publicationsService: PublicationsService
  ) {
    this.url = this.publicationsService.doShareViaFacebook();
  }

  ngOnInit() {
  }

  protected openShareFriend() {
    let modalInstance = this.modalsService.showCustomModal(ShareFriendModalComponent, 'shareFriendModalComponent', 'lg');
    modalInstance.componentInstance.publicationId = this.publicationId;
    this.shareModal.close('Dismiss');
    return modalInstance;
  }

  /**
   * Closes modal.
   */
  protected onAccept(): void {
    this.shareModal.close('Dismiss');
  }
}
