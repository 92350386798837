export class TcfautosCarInfoModel {
  public suggestedPrice: number;
  public maxLoanAmount: number;
  public minLoanAmount: number;

  constructor(suggestedPrice: number, maxLoanAmount: number, minLoanAmount: number) {
    this.suggestedPrice = suggestedPrice;
    this.maxLoanAmount = maxLoanAmount;
    this.minLoanAmount = minLoanAmount;
  }
}
