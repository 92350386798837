import { Component, Input, OnInit } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../../../shared/simple-logger.shared';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'loading-modal.component.ts',
  className: 'LoadingModalComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');


/**
 * Modal used to display loading messages to the user.
 */
@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent implements OnInit {
  @Input() message: string;

  constructor() {
  }


  /* Handlers. */

  ngOnInit() {
  }
}
