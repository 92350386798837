import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users/users.service';
import { BaseAppError } from 'src/app/services/errors/base-app-error';
import { LoggerContext, SimpleLogger } from "../../../../shared/simple-logger.shared";
import { ErrorsService } from 'src/app/services/errors/errors.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { SessionService } from "../../../../services/session/session.service";


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'side-nav.component.ts',
  className: 'SideNavComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  active: boolean = false;
  protected messageCount: number;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private errorsService: ErrorsService,
    private authService: AuthService,
    private modalsService: ModalsService,
    private viewportScroller: ViewportScroller,
    private sessionService: SessionService
  ) {
  }

  ngOnInit() {
    this.getMessageCount();
  }

  /**
   * Navs to Login Page.
   */
  private goToLoginPage(): void {
    this.sessionService.setSessionData('redirectAfterLogin', '/home');
    this.router.navigate(['/login']);
  }

  private onError(error: any, navigate: boolean = false): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'onSignUpFail'
    });
    __logger.info('Method start.');

    const appError: BaseAppError = this.errorsService.getAppError(error);
    __logger.error('Error:', appError.getMessage());

    if (appError.getCode() === ErrorsService.getApiHttpErrorCode(401)) {
      this.authService.expireSession();
      navigate = true;
    }

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            __logger.debug('Error modal closed with result:', result);
          })
          .catch(reason => {
            __logger.debug('Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
            if (navigate) {
              this.goToLoginPage();
            }
          });
      });
  }

  private getMessageCount(): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getMessageCount'
    });
    __logger.info('Method start.');

    this.usersService.getUserMessagesCount()
      .subscribe((resultOrError: number | BaseAppError) => {
        if ((!resultOrError && (resultOrError !== 0)) || (resultOrError instanceof BaseAppError)) {
          __logger.error('getMessageCount resulted in error.');
          __logger.errorVerbose('Error:', resultOrError);
          this.onError(resultOrError);
        } else {
          __logger.debugVerbose('getMessageCount success.');
          this.messageCount = resultOrError;
        }
      }, (error: any) => {
        __logger.error('Error on getMessageCount.');
        __logger.errorVerbose('Error:', error);
        this.onError(error);
      });
  }

  isOpen() {
    this.active = !this.active;
  }
}
