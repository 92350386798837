import { Component, Input, OnInit } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../../../shared/simple-logger.shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'success-modal.component.ts',
  className: 'SuccessModalComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');


/**
 * Modal used to display success messages to the user.
 */
@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  @Input() message: string;
  @Input() title: string;

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }


  /* Handlers. */

  ngOnInit() {
  }

  /**
   * Closes modal.
   */
  protected onAccept(): void {
    this.activeModal.close('Accept');
  }
}
