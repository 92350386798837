import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { Observable, of } from 'rxjs';
import { BaseAppError } from '../errors/base-app-error';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PersistenceService } from '../persistence/persistence.service';
import { ErrorsService } from '../errors/errors.service';
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { ILegalsGetTextsResponse } from './i-legals-get-texts-response';
import { IApiCommonResponse } from '../i-api-common-response';
import { ILegalsGetTerminosMandatoVentaResponse } from './i-legals-get-terminos-mandato-venta-response';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'legals.service.ts',
  className: 'LegalsService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


const _CACHE_CONFIG = {
  GET_TEXTS: {
    KEY: 'get_texts',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  },
  GET_TERMINOS_MANDATO_VENTA: {
    KEY: 'get_terminos_mandato_venta',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  }
};


/**
 * Provinces Service.
 */
@Injectable({
  providedIn: 'root'
})
export class LegalsService {
  private baseURL = `${_CONFIG.apiBaseURL}/toyotausados`;

  /** Cache data. */
  private cacheGetTexts: Observable<ILegalsGetTextsResponse | BaseAppError>;

  /** Mandato Venta Cache Data */
  private cacheGetTerminosMandatoVenta: Observable<ILegalsGetTerminosMandatoVentaResponse | BaseAppError>;

  constructor(
    private http: HttpClient,
    private persistenceService: PersistenceService,
    private errorsService: ErrorsService
  ) {
  }

  /**
   * Gets legal Texts.
   */
  public getTexts(): Observable<ILegalsGetTextsResponse | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getTexts'
    });
    __logger.info('Method start.');

    // Check cache.
    if (this.persistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_TEXTS.KEY, _CACHE_CONFIG.GET_TEXTS.AGE)) {
      const endpointURL = `${this.baseURL}/legales`;
      __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

      this.cacheGetTexts = this.http.get<IApiCommonResponse<ILegalsGetTextsResponse>>(endpointURL, { observe: 'response' })
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<ILegalsGetTextsResponse>>) => {
            __logger.debug('API response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<IApiCommonResponse<ILegalsGetTextsResponse>>) => response.body.data),
          // Store in cache.
          tap((data: ILegalsGetTextsResponse) => {
            this.persistenceService.store(_CACHE_CONFIG.GET_TEXTS.KEY, data);
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetTexts) {
        const cacheData: ILegalsGetTextsResponse = this.persistenceService.retrieve(_CACHE_CONFIG.GET_TEXTS.KEY, _CACHE_CONFIG.GET_TEXTS.AGE);
        this.cacheGetTexts = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: ILegalsGetTextsResponse) => {
              __logger.debug('Cache response:', data);
            }),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorsService.getAppError(error)))
          );
      }
    }

    return this.cacheGetTexts;
  }

  /**
 * Gets TyC Mandato de Venta.
 */
  public getTerminosMandatoVenta(): Observable<ILegalsGetTerminosMandatoVentaResponse | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getTerminosMandatoVenta'
    });
    __logger.info('Method start.');

    // Check cache.
    if (this.persistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_TERMINOS_MANDATO_VENTA.KEY, _CACHE_CONFIG.GET_TERMINOS_MANDATO_VENTA.AGE)) {
      const endpointURL = `${this.baseURL}/mandatoventa`;
      __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

      this.cacheGetTerminosMandatoVenta = this.http.get<IApiCommonResponse<ILegalsGetTerminosMandatoVentaResponse>>(endpointURL, { observe: 'response' })
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<ILegalsGetTerminosMandatoVentaResponse>>) => {
            __logger.debug('API response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<IApiCommonResponse<ILegalsGetTerminosMandatoVentaResponse>>) => response.body.data),
          // Store in cache.
          tap((data: ILegalsGetTerminosMandatoVentaResponse) => {
            this.persistenceService.store(_CACHE_CONFIG.GET_TERMINOS_MANDATO_VENTA.KEY, data);
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetTerminosMandatoVenta) {
        const cacheData: ILegalsGetTerminosMandatoVentaResponse = this.persistenceService.retrieve(
          _CACHE_CONFIG.GET_TERMINOS_MANDATO_VENTA.KEY,
          _CACHE_CONFIG.GET_TERMINOS_MANDATO_VENTA.AGE
        );
        this.cacheGetTerminosMandatoVenta = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: ILegalsGetTerminosMandatoVentaResponse) => {
              __logger.debug('Cache response:', data);
            }),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorsService.getAppError(error)))
          );
      }
    }

    return this.cacheGetTerminosMandatoVenta;
  }
}
