import { Component } from '@angular/core';
import { EnvironmentManager } from './shared/environment-manager.shared';
import { LoggerContext, SimpleLogger } from './shared/simple-logger.shared';
import { GoogleAnalyticsService } from "./services/google-analytics/google-analytics.service";


const _CONFIG = EnvironmentManager.getInstance().getConfig();
SimpleLogger.getInstance().setLogLevel(_CONFIG.logLevel);


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'app.component.ts',
  className: 'AppComponent',
  methodName: '',
  tagName: 'APP'
});
_LOGGER.debugVerbose('Loaded.');


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'constructor'
    });
    __logger.infoVerbose('Created.');
  }
}
