import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { Observable, of } from 'rxjs';
import { BaseAppError } from '../errors/base-app-error';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PersistenceService } from '../persistence/persistence.service';
import { ErrorsService } from '../errors/errors.service';
import { IApiCommonResponse } from '../i-api-common-response';
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { VehicleModelModel } from '../../models/vehicle-model.model';
import { IModelsGetModelsResponseModelItem } from './i-models-get-models-response-model-item';
import { VehicleBrandModel } from 'src/app/models/vehicle-brand.model';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'models.service.ts',
  className: 'ModelsService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


const _CACHE_CONFIG = {
  GET_MODELS: {
    KEY: 'get_models',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  }
};


/**
 * Models Service.
 */
@Injectable({
  providedIn: 'root'
})
export class ModelsService {
  private baseURL = `${_CONFIG.apiBaseURL}/toyotausados/modelos`;
  private baseURLLegacy = `${_CONFIG.apiBaseURL}/modelo`;

  /** Cache data. */
  private cacheGetModels: Observable<VehicleModelModel[] | BaseAppError>;

  constructor(
    private http: HttpClient,
    private persistenceService: PersistenceService,
    private errorsService: ErrorsService
  ) {
  }

  /**
   * Gets all vehicle Models.
   */
  public getModels(vehicleBrand?: VehicleBrandModel): Observable<VehicleModelModel[] | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getModels'
    });
    __logger.info('Method start.', '--VehicleBrand:', vehicleBrand);

    const __CACHE_KEY_SUFFIX = `_${!!vehicleBrand ? vehicleBrand.id : 0}`;

    const endpointURL = `${this.baseURL}?marca_id=${!!vehicleBrand ? vehicleBrand.id : ''}`;
    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

    return this.http.get<IApiCommonResponse<IModelsGetModelsResponseModelItem[]>>(endpointURL, { observe: 'response' })
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<IModelsGetModelsResponseModelItem[]>>) => {
            __logger.debug('API response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<IApiCommonResponse<IModelsGetModelsResponseModelItem[]>>) => response.body.data.map(
            vModel => new VehicleModelModel(vModel.id, vModel.mod_descripcion_s, vModel.id_fu))),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );

  }
  /**
   * Gets vehicle Models by year.
   */
  public getModelsByYear(year: string, vehicleBrand?: VehicleBrandModel): Observable<VehicleModelModel[] | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getModels'
    });
    __logger.info('Method start.', '--VehicleBrand:', vehicleBrand, '--VehicleYear:', year);

    const __CACHE_KEY_SUFFIX = `_${!!vehicleBrand ? vehicleBrand.id : 0}_${year}`;

    // Check cache.
    if (this.persistenceService.isExpiredOrEmpty(`${_CACHE_CONFIG.GET_MODELS.KEY + __CACHE_KEY_SUFFIX}`, _CACHE_CONFIG.GET_MODELS.AGE)) {
      const endpointURL = `${this.baseURLLegacy}/${!!vehicleBrand ? vehicleBrand.id : ''}/year/${year}`;
      __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

      this.cacheGetModels = this.http.get<IApiCommonResponse<IModelsGetModelsResponseModelItem[]>>(endpointURL, { observe: 'response' })
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<IModelsGetModelsResponseModelItem[]>>) => {
            __logger.debug('API response:', response);

          }),
          // Parse response.
          map((response: HttpResponse<IApiCommonResponse<IModelsGetModelsResponseModelItem[]>>) => response.body.data.map(
            vModel => new VehicleModelModel(vModel.id, vModel.mod_descripcion_s, vModel.id_fu))),
          // Store in cache.
          tap((data: VehicleModelModel[]) => {
            this.persistenceService.store(`${_CACHE_CONFIG.GET_MODELS.KEY + __CACHE_KEY_SUFFIX}`, data);
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );
    } else {
      const cacheData: VehicleModelModel[] = this.persistenceService.retrieve(`${_CACHE_CONFIG.GET_MODELS.KEY + __CACHE_KEY_SUFFIX}`, _CACHE_CONFIG.GET_MODELS.AGE);
      this.cacheGetModels = of(cacheData)
        .pipe(
          // Log operation.
          tap((data: VehicleModelModel[]) => {
            __logger.debug('Cache response:', data);
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );
    }
    __logger.info('end.');
    return this.cacheGetModels;
  }
}
