import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseAppError } from './base-app-error';
import { IAPIErrorResponse } from './i-api-error-response';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'errors.service.ts',
  className: 'ErrorService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
  /** Unknown error. */
  public readonly UNKNOWN_ERROR = {code: 'UNKNOWN', message: 'Error desconocido.'};

  /** Known external errors. */
  public readonly EXTERNAL_KNOWN_ERROR = {
    NETWORK: {
      OFFLINE: {
        code: 'EXT:NETWORK:OFFLINE',
        message: 'No fue posible conectarse con el servidor. Por favor, compruebe su conexión a Internet e intente nuevamente.'
      }
    },
    API: {
      SERVER_BASE: {
        code: 'EXT:API:SERVER_BASE',
        message: 'Error general del sistema, por favor intente nuevamente más tarde.'
      },
      UNAUTHORIZED: {
        code: 'EXT:API:UNAUTHORIZED',
        message: 'Usted no tiene permisos suficientes para acceder a esta funcionalidad o su sesión ha expirado.'
      }
    }
  };

  constructor() {
  }

  /**
   * Returns a known error code from any HTTP error number code.
   */
  private static getHttpErrorCode(code: number): string {
    return 'HTTP:' + code.toString();
  }

  /**
   * Returns a known error code from an API HTTP error number code.
   */
  public static getApiHttpErrorCode(code: number): string {
    return 'API:' + ErrorsService.getHttpErrorCode(code);
  }


  /**
   * Errors handler. Default behaviour: console log if not production env.
   */
  public static handleError(error: any): void {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'handleError'
    });

    if (!_CONFIG.production) {
      __logger.error('Error:', error);
    }
  }

  /**
   * Parses API message errors.
   */
  private getApiErrorText(apiError: IAPIErrorResponse): string {
    // Get error message from API error response interface.
    return ((apiError && apiError.errors && apiError.errors.message && (apiError.errors.message instanceof Array ? apiError.errors.message.join(' - ') : apiError.errors.message)) || this.UNKNOWN_ERROR.message);
  }

  /**
   * Handles App errors.
   */
  public getAppError(appError: any): BaseAppError {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getAppError'
    });

    if (appError instanceof BaseAppError) {
      return appError;
    }

    __logger.debugVerbose('Error:', appError);

    // Base error.
    let errorCode: string = (appError && (appError.code || appError.errorCode) || this.UNKNOWN_ERROR.code);
    let errorMessage: string = (appError && (appError.message || appError.errorMessage) || this.UNKNOWN_ERROR.message);

    // HTTP Errors.
    if (appError instanceof HttpErrorResponse) { // Common HTTP errors.
      __logger.debugVerbose('Error is instanceof HttpErrorResponse.');

      errorCode = ErrorsService.getHttpErrorCode(appError.status);
      errorMessage = (appError.statusText || errorMessage);

      __logger.debugVerbose('Error partial.', 'Code:', errorCode, 'Msg:', errorMessage);

      // Get Response body.
      let responseBody: any = (appError.error || {});
      if ((responseBody instanceof String) || (typeof responseBody === 'string')) {
        try {
          __logger.debugVerbose('Parsing JSON HttpErrorResponse Body:', responseBody);
          if (typeof responseBody !== 'string') {
            responseBody = JSON.parse(responseBody.toString());
          } else {
            responseBody = JSON.parse(responseBody);
          }
        } catch (e) {
          __logger.errorVerbose('Error parsing appError Response body as JSON:', e);
        }
      }
      __logger.debugVerbose('HttpErrorResponse Body:', responseBody);

      // Get error context by URL.
      if (appError.url) {
        __logger.debugVerbose('HttpErrorResponse URL:', appError.url);

        // TCFA API errors.
        if (appError.url.indexOf(_CONFIG.apiBaseURL) === 0) {
          __logger.debugVerbose('HttpErrorResponse URL matches API:', _CONFIG.apiBaseURL);

          errorCode = ErrorsService.getApiHttpErrorCode(appError.status);
          errorMessage = (this.getApiErrorText(responseBody as IAPIErrorResponse) || errorMessage);

          __logger.debugVerbose('Error partial.', 'Code:', errorCode, 'Msg:', errorMessage);
        }
      }

      // Override explicit errors messages.
      if (appError.status === 0) { // Network error.
        __logger.debugVerbose('HttpErrorResponse overriding error status 0 (network error).');
        errorMessage = this.EXTERNAL_KNOWN_ERROR.NETWORK.OFFLINE.message;
      } else if (appError.status >= 500) { // Server-side errors.
        __logger.debugVerbose('HttpErrorResponse overriding error status 500 (server error).');
        errorMessage = this.EXTERNAL_KNOWN_ERROR.API.SERVER_BASE.message;
      } else if (appError.status === 401) { // Session error.
        __logger.debugVerbose('HttpErrorResponse overriding error status 401 (session error).');
        errorMessage = this.EXTERNAL_KNOWN_ERROR.API.UNAUTHORIZED.message;
      }

    } else if (appError instanceof Error) { // Known errors.
      // Base known errors.
      __logger.debugVerbose('Error is instanceof Error.');

      errorCode = (appError.name || errorCode);
      errorMessage = (appError.message || errorMessage);

    } else if ((appError instanceof Number) || (typeof appError === 'number')) { // Numeric errors.
      // Base numeric errors.
      __logger.debugVerbose('Error is instanceof Number.');

      errorCode = appError.toString();
      errorMessage = 'Error ' + appError.toString() + '.';

    } else if ((appError instanceof String) || (typeof appError === 'string')) { // String errors (no code).
      __logger.debugVerbose('Error is instanceof String.');

      errorMessage = (appError.toString() || errorMessage);

    } else { // Other errors.
      __logger.debugVerbose('Error is instanceof None.');
    }

    __logger.debugVerbose('Error partial.', 'Code:', errorCode, 'Msg:', errorMessage);

    // Handle error & return error message.
    const resultError: BaseAppError = new BaseAppError(errorCode, errorMessage);
    ErrorsService.handleError(resultError);
    return resultError;
  }
}
