export class UserMessageCarModel {
  public brand: string;
  public model: string;
  public version: string;
  public year: number;
  public price: number;
  public kms: number;
  public carImages: string[]

  public constructor(brand: string, model: string, version: string,
    year: number, price: number, kms: number, carImages: string[]) {
    this.brand = brand;
    this.model = model;
    this.version = version;
    this.year = year;
    this.price = price;
    this.kms = kms;
    this.carImages = carImages;
  }
}
