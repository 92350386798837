import Swiper from 'swiper';
import { Component, ElementRef, OnInit, SecurityContext, TemplateRef, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicationDetailModel } from '../../../../models/publication-detail.model';
import { PublicationsService } from '../../../../services/publications/publications.service';
import { BaseAppError } from '../../../../services/errors/base-app-error';
import { LoggerContext, SimpleLogger } from '../../../../shared/simple-logger.shared';
import { ModalsService } from '../../../../services/modals/modals.service';
import { ViewportScroller } from '@angular/common';
import { ErrorsService } from '../../../../services/errors/errors.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PublicationFiltersModel } from '../../../../models/publication-filters.model';
import { SessionService } from '../../../../services/session/session.service';
import { DealerModel } from '../../../../models/dealer.model';
import { Observable } from 'rxjs';
import { PageDataModel } from '../../../../models/page-data.model';
import { PublicationModel } from '../../../../models/publication.model';
import { AuthService } from '../../../../services/auth/auth.service';
import { HelpersService } from '../../../../services/helpers/helpers.service';
import { ShareModalComponent } from '../../components/share/share.component';
import { WidgetCotizadorService } from '../../../../services/widget-cotizador/widget-cotizador.service';
import { IWidgetCotizadorInitResponse } from '../../../../services/widget-cotizador/i-widget-cotizador-init-response';
import { EnvironmentManager } from '../../../../shared/environment-manager.shared';
import { CotizadorService } from '../../../../services/cotizador/cotizador.service';
import { TcfautosCarInfoModel } from '../../../../models/tcfautos-car-info.model';
import { PublicationDealerExtraInfoModel } from '../../../../models/publication-dealer-extra-info.model';
import { DealersService } from '../../../../services/dealers/dealers.service';
import { UserProfileModel } from '../../../../models/user-profile.model';
import { UsersService } from '../../../../services/users/users.service';
import { NumericFormatterDirective } from '../../../../directives/numeric-formatter/numeric-formatter.directive';
import { PhoneValidatedModel } from '../../../../models/phone-validated.model';
import { PhoneVerifyModalComponent } from '../../../../modules/main/components/phone-verify-modal/phone-verify-modal.component';
import { UserModel } from '../../../../models/user.model';
import { UserActionService } from '../../../../services/user-action/user-action.service';
import { UserActionModel } from '../../../../models/user-action-send.model';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'publications-page.component.ts',
  className: 'PublicationsComponent',
  tagName: 'PAGES'
});
_LOGGER.debugVerbose('Loaded.');

/* Funtion facebook fbq*/
declare let fbq: Function;
/* var function Google*/
declare let gtag;

const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _SESSION_TAG_OPEN_SEND_MESSAGE_MODAL = 'openSendMessageModal';

const _METHOD_START_MSG = 'Method start.';

const PREDEFINED_TEXTS = {
  OPENING: 'Hola, ',
  CLOSING: '. Muchas gracias.',
  QUESTIONS: {
    PHOTOS: 'me gustaría recibir más fotos de esta publicación',
    INTERESTED: 'estoy interesado en esta publicación, me gustaría poder contactarlo',
    FINANCING: 'me gustaría recibir más información sobre los planes de financiación',
    PAYMENT_METHODS: 'poseo un auto que me gustaría dar en forma de pago. Solicito información al respecto'
  }
};

const NAV_BAR_ABSOLUTE_HEIGHT = 100;


/**
 * Publication Detail page.
 */
@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss']
})
export class PublicationsComponent implements OnInit {
  protected app: string;
  protected canal: string;
  private publicationId: number;
  private sharedPlazo: number;
  private sharedSubplan: number;
  private sharedPostalCode: number;
  private sharedStateId: string;
  private sharedInsurancePlanId: string;
  private sharedInsuranceTypeCode: string;
  private sharedInsuranceCompanyId: number;
  private isSharedPublication: boolean = false;
  protected publicationDetail: PublicationDetailModel;
  protected tcfautosCarInfo: TcfautosCarInfoModel;
  protected loadingDetail: boolean;
  protected loadingTCFAutosCarInfo: boolean;

  protected isPublicationPreview: boolean;

  protected youtubeVideoId: string;

  /** Swipers config & controls. */
  @ViewChild('swiperGallery') protected swiperGallery: ElementRef;
  @ViewChild('swiperThumbs') protected swiperThumbs: ElementRef;
  private swiperGalleryInstance: Swiper;
  private swiperThumbsInstance: Swiper;
  protected swiperGalleryConfig: SwiperConfigInterface = {
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    thumbs: {
      swiper: {
        el: '.gallery-thumbs',
        spaceBetween: 4,
        slidesPerView: 'auto',
        /*loop: true,*/
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        direction: 'vertical',
        centeredSlides: false,
        slideThumbActiveClass: 'swiper-slide-thumb-active',
        on: {
          init: () => {
            this.onSwiperThumbsInit();
          }
        }
      }
    }
  };
  @ViewChild('swiperRelatedPublications') protected swiperRelatedPublicationsDirectiveRef: ElementRef;
  private swiperRelatedPublicationsInstance: Swiper;
  protected swiperConfigRelatedPublications: SwiperConfigInterface = {
    autoHeight: true,
    spaceBetween: 12,
    centeredSlides: false,
    pagination: {
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-related-publications-arrow-right',
      prevEl: '.swiper-related-publications-arrow-left'
    },
    slidesPerView: 'auto',
    freeMode: true,
    freeModeMomentum: false
  };
  protected relatedPublications: PublicationModel[];
  protected loadingRelatedPublications: boolean;

  /** Form data Enviar Mensaje. */
  protected userCellphone: string;
  protected userPhone: string;
  protected userMessage: string;
  protected userName: string;
  protected selectedPredefinedTexts: { [key: string]: boolean };

  /** Quote Vehicle Form. */
  protected disableQuoteForm: boolean;
  protected quotePersonTypeIsEnterprise: boolean;
  protected quoteLoanAmount: string;
  protected quoteLoanAmountAsNumber: number;
  protected quoteTaxConditionIsInscribedResponsible: boolean;
  protected quoteMinLoanAmount: number = 5000; // Fixed.
  protected quoteMaxLoanAmount: number;

  private widgetCotizadorSdkInitPromise: Promise<any>;
  protected hideWidgetCotizador: boolean;
  private widgetInitData: IWidgetCotizadorInitResponse;

  /** Modal Refs. */
  @ViewChild('sendMessageModalTemplate') protected sendMessageModalTemplate: ElementRef;
  @ViewChild('confirmMessageModalTemplate') protected confirmMessageModalTemplate: ElementRef;
  private sendMessageModal: NgbModalRef;
  private confirmMessageModal: NgbModalRef;

  /** Quote Vehicle Modal. */
  protected disableQuoteModal: boolean;
  private quoteModal: NgbModalRef;

  /** Send Message Modal. */
  protected disableSendMessageForm: boolean;

  /** Iframe wrapper ref to scroll. */
  @ViewChild('iframeWrapper') protected iframeWrapper: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private router: Router,
    private publicationsService: PublicationsService,
    private modalsService: ModalsService,
    private errorsService: ErrorsService,
    private viewportScroller: ViewportScroller,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private helpersService: HelpersService,
    private widgetCotizadorService: WidgetCotizadorService,
    private cotizadorService: CotizadorService,
    private dealersService: DealersService,
    private usersService: UsersService,
    private userActionService: UserActionService
  ) {
  }

  /* Presentation logic. */

  private getPublicationsRelated(dealerId: number): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'getPublicationsRelated'
    });
    _logger.info(_METHOD_START_MSG);

    this.relatedPublications = [];
    this.loadingRelatedPublications = true;
    this.publicationsService.getPublicationsByDealerId(dealerId)
      .subscribe((resultOrError: PageDataModel<PublicationModel> | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.items || !resultOrError.items.length) {
          _logger.error('getPublicationsByDealerId resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
        } else {
          _logger.debugVerbose('getPublicationsByDealerId success.');
          this.relatedPublications = resultOrError.items;
        }
      }, (error: any) => {
        _logger.error('Error on getPublicationsByDealerId.');
        _logger.errorVerbose('Error:', error);
      }, () => {
        this.loadingRelatedPublications = false;
        setTimeout(() => {
          if (this.swiperRelatedPublicationsInstance) {
            this.swiperRelatedPublicationsInstance.update();
          }
        }, 10);
      });
  }

  /**
   * Saves Publications results on Session and navigates to Results Page.
   *
   * @param publications
   * @param filters
   */
  private savePublicationsAndNavToResults(publications: Observable<PageDataModel<PublicationModel> | BaseAppError>, filters: PublicationFiltersModel): void {
    this.sessionService.setSessionData('publicationsResults', publications);
    this.sessionService.setSessionData('publicationsFilters', filters);
    this.router.navigate(['/results']);
  }

  private getTcfautosCarInfo(): Observable<TcfautosCarInfoModel | BaseAppError> {
    this.loadingTCFAutosCarInfo = true;
    const ob = this.cotizadorService.getTcfautosCarInfo(this.publicationDetail.dealer.cuit, 'RE',
      this.quotePersonTypeIsEnterprise ? 'J' : 'F', this.publicationDetail.vehicleIdFu,
      this.publicationDetail.isNew, this.publicationDetail.price);
    ob.subscribe((resultOrError: TcfautosCarInfoModel | BaseAppError) => {
      if (!resultOrError || (resultOrError instanceof BaseAppError)) {
        this.onError(resultOrError);
      } else {
        this.tcfautosCarInfo = resultOrError;
        this.quoteMaxLoanAmount = Math.round(resultOrError.maxLoanAmount);
        this.quoteMinLoanAmount = Math.round(resultOrError.minLoanAmount);
        if (!this.quoteLoanAmount) {
          this.quoteLoanAmount = NumericFormatterDirective.parseInputToString(resultOrError.suggestedPrice, true);
          this.onChangeQuoteLoanAmount();
        }
        this.onBlurQuoteLoanAmount();
      }
    }, (error: any) => {
      this.onError(error);
    }, () => {
      this.loadingTCFAutosCarInfo = false;
    });
    return ob;
  }

  private getPublicationDetail(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'getPublicationDetail'
    });
    _logger.info(_METHOD_START_MSG);

    const pubIdParam: string = this.route.snapshot.paramMap.get('pubId');
    if (pubIdParam) {
      this.publicationId = parseInt(pubIdParam, 10);
    }
    const sharedPersonTypeId: string = this.route.snapshot.paramMap.get('tipoPer');
    if (sharedPersonTypeId) {
      this.quotePersonTypeIsEnterprise = (sharedPersonTypeId === 'J');
      this.onChangeQuotePersonTypeIsEnterprise(true);
    }
    const sharedTaxConditionId: string = this.route.snapshot.paramMap.get('condImp');
    if (sharedTaxConditionId) {
      this.quoteTaxConditionIsInscribedResponsible = (sharedTaxConditionId === 'RI');
      this.onChangeQuoteTaxConditionIsInscribedResponsible();
    }
    const sharedAmountToFinance: string = this.route.snapshot.paramMap.get('monFin');
    if (sharedAmountToFinance) {
      this.quoteLoanAmount = sharedAmountToFinance;
      this.onChangeQuoteLoanAmount();
    }
    const sharedPlazo = this.route.snapshot.paramMap.get('plazo');
    if (sharedPlazo) {
      this.sharedPlazo = parseInt(sharedPlazo);
    }
    const sharedSubplan: string = this.route.snapshot.paramMap.get('subplan');
    if (sharedSubplan) {
      this.sharedSubplan = parseInt(sharedSubplan);
    }
    const sharedPostalCode: string = this.route.snapshot.paramMap.get('cp');
    if (sharedPostalCode) {
      this.sharedPostalCode = parseInt(sharedPostalCode);
    }
    const sharedStateId: string = this.route.snapshot.paramMap.get('prov');
    if (sharedStateId) {
      this.sharedStateId = sharedStateId;
    }
    const sharedInsurancePlanId: string = this.route.snapshot.paramMap.get('p_cobertura_id_c');
    if (sharedInsurancePlanId) {
      this.sharedInsurancePlanId = sharedInsurancePlanId;
    }
    const sharedInsuranceTypeCode: string = this.route.snapshot.paramMap.get('p_familia_cod_c');
    if (sharedInsuranceTypeCode) {
      this.sharedInsuranceTypeCode = sharedInsuranceTypeCode;
    }
    const sharedInsuranceCompanyId: string = this.route.snapshot.paramMap.get('p_compania_id_i');
    if (sharedInsuranceCompanyId) {
      this.sharedInsuranceCompanyId = parseInt(sharedInsuranceCompanyId);
    }
    if (this.publicationId && sharedPersonTypeId && sharedTaxConditionId && sharedAmountToFinance &&
      this.sharedPlazo && this.sharedSubplan && this.sharedPostalCode && this.sharedStateId &&
      this.sharedInsurancePlanId && this.sharedInsuranceTypeCode && this.sharedInsuranceCompanyId) {
      this.isSharedPublication = true;
    }

    let publicationDetailObservable: Observable<PublicationDetailModel | BaseAppError>;
    if (this.publicationId) {
      this.isPublicationPreview = false;
      publicationDetailObservable = this.publicationsService.getPublicationById(this.publicationId);
      this.sendUserActionPublicacionId(this.publicationId);
    } else {
      const tokenPreview: string = this.route.snapshot.queryParamMap.get('token');
      this.publicationId = 0;
      if (tokenPreview) {
        this.isPublicationPreview = true;
        publicationDetailObservable = this.publicationsService.getPublicationPreview(tokenPreview);
      }
    }

    if (publicationDetailObservable) {
      this.loadingDetail = true;
      const loadingModal = this.modalsService.showLoadingModal('Obteniendo datos...');
      publicationDetailObservable.subscribe((resultOrError: PublicationDetailModel | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('getPublicationById resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          this.onError(resultOrError);
        } else {
          _logger.debugVerbose('getPublicationById success.');
          this.publicationDetail = resultOrError;
          this.publicationDetail.description = this.sanitizer.sanitize(SecurityContext.HTML, this.publicationDetail.description);

          this.youtubeVideoId = null;
          if (this.publicationDetail.video) {
            const regEx = /(?:https?:\/\/(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=)?)([\w-]+)(?:&.*)*/i;
            const regResult = regEx.exec(this.publicationDetail.video); // Format: 'https://youtu.be/Dpkb4NZ5IEQ' | 'https://www.youtube.com/watch?v=JTjC-Vubx9s'
            if (regResult && (regResult.length >= 1)) {
              this.youtubeVideoId = regEx.exec(this.publicationDetail.video)[1];
            }
          }

          this.getPublicationsRelated(this.publicationDetail.dealer.id);
          setTimeout(() => {
            if (this.swiperGalleryInstance) {
              this.swiperGalleryInstance.update();
            }
            if (this.swiperThumbsInstance) {
              this.swiperThumbsInstance.update();
            }
          }, 10);

          // Get TCFAutos car's info.
          this.getTcfautosCarInfo()
            .subscribe(null, null, () => {
              this.closeLoadingModal(loadingModal);
            });

          // Get Dealer's extra info.
          if (!this.publicationDetail.dealer.extraInfo) {
            this.publicationDetail.dealer.extraInfo = new PublicationDealerExtraInfoModel(
              'Cargando...',
              'Cargando...',
              'Cargando...',
              'Cargando...'
            );

            const __onDealersGetMapFail = () => {
              this.publicationDetail.dealer.extraInfo.address = '-';
              this.publicationDetail.dealer.extraInfo.addressZone = '-';
              this.publicationDetail.dealer.extraInfo.phone = '-';
              this.publicationDetail.dealer.extraInfo.email = '-';
            };

            const __onDealersGetMapSuccess = (dealersMap: DealerModel[]) => {
              const dealer = dealersMap.find(d => d.id === this.publicationDetail.dealer.id);
              if (dealer) {
                const dealerCentralBranch = dealer.branches.find(db => db.isCentralBranch);
                if (dealerCentralBranch) {
                  this.publicationDetail.dealer.extraInfo.address = dealerCentralBranch.address;
                  this.publicationDetail.dealer.extraInfo.addressZone = dealerCentralBranch.location;
                  this.publicationDetail.dealer.extraInfo.phone = dealerCentralBranch.phone;
                  this.publicationDetail.dealer.extraInfo.email = dealerCentralBranch.email;
                } else {
                  __onDealersGetMapFail();
                }
              } else {
                __onDealersGetMapFail();
              }
            };

            this.dealersService.getMap()
              .subscribe(
                // Success response.
                (resultOrError: DealerModel[] | BaseAppError) => {
                  if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.length) {
                    _logger.error('Cannot resolve Dealers Map array.', resultOrError);
                    __onDealersGetMapFail();
                  } else {
                    _logger.debug('Dealers Map initialized.');
                    __onDealersGetMapSuccess(resultOrError);
                  }
                },
                // Error response.
                (error: any) => {
                  _logger.error('Error on get Dealers Map.', error);
                  __onDealersGetMapFail();
                });
          }

          // Open previous opened message modal.
          if (!!this.sessionService.getSessionData(_SESSION_TAG_OPEN_SEND_MESSAGE_MODAL)) {
            this.sessionService.setSessionData(_SESSION_TAG_OPEN_SEND_MESSAGE_MODAL, null);
            if (this.authService.isUserLoggedIn()) {
              if (this.authService.getUserData().profile) {
                this.resetFormMessage();
                this.onOpenSendMessageModal();
              } else {
                this.usersService.getUserProfile()
                  .subscribe((resultOrError: UserProfileModel | BaseAppError) => {
                    if (!resultOrError || (resultOrError instanceof BaseAppError)) {
                      _logger.error('getUserProfile resulted in error.');
                      _logger.errorVerbose('Error:', resultOrError);
                      this.onError(resultOrError);
                    } else {
                      _logger.debugVerbose('getUserProfile success.');
                      this.resetFormMessage();
                      this.onOpenSendMessageModal();
                    }
                  }, (error: any) => {
                    _logger.error('Error on getUserProfile.');
                    _logger.errorVerbose('Error:', error);
                    this.onError(error);
                  });
              }
            }
          }
        }
      }, (error: any) => {
        _logger.error('Error on getPublicationById.');
        _logger.errorVerbose('Error:', error);
        this.onError(error);
      }, () => {
        this.loadingDetail = false;
      });
    } else {
      this.router.navigate(['/home']);
    }
  }

  /**
   * Navs to Login Page.
   */
  private goToLoginPage(): void {
    this.sessionService.setSessionData('redirectAfterLogin', `/publications/${this.publicationId}`);
    this.router.navigate(['/login']);
  }

  protected get publicationImagesAndVideosCount(): number {
    let imagesCount = 0;
    if (this.publicationDetail) {
      if (this.publicationDetail.images && this.publicationDetail.images.length) {
        imagesCount = imagesCount + this.publicationDetail.images.length;
      }
      if (this.youtubeVideoId) {
        imagesCount = imagesCount + 1;
      }
    }
    return imagesCount;
  }

  /**
   * Return TRUE if Cellphone is invalid
   */
  protected get isCellInvalid(): boolean {
    return !this.helpersService.isCellphoneValid(this.userCellphone);
  }

  /**
   * Return TRUE if Phone is invalid
   */
  protected get isPhoneInvalid(): boolean {
    return !this.helpersService.isCellphoneValid(this.userPhone);
  }

  private resetQuoteForm(): void {
    this.hideWidgetCotizador = true;

    this.disableQuoteForm = true;
    this.quotePersonTypeIsEnterprise = false;
    this.onChangeQuotePersonTypeIsEnterprise(true);
    this.quoteTaxConditionIsInscribedResponsible = false;
    this.onChangeQuoteTaxConditionIsInscribedResponsible();
    this.disableQuoteForm = false;
  }

  protected get isPublicationLiked(): boolean {
    return this.isUserLoggedIn && !!this.publicationDetail && this.publicationDetail.liked;
  }

  protected get isUserLoggedIn(): boolean {
    return this.authService.isUserLoggedIn();
  }


  /* Handlers. */

  protected onSwiperGalleryInit(): void {
    this.swiperGalleryInstance = this.swiperGallery.nativeElement.swiper;
  }

  protected onSwiperThumbsInit(): void {
    this.swiperThumbsInstance = this.swiperThumbs.nativeElement.swiper;
  }

  protected onSwiperRelatedPublicationsInit(): void {
    this.swiperRelatedPublicationsInstance = this.swiperRelatedPublicationsDirectiveRef.nativeElement.swiper;
  }

  /**
   * Shows errors to user.
   */
  private onError(error: any, navigate: boolean = false): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'onSignUpFail'
    });
    _logger.info(_METHOD_START_MSG);

    const appError: BaseAppError = this.errorsService.getAppError(error);
    _logger.error('Error:', appError.getMessage());

    if (appError.getCode() === ErrorsService.getApiHttpErrorCode(401)) {
      this.authService.expireSession();
      navigate = true;
    }

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            _logger.debug('Error modal closed with result:', result);
          })
          .catch(reason => {
            _logger.debug('Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
            if (navigate) {
              this.goToLoginPage();
            }
          });
      });
  }

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'ngOnInit'
    });
    _logger.info(_METHOD_START_MSG);

    this.route.paramMap
      .subscribe(() => {
        this.resetQuoteForm();
        this.resetFormMessage();
        this.getPublicationDetail();
      });

    this.widgetCotizadorSdkInitPromise = this.widgetCotizadorService.sdkInit('iframe-wrapper', (val: number) => {
      _logger.debugVerbose('Widget iFrame Update', val);
    }, (val: { height: number; }) => {
      _logger.debugVerbose('Widget iFrame Scroll To', val, this.iframeWrapper.nativeElement);
      const iframeTop = (!!this.iframeWrapper && !!this.iframeWrapper.nativeElement && this.iframeWrapper.nativeElement.offsetTop) || 0;
      this.viewportScroller.scrollToPosition([0, val.height + iframeTop - NAV_BAR_ABSOLUTE_HEIGHT]);
    });

    this.disableQuoteModal = true;
    this.widgetCotizadorSdkInitPromise
      .then((value: IWidgetCotizadorInitResponse) => {
        _logger.debugVerbose('SDK Init Success.', value);
        this.widgetInitData = value;
        this.disableQuoteModal = false;

        if (this.isSharedPublication) {
          this.onConfirmQuoteVehicle();
        }
      })
      .catch((err: any) => {
        _logger.errorVerbose('Error on SDK Init.', err);
        this.onError(this.errorsService.getAppError(err).getMessage());
      });
  }

  protected onSelectAllRelatedPublications(): void {
    if (this.isPublicationPreview) {
      return;
    }

    const filters = new PublicationFiltersModel(false, false);
    filters.dealer = new DealerModel(this.publicationDetail.dealer.id, this.publicationDetail.dealer.name, [], 0);
    this.savePublicationsAndNavToResults(this.publicationsService.getPublicationsByAdvancedSearch(
      null, null, null, null, null, null, null, null, null, null, null,
      this.publicationDetail.dealer.id), filters);
  }

  /**
   * User selects "Vehículos" link on breadcrumb (or Vehicle Brand Name link).
   */
  protected onSelectAllPublications(): void {
    if (this.isPublicationPreview) {
      return;
    }
    this.savePublicationsAndNavToResults(this.publicationsService.getPublicationsByAdvancedSearch(null, null, null, null,
      null, null, null, null, null, null), new PublicationFiltersModel());
  }

  /**
   * User selects Vehicle Model Name link on breadcrumb.
   */
  protected onSelectPublicationsByRelatedModel(): void {
    if (this.isPublicationPreview) {
      return;
    }

    const filters = new PublicationFiltersModel(false, false);
    filters.vehicleModel = this.publicationDetail.vehicleModel;
    this.savePublicationsAndNavToResults(this.publicationsService.getPublicationsByAdvancedSearch(
      null, null, this.publicationDetail.vehicleModel.id), filters);
  }

  protected onLiked(): void {
    if (this.authService.isUserLoggedIn() && !this.isPublicationPreview) {
      this.publicationDetail.liked = !this.publicationDetail.liked;
    }
  }

  protected onOpenReserveModal(reserveModalRef) {
    this.modalsService.showCustomModal(reserveModalRef, 'reserveModal', 'lg', true, 'modal-basic-title-reserve');
  }

  protected onSelectDealerInfo(infoConcesionarioModalRed) {
    if (this.isPublicationPreview) {
      return;
    }
    this.modalsService.showCustomModal(infoConcesionarioModalRed, 'infoConcesionario', 'lg', true, 'modal-basic-title-info-dealer');
  }

  protected onOpenQuoteModal(quoteModalRef): void {
    // Reset form.
    this.resetQuoteForm();
    // Facebook fbq
    fbq('track', 'Simula_tu_Credito');
    // Google GDN Simula_tu_Credito
    gtag('event', 'conversion', {
      event_label: 'wJuvCM2Oz8IBEKfY38ED',
      value: 943189031
    });
    // Google SEM Simula_tu_Credito
    gtag('event', 'conversion', {
      event_label: 'VitXCJ7Z38IBEMeE7qAD',
      value: 874218055
    });

    const loadingModal = this.modalsService.showLoadingModal('Obteniendo valores...');
    this.getTcfautosCarInfo()
      .subscribe(() => {
        // Open modal.
        this.quoteModal = this.modalsService.showCustomModal(quoteModalRef, 'simulaCredito', 'lg', true, 'quote-modal-basic-title');
      }, null, () => {
        this.closeLoadingModal(loadingModal);
      });
  }

  protected onChangeQuotePersonTypeIsEnterprise(skipUpdate = false, event: Event = null): void {
    if (event) {
      (event.target as HTMLElement).blur();
      event.preventDefault();
    }

    if (this.quotePersonTypeIsEnterprise) {
      this.quoteTaxConditionIsInscribedResponsible = true;
      this.onChangeQuoteTaxConditionIsInscribedResponsible();
    }

    // Update loan values.
    if (!skipUpdate) {
      const loadingModal = this.modalsService.showLoadingModal('Obteniendo valores...');
      this.getTcfautosCarInfo()
        .subscribe(null, null, () => {
          this.closeLoadingModal(loadingModal);
        });
    }
  }

  protected onChangeQuoteTaxConditionIsInscribedResponsible(): void {
    // No action.
  }

  protected onChangeQuoteLoanAmount(): void {
    this.quoteLoanAmountAsNumber = NumericFormatterDirective.parseInputToFloat(this.quoteLoanAmount);
  }

  protected onBlurQuoteLoanAmount(): void {
    let newValue: number;
    if (this.quoteLoanAmountAsNumber < this.quoteMinLoanAmount) {
      newValue = this.quoteMinLoanAmount;
    } else if (this.quoteLoanAmountAsNumber > this.quoteMaxLoanAmount) {
      newValue = this.quoteMaxLoanAmount;
    }
    if (newValue || (newValue === 0)) {
      this.quoteLoanAmount = NumericFormatterDirective.parseInputToString(newValue, true);
      this.onChangeQuoteLoanAmount();
    }
  }

  protected onOpenConfirmReserveModal(confirmReserveModalRef: TemplateRef<any>, reserveModal: NgbModalRef) {
    reserveModal.dismiss('Close.');
    this.modalsService.showCustomModal(confirmReserveModalRef, 'confirmacionReserva', 'lg', true, 'modal-basic-title-confirm-reserve');
  }

  protected onOpenSendMessageModal(reset: boolean = false) {
    if (this.isPublicationPreview) {
      return;
    }

    // Facebook fbq
    fbq('track', 'Solicitar_Informacion');
    // Google GDN Solicitar_Informacion
    gtag('event', 'conversion', {
      event_label: 'YlWICNKQz8IBEKfY38ED',
      value: 943189031
    });
    // Google SEM Solicitar_Informacion
    gtag('event', 'conversion', {
      event_label: 'UMURCMHd6cIBEMeE7qAD',
      value: 874218055
    });

    if (reset) {
      this.resetFormMessage();
    }

    if (this.authService.isUserLoggedIn()) {
      this.sendMessageModal = this.modalsService.showCustomModal(this.sendMessageModalTemplate, 'enviarMensaje', 'lg', true, 'modal-basic-title-send-message');
    } else {
      this.sessionService.setSessionData(_SESSION_TAG_OPEN_SEND_MESSAGE_MODAL, true);
      this.goToLoginPage();
    }
  }

  protected onOpenConfirmMessageModal() {
    this.confirmMessageModal = this.modalsService.showCustomModal(this.confirmMessageModalTemplate, 'confirmacionMensaje', 'lg', true, 'modal-basic-title-confirm-message');
  }

  openShare() {
    if (this.isPublicationPreview) {
      return;
    }

    let modalInstance = this.modalsService.showCustomModal(ShareModalComponent, 'shareModalComponent', 'lg');
    modalInstance.componentInstance.publicationId = +this.publicationId;
    return modalInstance;
  }

  protected onOpenShareModal(shareModalRef: TemplateRef<any>) {
    this.modalsService.showCustomModal(shareModalRef, 'share', 'lg', true, 'modal-basic-title');
  }

  protected enviarMensajeConcesionario(): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'enviarMensajeConcesionario'
    });
    _logger.info(_METHOD_START_MSG);

    if (!this.userMessage) {
      this.onError('El campo Mensaje es requerido.');
      return;
    }
    if (this.userMessage.length < 4) {
      this.onError('El campo Mensaje es muy corto.');
      return;
    }
    if (!this.userPhone) {
      this.onError('El campo Teléfono es requerido.');
      return;
    }
    if (!this.userCellphone) {
      this.onError('El campo Celular es requerido.');
      return;
    }

    // Facebook fbq
    fbq('track', 'Consultar_Informacion');
    // Google GDN Consultar_Informacion
    gtag('event', 'conversion', {
      event_label: 'qG9xCN3V6cIBEKfY38ED',
      value: 943189031
    });
    // Google SEM Consultar_Informacion
    gtag('event', 'conversion', {
      event_label: 'pQm6CP7k6cIBEMeE7qAD',
      value: 874218055
    });

    if (this.authService.isUserLoggedIn()) {
      const userData = this.authService.getUserData();

      if (userData.profile.cellphoneValidated && this.userCellphone === userData.profile.cellphone) {
        this.doSendConcesionarioMessage(userData);
      } else {
        const verifyModal = this.modalsService.showCustomModal(PhoneVerifyModalComponent, 'phone-verify-modal', 'sm',
          true, 'modal-basic-title', false, 'static');
        verifyModal.componentInstance.phone = this.userCellphone;
        verifyModal.componentInstance.email = userData.profile.email;

        verifyModal.result.then(result => {
          _logger.debug('Celular verificado correctamente:', result);
          this.doSendConcesionarioMessage(userData);
        }).catch(error => {
          _logger.error('Error al verificar celular:', error);
          this.modalsService.showErrorModal('Ocurrió un error al intentar verificar su número de celular, ' +
            'por favor intente nuevamente.');
        });
      }
    } else {
      this.goToLoginPage();
    }
  }

  private doSendConcesionarioMessage(userData: UserModel): void {
    const _logger = _LOGGER.getDerivedContext({
      methodName: 'doSendConcesionarioMessage'
    });
    _logger.info(_METHOD_START_MSG);

    this.disableSendMessageForm = true;
    const loadingModal = this.modalsService.showLoadingModal('Enviando mensaje...');
    this.userName = userData.name + ' ' + userData.surname;
    this.publicationsService.sendMessage(this.publicationId, this.userMessage, this.userCellphone, this.userPhone)
      .subscribe((resultOrError: BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          _logger.error('sendMessage resulted in error.');
          _logger.errorVerbose('Error:', resultOrError);
          this.onError(resultOrError);
        } else {
          _logger.debugVerbose('sendMessage success.');
          this.sendMessageModal.dismiss('Close');
          this.onOpenConfirmMessageModal();
        }
      }, (error: any) => {
        _logger.error('Error on sendMessage.');
        _logger.errorVerbose('Error:', error);
        this.onError(error);
      }, () => {
        this.resetFormMessage();
        this.closeLoadingModal(loadingModal);
      });
  }

  protected onConfirmQuoteVehicle(): void {
    if (this.quoteModal) {
      this.quoteModal.dismiss('quote');
    }

    // Quote operation trigger data.
    if (!this.widgetInitData) {
      this.onError('No se pudo recuperar la información de cotización.');
      return;
    }
    let operationTypeId: number;
    let personTypeId: number;
    let taxConditionId: number;
    const operationTypeCode = _CONFIG.defaultOperationTypeCode;
    const personTypeCode = this.quotePersonTypeIsEnterprise ? 'J' : 'F';
    const taxConditionCode = this.quoteTaxConditionIsInscribedResponsible ? 'RI' : 'CF';
    const selectedOperationType = this.widgetInitData.operationTypes.find(ot => ot.code === operationTypeCode);
    if (selectedOperationType) {
      operationTypeId = selectedOperationType.id;
    } else {
      this.onError('No se pudo recuperar el tipo de operación a cotizar.');
      return;
    }
    const selectedPersonType = this.widgetInitData.personTypes.find(pt => pt.code === personTypeCode);
    if (selectedPersonType) {
      personTypeId = selectedPersonType.id;
      const selectedTaxCondition = selectedPersonType.taxConditions.find(tc => tc.code === taxConditionCode);
      if (selectedTaxCondition) {
        taxConditionId = selectedTaxCondition.id;
      } else {
        this.onError('No se pudo recuperar el tipo de condición impositiva seleccionado.');
        return;
      }
    } else {
      this.onError('No se pudo recuperar el tipo de persona seleccionado.');
      return;
    }
    // Facebook fbq
    fbq('track', 'Continuar_Credito');
    // Google GDN Continuar_Credito
    gtag('event', 'conversion', {
      event_label: 'QiEgCI7S38IBEKfY38ED',
      value: 943189031
    });
    // Google SEM Continuar_Credito
    gtag('event', 'conversion', {
      event_label: '0TunCLLj6cIBEMeE7qAD',
      value: 874218055
    });
    this.disableQuoteForm = true;
    const loadingModal = this.modalsService.showLoadingModal('Cotizando...');
    this.widgetCotizadorSdkInitPromise
      .then(() => {
        this.hideWidgetCotizador = false;
        this.app = 'ToyotaUsados';
        this.canal = 'TUS';
        if (!this.isSharedPublication) {
          this.widgetCotizadorService.quoteVehicle(operationTypeId, personTypeId, taxConditionId, this.publicationDetail.vehicleIdFu,
            this.quoteLoanAmountAsNumber, this.publicationDetail.year, this.publicationDetail.isNew, this.publicationDetail.price,
            this.publicationDetail.dealer.cuit, this.publicationDetail.vehicleBrand.idFu, this.publicationDetail.vehicleModel.idFu,
            this.publicationDetail.vehicleVersion.idFu, this.publicationDetail.id, this.publicationDetail.images[0],
            this.publicationDetail.vehicleBrand.name, this.publicationDetail.vehicleModel.name, this.publicationDetail.vehicleVersion.name,
            this.canal, this.publicationDetail.isCertificated, this.app, false, this.publicationDetail.plans).then((value: any) => {
            // No action.
          }).catch((error: any) => {
            this.resetQuoteForm();
            this.onError(error);
          });
        } else {
          this.widgetCotizadorService.quoteVehicleShared(operationTypeId, personTypeId, taxConditionId, this.publicationDetail.vehicleIdFu,
            this.quoteLoanAmountAsNumber, this.publicationDetail.year, this.publicationDetail.isNew, this.publicationDetail.price,
            this.publicationDetail.dealer.cuit, this.publicationDetail.vehicleBrand.idFu, this.publicationDetail.vehicleModel.idFu,
            this.publicationDetail.vehicleVersion.idFu, this.publicationDetail.id, this.publicationDetail.images[0],
            this.publicationDetail.vehicleBrand.name, this.publicationDetail.vehicleModel.name, this.publicationDetail.vehicleVersion.name,
            this.canal, this.publicationDetail.isCertificated, this.app, false, this.publicationDetail.plans, this.sharedPlazo,
            this.sharedSubplan, this.sharedPostalCode, this.sharedStateId, this.sharedInsurancePlanId, this.sharedInsuranceTypeCode,
            this.sharedInsuranceCompanyId).then((value: any) => {
              // No action.
          }).catch((error: any) => {
            this.resetQuoteForm();
            this.onError(error);
          });
        }
      }).catch((err: any) => {
      this.onError(err);
    }).finally(() => {
      this.closeLoadingModal(loadingModal);
    });
  }

  /**
   * Reset Form send message
   */
  private resetFormMessage(): void {
    this.userMessage = null;
    this.selectedPredefinedTexts = {};
    this.disableSendMessageForm = false;
    const userData = this.authService.getUserData();
    if (userData && userData.profile) {
      this.userCellphone = userData.profile.cellphone;
      this.userPhone = userData.profile.cellphone;
    } else {
      this.userCellphone = null;
      this.userPhone = null;
    }
  }

  /**
   * Add a predefined text to the user's message in the Send Message Modal.
   * @param predefinedTextKey
   */
  private addPredefinedText(predefinedTextKey: string): void {
    if (this.selectedPredefinedTexts.hasOwnProperty(predefinedTextKey)) {
      delete this.selectedPredefinedTexts[predefinedTextKey];
    } else {
      this.selectedPredefinedTexts[predefinedTextKey] = true;
    }

    const selectedTexts = Object.keys(PREDEFINED_TEXTS.QUESTIONS).filter(k => !!this.selectedPredefinedTexts[k]);
    if (selectedTexts.length > 0) {
      this.userMessage = PREDEFINED_TEXTS.OPENING +
        selectedTexts.map(k => PREDEFINED_TEXTS.QUESTIONS[k]).join('; ') +
        PREDEFINED_TEXTS.CLOSING;
    } else {
      this.userMessage = '';
    }
  }

  /**
   * User selects "Ver publicaciones similares de precio" button event handler.
   */
  protected onSearchPublicationsBySimilarPrice(): void {
    this.confirmMessageModal.dismiss('Close');
    const priceOffset = 100_000;
    const minPrice = Math.max(this.publicationDetail.price - priceOffset, _CONFIG.vehicleInstallmentsPriceMinValueToFilter);
    const maxPrice = Math.min(this.publicationDetail.price + priceOffset, _CONFIG.vehicleInstallmentsPriceMaxValueToFilter);
    const filters = new PublicationFiltersModel(false, false);
    filters.priceFrom = minPrice;
    filters.priceTo = maxPrice;
    this.savePublicationsAndNavToResults(this.publicationsService.getPublicationsByAdvancedSearch(null, null,
      null, null, null, null, null, minPrice, maxPrice, null, null), filters);
  }

  /**
   * User selects "Ver publicaciones similares de modelo" button event handler.
   */
  protected onSearchPublicationsBySimilarVehicleModel(): void {
    this.confirmMessageModal.dismiss('Close');
    const filters = new PublicationFiltersModel(false, false);
    filters.vehicleModel = this.publicationDetail.vehicleModel;
    this.savePublicationsAndNavToResults(this.publicationsService.getPublicationsByAdvancedSearch(null, null,
      this.publicationDetail.vehicleModel.id, null, null, null, null, null, null,
      null, null), filters);
  }

  /**
   * User blurs Cellphone
   */
  protected onBlurCellPhone(): void {
    if (!this.isCellInvalid) {
      const _logger = _LOGGER.getDerivedContext({
        methodName: 'onBlurCellPhone'
      });
      _logger.info(_METHOD_START_MSG);

      const loadingModal = this.modalsService.showLoadingModal('Validando teléfono. Por favor espere.');
      this.usersService.validatePhone(this.userCellphone)
        .subscribe((resultOrError: PhoneValidatedModel | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError)) {
            const appError: BaseAppError = this.errorsService.getAppError(resultOrError);
            _logger.error('onBlurCellPhone resulted in error.');
            _logger.errorVerbose('Error:', resultOrError);
            this.userCellphone = '';
            this.modalsService.showErrorModal(appError.getMessage());
          } else {
            _logger.debugVerbose('onBlurCellPhone success.');
            this.userCellphone = resultOrError.phone;
          }
        }, (error: BaseAppError) => {
          _logger.error('Error on onBlurCellPhone.');
          _logger.errorVerbose('Error:', error);
          this.userCellphone = '';
          this.modalsService.showErrorModal('Ha ocurrido un error al validar el teléfono. Intente nuevamente en unos minutos.');
        }, () => {
          this.disableSendMessageForm = false;
          this.closeLoadingModal(loadingModal);
        });
    }
  }

  /**
   * User blurs Phone
   */
  protected onBlurPhone(): void {
    if (!this.isPhoneInvalid) {
      const _logger = _LOGGER.getDerivedContext({
        methodName: 'onBlurPhone'
      });
      _logger.info(_METHOD_START_MSG);

      const loadingModal = this.modalsService.showLoadingModal('Validando teléfono. Por favor espere.');
      this.usersService.validatePhone(this.userPhone)
        .subscribe((resultOrError: PhoneValidatedModel | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError)) {
            const appError: BaseAppError = this.errorsService.getAppError(resultOrError);
            _logger.error('onBlurPhone resulted in error.');
            _logger.errorVerbose('Error:', resultOrError);
            this.userPhone = '';
            this.modalsService.showErrorModal(appError.getMessage());
          } else {
            _logger.debugVerbose('onBlurPhone success.');
            this.userPhone = resultOrError.phone;
          }
        }, (error: BaseAppError) => {
          _logger.error('Error on onBlurPhone.');
          _logger.errorVerbose('Error:', error);
          this.userPhone = '';
          this.modalsService.showErrorModal('Ha ocurrido un error al validar el teléfono. Intente nuevamente en unos minutos.');
        }, () => {
          this.disableSendMessageForm = false;
          this.closeLoadingModal(loadingModal);
        });
    }
  }

  private closeLoadingModal(loadingModal: Promise<NgbModalRef>): void {
    loadingModal.then(modal => {
      modal.dismiss('done');
    });
  }

  private sendUserActionPublicacionId(id): void{
    const logger = _LOGGER.getDerivedContext({methodName: 'sendUserActionPublicacionId'});
    logger.info('Method start.');
    this.userActionService.sendUserAction('landing_modelo_seleccionado', id, id).subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
  }
}
