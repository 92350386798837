import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from "../../shared/simple-logger.shared";
import { EnvironmentManager } from "../../shared/environment-manager.shared";
import { Observable, of } from "rxjs";
import { BaseAppError } from "../errors/base-app-error";
import { ParamModel } from "../../models/param.model";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { PersistenceService } from "../persistence/persistence.service";
import { ErrorsService } from "../errors/errors.service";
import { IApiCommonResponse } from "../i-api-common-response";
import { catchError, map, publishReplay, refCount, tap } from "rxjs/operators";
import { IParamsGetParamResponse } from "./i-params-get-param-response";


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'params.service.ts',
  className: 'ParamsService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


const _CACHE_CONFIG = {
  GET_PARAMS: {
    KEY: 'get_params',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  }
};


/**
 * Params Service.
 */
@Injectable({
  providedIn: 'root'
})
export class ParamsService {
  private baseURL = `${_CONFIG.apiBaseURL}/parametros`;

  /** Cache data. */
  private cacheGetParams: { [key: string]: Observable<ParamModel | BaseAppError> } = {};

  constructor(
    private http: HttpClient,
    private persistenceService: PersistenceService,
    private errorsService: ErrorsService
  ) {
  }

  /**
   * Gets a Param by a given key.
   */
  public getParamByKey(paramKey: string): Observable<ParamModel | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getParamByKey'
    });
    __logger.info('Method start.');

    const __CACHE_KEY_SUFFIX = `_${paramKey}`;

    // Check cache.
    if (this.persistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_PARAMS.KEY + __CACHE_KEY_SUFFIX, _CACHE_CONFIG.GET_PARAMS.AGE)) {
      const endpointURL = `${this.baseURL}/${paramKey}`;
      __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

      this.cacheGetParams[__CACHE_KEY_SUFFIX] = this.http.get<IApiCommonResponse<IParamsGetParamResponse[]>>(endpointURL, {observe: 'response'})
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<IParamsGetParamResponse[]>>) => {
            __logger.debug('API response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<IApiCommonResponse<IParamsGetParamResponse[]>>) => {
            const param = response.body.data.find(p => p.clave === paramKey);
            if (param) {
              let paramValue: number | boolean | string;
              switch (param.tipo) {
                case "boolean":
                  paramValue = (param.valor === "true");
                  break;
                case "number":
                  paramValue = parseInt(param.valor, 10);
                  break;
                case "string": // Same as default.
                default:
                  paramValue = param.valor;
              }
              return new ParamModel(param.id, param.clave, paramValue, param.tipo);
            }
            return null;
          }),
          // Store in cache.
          tap((data: ParamModel) => {
            if (data) {
              this.persistenceService.store(_CACHE_CONFIG.GET_PARAMS.KEY + __CACHE_KEY_SUFFIX, data);
            }
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetParams[__CACHE_KEY_SUFFIX]) {
        const cacheData: ParamModel = this.persistenceService.retrieve(_CACHE_CONFIG.GET_PARAMS.KEY + __CACHE_KEY_SUFFIX, _CACHE_CONFIG.GET_PARAMS.AGE);
        this.cacheGetParams[__CACHE_KEY_SUFFIX] = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: ParamModel) => {
              __logger.debug('Cache response:', data);
            }),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorsService.getAppError(error)))
          );
      }
    }

    return this.cacheGetParams[__CACHE_KEY_SUFFIX];
  }
}
