import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleMapService } from '../../../../services/google-map/google-map.service';
import { IGoogleGetReverseGeocodingResponse } from '../../../../services/google-map/i-google-get-reverse-geocoding-response';
import { BaseAppError } from '../../../../services/errors/base-app-error';
import { LoggerContext, SimpleLogger } from '../../../../shared/simple-logger.shared';
import { Observable, Subject } from 'rxjs';
import { PublicationsService } from '../../../../services/publications/publications.service';
import { PublicationSearchResultModel } from '../../../../models/publication-search-result.model';
import { SessionService } from '../../../../services/session/session.service';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { AuthService } from '../../../../services/auth/auth.service';
import { PublicationFiltersModel } from '../../../../models/publication-filters.model';
import { VehicleVersionModel } from '../../../../models/vehicle-version.model';
import { VehicleModelModel } from '../../../../models/vehicle-model.model';
import { ModelsService } from '../../../../services/models/models.service';
import { UserProfileModel } from '../../../../models/user-profile.model';
import { UsersService } from '../../../../services/users/users.service';
import { filter, takeUntil } from 'rxjs/operators';
import {CommonsService} from '../../../../services/commons/commons.service';
import { UserActionService } from '../../../../services/user-action/user-action.service';
import { UserActionModel } from '../../../../models/user-action-send.model';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'page-header-legacy.component.ts',
  className: 'PageHeaderLegacyComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');

type FeaturedVehicleModelsColumn = { label: string, model?: VehicleModelModel, hybrid?: boolean }[];

interface FeaturedVehicleModelsByColumns {
  left: FeaturedVehicleModelsColumn;
  right: FeaturedVehicleModelsColumn;
}

/* Function facebook fbq*/
// tslint:disable-next-line:ban-types
declare let fbq: Function;
/* var function Google*/
declare let gtag;

/**
 * Legacy Page Header component to place top of legacy views.
 */
@Component({
  selector: 'app-page-header-legacy',
  templateUrl: './page-header-legacy.component.html',
  styleUrls: ['./page-header-legacy.component.scss'],
  animations: [
    trigger('sideNavMenuDisplay', [
      state('closed', style({
        width: '0px'
      })),
      state('opened', style({
        width: '300px'
      })),
      transition('closed => opened', [
        animate('200ms')
      ]),
      transition('opened => closed', [
        animate('200ms')
      ])
    ])
  ]
})
export class PageHeaderLegacyComponent implements OnInit {
  public routerChangesSubscriptionIsDestroyed = new Subject<any>();

  @Input() disableAll = false;

  @Output()
  public openAdvancedSearch = new EventEmitter<any>();

  public showNav = false;
  public userLocation: string;
  public searchText: string;
  protected lastSelectedTitle: string;

  private featuredVehicleModels: VehicleModelModel[];
  protected loadingFeaturedVehicleModels: boolean;
  public featuredModels: FeaturedVehicleModelsByColumns = {
    left: [
      {label: 'Etios'},
      {label: 'Corolla'},
      {label: 'Hilux'},
      {label: 'Innova'},
      {label: 'Camry'},
      {label: 'Land Cruiser'}
    ],
    right: [
      {label: 'Land Cruiser 200'},
      {label: 'Prius', hybrid: true},
      {label: 'RAV'},
      {label: 'SW4'},
      {label: 'Yaris'},
      {label: '86'}
    ]
  };

  protected loadingProfile: boolean;
  public messageCount: number;
  public profileData: UserProfileModel;

  constructor(
    private googleMapService: GoogleMapService,
    private publicationsService: PublicationsService,
    private sessionService: SessionService,
    private router: Router,
    private authService: AuthService,
    private modelsService: ModelsService,
    private usersService: UsersService,
    private commonsService: CommonsService,
    private userActionService: UserActionService
  ) {
  }

  /* Presentation logic. */

  public get noLeftFeaturedModels(): boolean {
    return !(this.featuredModels.left.some(fm => !!fm.model));
  }

  public get noRightFeaturedModels(): boolean {
    return !(this.featuredModels.right.some(fm => !!fm.model));
  }

  public get isUserLoggedIn(): boolean {
    return this.authService.isUserLoggedIn();
  }

  public get userName(): string {
    if (this.authService.isUserLoggedIn()) {
      const name = this.authService.getUserData().name.split(' ')[0].toLowerCase();
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
    return 'GUEST';
  }

  public get userAvatarUrl(): string {
    if (this.authService.isUserLoggedIn()) {
      return this.authService.getUserData().imageUrl;
    }
    return null;
  }

  private getFeaturedModels(): void {
    const logger = _LOGGER.getDerivedContext({methodName: 'getFeaturedModels'});
    logger.info('Method start.');

    this.featuredVehicleModels = [];
    this.loadingFeaturedVehicleModels = true;
    this.modelsService.getModels().subscribe((resultOrError: VehicleVersionModel[] | BaseAppError) => {
      if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.length) {
        logger.error('getModels resulted in error.');
        logger.errorVerbose('Error:', resultOrError);
      } else {
        logger.debug('Vehicle Models initialized.');
        this.featuredVehicleModels = resultOrError;
        this.featuredModels.left.concat(this.featuredModels.right).forEach(fm => {
          const name = fm.label.toLowerCase();
          fm.model = this.featuredVehicleModels.find(vm => vm.name.toLowerCase() === name);
        });
      }
    }, (error: any) => {
      _LOGGER.error('Error on getModels.', error);
    }, () => {
      this.loadingFeaturedVehicleModels = false;
    });
  }

  private getMessageCount(): void {
    const logger = _LOGGER.getDerivedContext({methodName: 'getMessageCount'});
    logger.info('Method start.');
    this.usersService.getUserMessagesCount().subscribe((resultOrError: number | BaseAppError) => {
      if ((!resultOrError && (resultOrError !== 0)) || (resultOrError instanceof BaseAppError)) {
        logger.error('getMessageCount resulted in error.');
        logger.errorVerbose('Error:', resultOrError);
      } else {
        logger.debugVerbose('getMessageCount success.');
        this.messageCount = resultOrError;
      }
    }, (error: any) => {
      logger.error('Error on getMessageCount.');
      logger.errorVerbose('Error:', error);
    });
  }

  /* Handlers. */

  /**
   * User selects Model from header dropdown event handler.
   *
   * @param model   The Vehicle Model being selected.
   */
  public onSearchByModel(model: VehicleModelModel): void {
    if (model && !this.disableAll) {
      this.sessionService.setSessionData('publicationsResults', this.publicationsService.getPublicationsByAdvancedSearch(null, null,
        null, model.id, null, null, null, null, null, null, null, null, null));
      const filters = new PublicationFiltersModel();
      filters.vehicleModel = model;
      this.sessionService.setSessionData('publicationsFilters', filters);
      this.onNavClose();
      this.router.navigate(['/results']);
    }
  }

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
    const logger = _LOGGER.getDerivedContext({methodName: 'ngOnInit'});
    logger.info('Method start.');
    this.getFeaturedModels();

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      takeUntil(this.routerChangesSubscriptionIsDestroyed)
    ).subscribe(() => {
      this.onNavClose();
      this.routerChangesSubscriptionIsDestroyed.next();
      this.routerChangesSubscriptionIsDestroyed.complete();
    });

    if (this.authService.isUserLoggedIn()) {
      this.getMessageCount();

      const profileData = this.authService.getUserData();
      if (!profileData.profile) {
        this.loadingProfile = true;
        this.usersService.getUserProfile().subscribe((resultOrError: UserProfileModel | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError)) {
            logger.error('getUserProfile resulted in error.');
            logger.errorVerbose('Error:', resultOrError);
          } else {
            logger.debugVerbose('getUserProfile success.');
            this.profileData = resultOrError;
          }
        }, (error: any) => {
          logger.error('Error on getUserProfile.');
          logger.errorVerbose('Error:', error);
        }, () => {
          this.loadingProfile = false;
        });
      } else {
        this.profileData = profileData.profile;
      }
    }

    this.userLocation = 'Buscando ubicación...';
    const onGetUserLocationFail = (error: any) => {
      logger.error('Cannot get user location.');
      logger.errorVerbose('Error:', error);
      this.userLocation = 'Sin ubicación';
    };
    const onGetUserLocationSuccess = (geocodingData: IGoogleGetReverseGeocodingResponse) => {
      logger.debug('Got user location.');

      let bestResult = geocodingData.results[0];
      const geoPoliticalResults = geocodingData.results.filter(geoResult => geoResult.types.some(t => t === 'political'));
      if (geoPoliticalResults.length > 0) {
        bestResult = geoPoliticalResults[0];
      }
      this.userLocation = bestResult.formatted_address;
    };

    this.googleMapService.getUserPosition().then((userPosition: Position) => {
      logger.debug('Got User position:', userPosition);

      const userLatitude = userPosition.coords.latitude;
      const userLongitude = userPosition.coords.longitude;

      this.googleMapService.getReverseGeocoding(userLatitude, userLongitude)
        .subscribe((resultOrError: IGoogleGetReverseGeocodingResponse | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.results || !resultOrError.results.length) {
            onGetUserLocationFail(resultOrError);
          } else {
            onGetUserLocationSuccess(resultOrError);
          }
        }, (error: any) => {
          onGetUserLocationFail(error);
        });
    }).catch((error: any) => {
      onGetUserLocationFail(error);
    });
  }

  /**
   * Toggles mobile side nav menu.
   */
  public onNavToggle(): void {
    if (this.disableAll) {
      return;
    }

    this.showNav = !this.showNav;
  }

  protected onNavClose(): void {
    this.showNav = false;
  }

  /**
   * User selects a search result.
   */
  public onSelectSearchPublication(result: any): void {
    if (result && (result instanceof PublicationSearchResultModel) && (this.lastSelectedTitle !== result.title)) {
      this.sessionService.setSessionData('publicationsResults', this.publicationsService.getPublicationsByTitle(result.title));
      const filters = new PublicationFiltersModel();
      filters.title = result.title;
      this.lastSelectedTitle = result.title;
      this.sessionService.setSessionData('publicationsFilters', filters);
      this.onNavClose();
      this.router.navigate(['/results']);
    }
  }

  /**
   * Transforms a Publication into a search result.
   */
  public onDisplaySearchPublication(data: PublicationSearchResultModel): string {
    return `${data.title}`;
  }

  /**
   * Searches Publications by user's input.
   */
  public onKeyPressSearchPublications(keyword: string): Observable<PublicationSearchResultModel[]> {
    return Observable.create((observer) => {
      const onFinishSearch = (results: any[]) => {
        observer.next(results);
        observer.complete();
      };

      if (keyword) {
        this.publicationsService.searchPublications(keyword).subscribe((resultOrError: PublicationSearchResultModel[] | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.length) {
            onFinishSearch([]);
          } else {
            onFinishSearch(resultOrError);
          }
        }, () => {
          onFinishSearch([]);
        });
      } else {
        onFinishSearch([]);
      }
    });
  }

  /**
   * User selects LogOut link ("Cerrar sesión").
   */
  public onUserLogOut(): void {
    if (this.disableAll) {
      return;
    }

    this.authService.doLogOut();
    this.onNavClose();
    this.router.navigate(['/login']);
  }

  /**
   * User selects "Usados Certificados Toyota" button.
   */
  public onSelectCertifiedToyotaPublications(): void {
    if (this.disableAll) {
      return;
    }
    this.commonsService.execUsadosCertificadosToyotaSearchTerm();
    this.onNavClose();
  }

  public onErrorUserAvatar(newUrl: string): void {
    if (this.authService.isUserLoggedIn()) {
      this.authService.getUserData().imageUrl = newUrl;
    }
  }

  public onNavAndEmitAdvancedSearch(): void {
    this.onNavClose();
    if (this.router.url === '/home') {
      this.openAdvancedSearch.emit();
    } else {
      this.router.navigate(['/home'], {state: {openSearchModal: true}});
    }
  }

  public onClickIngresar(): void{
    const logger = _LOGGER.getDerivedContext({methodName: 'onClickIngresar'});
    logger.info('Method start.');
    this.userActionService.sendUserAction('registrarse_compra').subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
    this.router.navigate(['/login'], {state: {recoverRegisterData: true}});
  }

  public onClickCrearCuenta(): void{
    const logger = _LOGGER.getDerivedContext({methodName: 'onClickCrearCuenta'});
    logger.info('Method start.');
    this.userActionService.sendUserAction('registrarse_compra').subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
    this.router.navigate(['/register'], {state: {recoverRegisterData: true}});
  }

  public onClickLogoToyota(): void{
    const logger = _LOGGER.getDerivedContext({methodName: 'onClickLogoToyota'});
    logger.info('Method start.');
    this.userActionService.sendUserAction('home').subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
    this.router.navigate(['/home'], {state: {recoverRegisterData: true}});
  }
}
