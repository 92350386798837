import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleMapService } from '../../../../services/google-map/google-map.service';
import { IGoogleGetReverseGeocodingResponse } from '../../../../services/google-map/i-google-get-reverse-geocoding-response';
import { BaseAppError } from '../../../../services/errors/base-app-error';
import { LoggerContext, SimpleLogger } from '../../../../shared/simple-logger.shared';
import { Subject } from 'rxjs';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { AuthService } from '../../../../services/auth/auth.service';
import { UserProfileModel } from '../../../../models/user-profile.model';
import { UsersService } from '../../../../services/users/users.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { CommonsService } from '../../../../services/commons/commons.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SessionModel } from 'src/app/models/session.model';
import { UserActionService } from '../../../../services/user-action/user-action.service';
import { UserActionModel } from '../../../../models/user-action-send.model';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'page-header.component.ts',
  className: 'PageHeaderComponent',
  tagName: 'COMPONENTS'
});
_LOGGER.debugVerbose('Loaded.');


const SCROLL_OFFSET_LIMIT = 100;

/**
 * Page Header component to place top of most views.
 */
@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  animations: [
    trigger('sideNavMenuDisplay', [
      state('closed', style({
        width: '0px'
      })),
      state('opened', style({
        width: '100%',
        height: '100%'
      })),
      transition('closed => opened', [
        animate('200ms')
      ]),
      transition('opened => closed', [
        animate('200ms')
      ])
    ])
  ]
})
export class PageHeaderComponent implements OnInit {
  public routerChangesSubscriptionIsDestroyed = new Subject<any>();

  @Input() disableAll = false;

  public showNav = false;
  protected userLocation: string;

  protected loadingProfile: boolean;
  public messageCount: number;
  public profileData: UserProfileModel;

  @Input() noScrollLookup = false;
  public isScrollOffsetPassed: boolean;

  constructor(
    private googleMapService: GoogleMapService,
    private router: Router,
    private authService: AuthService,
    private usersService: UsersService,
    private viewportScroller: ViewportScroller,
    private sessionService: SessionService,
    private commonsService: CommonsService,
    private userActionService: UserActionService
  ) {
  }

  /* Presentation logic. */

  public get isUserLoggedIn(): boolean {
    return this.authService.isUserLoggedIn();
  }

  public get userAvatarUrl(): string {
    if (this.authService.isUserLoggedIn()) {
      return this.authService.getUserData().imageUrl;
    }
    return null;
  }

  private getMessageCount(): void {
    const logger = _LOGGER.getDerivedContext({methodName: 'getMessageCount'});
    logger.info('Method start.');

    this.usersService.getUserMessagesCount().subscribe((resultOrError: number | BaseAppError) => {
      if ((!resultOrError && (resultOrError !== 0)) || (resultOrError instanceof BaseAppError)) {
        logger.error('getMessageCount resulted in error.');
        logger.errorVerbose('Error:', resultOrError);
      } else {
        logger.debugVerbose('getMessageCount success.');
        this.messageCount = resultOrError;
      }
    }, (error: any) => {
      logger.error('Error on getMessageCount.');
      logger.errorVerbose('Error:', error);
    });
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
    const logger = _LOGGER.getDerivedContext({methodName: 'ngOnInit'});
    logger.info('Method start.');

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      takeUntil(this.routerChangesSubscriptionIsDestroyed)
    ).subscribe(() => {
      this.onNavClose();
      this.routerChangesSubscriptionIsDestroyed.next();
      this.routerChangesSubscriptionIsDestroyed.complete();
    });

    if (this.authService.isUserLoggedIn()) {
      this.getMessageCount();

      const profileData = this.authService.getUserData();
      if (!profileData.profile) {
        this.loadingProfile = true;
        this.usersService.getUserProfile().subscribe((resultOrError: UserProfileModel | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError)) {
            logger.error('getUserProfile resulted in error.');
            logger.errorVerbose('Error:', resultOrError);
          } else {
            logger.debugVerbose('getUserProfile success.');
            this.profileData = resultOrError;
          }
        }, (error: any) => {
          logger.error('Error on getUserProfile.');
          logger.errorVerbose('Error:', error);
        }, () => {
          this.loadingProfile = false;
        });
      } else {
        this.profileData = profileData.profile;
      }
    }

    let session_token = this.sessionService.getSessionData('session_token'); 
    if (session_token == null) {
      this.sendUserSessionData();
    }

    this.userLocation = 'Buscando ubicación...';
    const onGetUserLocationFail = (error: any) => {
      logger.error('Cannot get user location.');
      logger.errorVerbose('Error:', error);
      this.userLocation = 'Sin ubicación';
    };
    const onGetUserLocationSuccess = (geocodingData: IGoogleGetReverseGeocodingResponse) => {
      logger.debug('Got user location.');

      let bestResult = geocodingData.results[0];
      const geoPoliticalResults = geocodingData.results.filter(geoResult => geoResult.types.some(t => t === 'political'));
      if (geoPoliticalResults.length > 0) {
        bestResult = geoPoliticalResults[0];
      }
      this.userLocation = bestResult.formatted_address;
    };

    this.googleMapService.getUserPosition().then((userPosition: Position) => {
      logger.debug('Got User position:', userPosition);

      const userLatitude = userPosition.coords.latitude;
      const userLongitude = userPosition.coords.longitude;

      this.googleMapService.getReverseGeocoding(userLatitude, userLongitude)
        .subscribe((resultOrError: IGoogleGetReverseGeocodingResponse | BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError) || !resultOrError.results || !resultOrError.results.length) {
            onGetUserLocationFail(resultOrError);
          } else {
            onGetUserLocationSuccess(resultOrError);
          }
        }, (error: any) => {
          onGetUserLocationFail(error);
        });
    }).catch((error: any) => {
      onGetUserLocationFail(error);
    });
  }

  protected sendUserSessionData(): void {
    const logger = _LOGGER.getDerivedContext({methodName: 'sendUserSessionData'});
    logger.info('Method start.');
    this.sessionService.sendSessionData().subscribe((resultOrError: SessionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send session Error', resultOrError);
      } else {
        logger.debugVerbose('Send session success');
      }
    });
  }


  /**
   * Toggles mobile side nav menu.
   */
  public onNavToggle(): void {
    if (this.disableAll) {
      return;
    }

    this.showNav = !this.showNav;
  }

  public onClickIngresarMobile(): void {
    this.onClickIngresar();
    this.onNavToggle();
  }

  protected onNavClose(): void {
    this.showNav = false;
  }

  /**
   * User selects LogOut link ("Cerrar sesión").
   */
  public onUserLogOut(): void {
    if (this.disableAll) {
      return;
    }

    this.authService.doLogOut();
    this.onNavClose();
    this.router.navigate(['/login']);
  }

  public onErrorUserAvatar(newUrl: string): void {
    if (this.authService.isUserLoggedIn()) {
      this.authService.getUserData().imageUrl = newUrl;
    }
  }

  public onScroll(event: Event): void {
    if (this.noScrollLookup) {
      return;
    }
    let scrollOffset = 0;
    if (!!event.srcElement && !!event.srcElement.children && !!event.srcElement.children.length) {
      scrollOffset = event.srcElement.children[0].scrollTop || scrollOffset;
    }
    this.isScrollOffsetPassed = (scrollOffset >= SCROLL_OFFSET_LIMIT);
  }

  public onClickHelp(): void {
    this.viewportScroller.scrollToAnchor('help-anchor');
  }

  public onBuy(): void {
    const logger = _LOGGER.getDerivedContext({methodName: 'onBuy'});
    logger.info('Method start.');
    this.userActionService.sendUserAction('comprar').subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
    this.commonsService.execAllPublicationsSearchTerm();
  }

  public onClickSellToyota(): void {
    const logger = _LOGGER.getDerivedContext({methodName: 'onClickSellToyota'});
    logger.info('Method start.');
    this.userActionService.sendUserAction('vender').subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
    this.router.navigate(['/vender'], {state: {recoverRegisterData: true}});
  }

  public onClickIngresar(): void{
    const logger = _LOGGER.getDerivedContext({methodName: 'onClickIngresar'});
    logger.info('Method start.');
    this.userActionService.sendUserAction('registrarse_directo').subscribe((resultOrError: UserActionModel | BaseAppError) => {
      if(!resultOrError || (resultOrError instanceof BaseAppError)) {
        logger.errorVerbose('Send User Action Error', resultOrError);
      } else {
        logger.debugVerbose('Send User Action success');
      }
    });
    this.router.navigate(['/login'], {state: {recoverRegisterData: true}});
  }

  public onClickLogoToyota(): void{
    const logger = _LOGGER.getDerivedContext({methodName: 'onClickLogoToyota'});
    logger.info('Method start.');
    if (this.router.url !== '/home') {
      this.userActionService.sendUserAction('home').subscribe((resultOrError: UserActionModel | BaseAppError) => {
        if(!resultOrError || (resultOrError instanceof BaseAppError)) {
          logger.errorVerbose('Send User Action Error', resultOrError);
        } else {
          logger.debugVerbose('Send User Action success');
        }
      });
      this.router.navigate(['/home'], {state: {recoverRegisterData: true}});
    }
  }
}
