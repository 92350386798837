import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'linkedin.service.ts',
  className: 'LinkedinService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');

const _CONFIG = EnvironmentManager.getInstance().getConfig();

/**
 * Linkedin Service.
 */
@Injectable({
  providedIn: 'root'
})
export class LinkedinService {
  
  constructor() {
  }
  
  openAuthTab() {
    let linkedinLoginURI = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code';
    linkedinLoginURI += `&client_id=${_CONFIG.linkedinClientId}`;
    const redirectURI = `${window.location.origin}/auth/linkedin/callback/`;
    linkedinLoginURI += `&redirect_uri=${encodeURIComponent(redirectURI)}`;
    linkedinLoginURI += '&scope=r_liteprofile%20r_emailaddress';

    window.open(linkedinLoginURI, '_self');
  }
  
}
