import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { EnvironmentManager } from './app/shared/environment-manager.shared';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LoggerContext, SimpleLogger } from './app/shared/simple-logger.shared';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'main.ts',
  className: '',
  methodName: '',
  tagName: 'MAIN'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();
if (_CONFIG.production) {
  enableProdMode();
  _LOGGER.debugVerbose('Enabled Production mode.');
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

_LOGGER.debugVerbose('Bootstrapped.');
