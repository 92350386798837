import { IEnvironmentConfiguration } from '../app/shared/environment-manager.shared';
import { LOGLEVEL } from '../app/shared/simple-logger.shared';


export const environment: IEnvironmentConfiguration = {
  frontDomains: ['www.toyotausados.com.ar'],
  apiBaseURL: 'https://api.tcfautos.com.ar/index.php',
  brokerSegurosURL: 'https://www.toyotabroker.com.ar/ToyotaSom/',
  ventaUsadosURL: 'https://www.toyota.com.ar/venta-usados',
  appId: 2,
  publicationsImagesContextUrl: 'https://resources.tcfautos.com.ar/auto_images',
  profilesImagesContextUrl: 'https://resources.tcfautos.com.ar/user_images',
  dealerImagesContextUrl: 'https://resources.tcfautos.com.ar/dealer_images',
  googleMapAK: 'AIzaSyDsnM-k76MHzztHTcGJuwBS_Nvb6wMRMxE',
  googleAnalyticsId: 'UA-145476640-1', // Format: 'UA-XXXXX-Y'.
  logLevel: LOGLEVEL.NONE,
  widgetCotizadorUrl: 'https://widgetcotizador.tcfautos.com.ar/sdk/sdk.js',
  widgetCotizadorEnvName: 'PROD',
  widgetCotizadorTheme: 'tcfautos',
  defaultOperationTypeCode: 'RE',
  facebookConfig: {
    appId: '681761016377309',
    hash: '922c541513399a40dfba4c6137454c91',
    version: 'v2.6'
  },
  linkedinClientId: '77afduyxtja3k9',
  googleAuthConfig: {
    clientId: '823949072627-769p880beq6f731su74nf9v9h3qi2ss9.apps.googleusercontent.com',
    scopes: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/plus.me https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/userinfo.profile'
  }
};
