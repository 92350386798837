import { UserMessageModel } from './user-message.model';
import { UserMessageCarModel } from './user-message-car.model';
import { UserMessageDealerModel } from './user-message-dealer.model';


export class UserMessagesModel {
  public id: number;
  public publicationId?: string;
  public publicationState?: string;
  public messageList: UserMessageModel[];
  public unreadCount: number;
  public car?: UserMessageCarModel;
  public dealer: UserMessageDealerModel;
  public createdAt: Date;
  public isCertificado?: boolean;
  public title?: string;
  public pedidoPublicationId?: string;

  public constructor(id: number, publicationId: string, publicationState: string, messageList: UserMessageModel[],
                     unreadCount: number, car: UserMessageCarModel, dealer: UserMessageDealerModel, createdAt: Date,
                     isCertificado: boolean, title?: string, pedidoPublicationId?: string) {
    this.id = id;
    this.publicationId = publicationId;
    this.publicationState = publicationState;
    this.messageList = messageList;
    this.unreadCount = unreadCount;
    this.car = car;
    this.dealer = dealer;
    this.createdAt = createdAt;
    this.isCertificado = isCertificado;
    this.title = title;
    this.pedidoPublicationId = pedidoPublicationId;
  }
}
