import { Component, OnInit } from '@angular/core';
import { LoggerContext, SimpleLogger } from 'src/app/shared/simple-logger.shared';
import { UserMessagesModel } from 'src/app/models/user-messages.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ErrorsService } from 'src/app/services/errors/errors.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { BaseAppError } from 'src/app/services/errors/base-app-error';
import { ViewportScroller } from '@angular/common';
import { UserMessageModel } from 'src/app/models/user-message.model';
import { SessionService } from '../../../../services/session/session.service';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'list-messages-page.component.ts',
  className: 'ListMessagesPageComponent',
  tagName: 'PAGES'
});
_LOGGER.debugVerbose('Loaded.');

/**
 * User Messages List page
 */
@Component({
  selector: 'app-list-messages-page',
  templateUrl: './list-messages-page.component.html',
  styleUrls: ['./list-messages-page.component.scss']
})
export class ListMessagesPageComponent implements OnInit {
  /** Data */
  public messageList: UserMessagesModel[];
  public messageCount: number;
  protected loadingMessages: boolean;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private authService: AuthService,
    private errorsService: ErrorsService,
    private modalsService: ModalsService,
    private viewportScroller: ViewportScroller,
    private sessionService: SessionService,
    private route: ActivatedRoute
  ) {
  }

  /**
   * Navs to Login Page.
   */
  private goToLoginPage(redirectToMessageId?: number): void {
    this.sessionService.setSessionData('redirectAfterLogin', '/list-messages');
    if (!!redirectToMessageId) {
      this.sessionService.setSessionData('redirectAfterLoginQuery', {viewMessage: redirectToMessageId});
    }
    this.router.navigate(['/login']);
  }

  private onError(error: any, navigate: boolean = false): void {
    const __logger = _LOGGER.getDerivedContext({methodName: 'onSignUpFail'});
    __logger.info('Method start.');

    const appError: BaseAppError = this.errorsService.getAppError(error);
    __logger.error('Error:', appError.getMessage());

    if (appError.getCode() === ErrorsService.getApiHttpErrorCode(401)) {
      this.authService.expireSession();
      navigate = true;
    }

    this.modalsService.showErrorModal(appError.getMessage()).then(modal => {
      modal.result.then(result => {
        __logger.debug('Error modal closed with result:', result);
      }).catch(reason => {
        __logger.debug('Error modal dismissed with reason:', reason);
      }).finally(() => {
        this.viewportScroller.scrollToAnchor('app-main-header');
        if (navigate) {
          this.goToLoginPage();
        }
      });
    });
  }

  public ngOnInit(): void {
    const __logger = _LOGGER.getDerivedContext({methodName: 'ngOnInit'});
    __logger.info('Method start.');
    const viewMessageId = parseInt(this.route.snapshot.queryParamMap.get('viewMessage') || '0', 10);
    // Get User's profile.
    if (this.authService.isUserLoggedIn()) {
      this.loadingMessages = true;
      const loadingModal = this.modalsService.showLoadingModal('Obteniendo mensajes...');
      this.usersService.getUserMessages().subscribe((resultOrError: UserMessagesModel[] | BaseAppError) => {
        if (!resultOrError || (resultOrError instanceof BaseAppError)) {
          __logger.error('getUserMessages resulted in error.');
          __logger.errorVerbose('Error:', resultOrError);
          this.onError(resultOrError);
        } else {
          __logger.debugVerbose('getUserMessages success.');
          this.messageList = resultOrError;
          this.messageCount = resultOrError.length;
          if (!!viewMessageId) {
            const viewMessage = this.messageList.find(m => m.id === viewMessageId);
            if (!!viewMessage) {
              this.doMessageRead(viewMessage.messageList);
              this.router.navigate(['/messages'], {state: {message: viewMessage}});
            }
          }
        }
      }, (error: any) => {
        __logger.error('Error on getUserProfile.');
        __logger.errorVerbose('Error:', error);
        this.onError(error);
      }, () => {
        this.loadingMessages = false;
        loadingModal.then(modal => {
          modal.dismiss('done');
        });
      });
    } else {
      this.goToLoginPage(viewMessageId);
    }
  }

  public doMessageRead(messageList: UserMessageModel[]) {
    const __logger = _LOGGER.getDerivedContext({methodName: 'doMessageRead'});
    __logger.info('Method start.');
    messageList.forEach((message: UserMessageModel) => {
      if (!message.esRespuesta) {
        this.usersService.readUserMessage(message.id).subscribe((resultOrError: BaseAppError) => {
          if (!resultOrError || (resultOrError instanceof BaseAppError)) {
            __logger.error('readUserMessage resulted in error.');
            __logger.errorVerbose('Error:', resultOrError);
            this.onError(resultOrError);
          } else {
            __logger.debugVerbose('readUserMessage success.');
          }
        }, (error: any) => {
          __logger.error('Error on readUserMessage.');
          __logger.errorVerbose('Error:', error);
          this.onError(error);
        });
      }
    });
  }
}
