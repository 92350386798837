import { PublicationAmenityModel } from "./publication-amenity.model";


export class PublicationAmenityCategoryModel {
  public id: number;
  public name: string;
  public amenities: PublicationAmenityModel[];

  constructor(id: number, name: string, amenities: PublicationAmenityModel[]) {
    this.id = id;
    this.name = name;
    this.amenities = amenities;
  }
}
