import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainRoutingModule } from './main-routing.module';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { NumericFormatterDirective } from "../../directives/numeric-formatter/numeric-formatter.directive";


@NgModule({
  declarations: [
    AlertModalComponent,
    ErrorModalComponent,
    LoadingModalComponent,
    SuccessModalComponent,
    NumericFormatterDirective
  ],
  imports: [
    CommonModule,
    MainRoutingModule
  ],
  exports: [
    NumericFormatterDirective
  ]
})
export class MainModule {
}
