import { Injectable } from '@angular/core';
import { LoggerContext, SimpleLogger } from '../../shared/simple-logger.shared';
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PersistenceService } from '../persistence/persistence.service';
import { ErrorsService } from '../errors/errors.service';
import { Observable, of } from 'rxjs';
import { BaseAppError } from '../errors/base-app-error';
import { VehicleVersionModel } from '../../models/vehicle-version.model';
import { IApiCommonResponse } from '../i-api-common-response';
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { IVersionsGetVersionsResponseVersionItem } from './i-versions-get-versions-response-version-item';


const _LOGGER: LoggerContext = SimpleLogger.getInstance().getContext({
  fileName: 'versions.service.ts',
  className: 'VersionsService',
  methodName: '',
  tagName: 'SERVICES'
});
_LOGGER.debugVerbose('Loaded.');


const _CONFIG = EnvironmentManager.getInstance().getConfig();


const _CACHE_CONFIG = {
  GET_VERSIONS: {
    KEY: 'get_versions',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  }
};


/**
 * Versions Service.
 */
@Injectable({
  providedIn: 'root'
})
export class VersionsService {
  private baseURL = `${_CONFIG.apiBaseURL}/toyotausados/versiones`;
  private baseURLLegacy = `${_CONFIG.apiBaseURL}/version`;

  /** Cache data. */
  private cacheGetVersions: { [key: string]: Observable<VehicleVersionModel[] | BaseAppError> } = {};

  constructor(
    private http: HttpClient,
    private persistenceService: PersistenceService,
    private errorsService: ErrorsService
  ) {
  }

  /**
   * Gets all vehicle Versions by a given vehicle Model.
   */
  public getVersions(vehicleModel: VehicleVersionModel): Observable<VehicleVersionModel[] | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getProvinces'
    });
    __logger.info('Method start.');

    const __CACHE_KEY_SUFFIX = `_${vehicleModel.id}`;

    const endpointURL = `${this.baseURL}?modelo_id=${vehicleModel.id}`;
    __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

    return this.http.get<IApiCommonResponse<IVersionsGetVersionsResponseVersionItem[]>>(endpointURL, { observe: 'response' })
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<IVersionsGetVersionsResponseVersionItem[]>>) => {
            __logger.debug('API response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<IApiCommonResponse<IVersionsGetVersionsResponseVersionItem[]>>) => response.body.data.map(
            vVersion => new VehicleVersionModel(vVersion.id, vVersion.ver_descripcion_s, vVersion.id_fu))),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );
        
  }

  /**
   * Gets all vehicle Versions by a given vehicle Model and Year.
   */
  public getVersionsByYearAndModel(vehicleModel: VehicleVersionModel, year: string): Observable<VehicleVersionModel[] | BaseAppError> {
    const __logger = _LOGGER.getDerivedContext({
      methodName: 'getVersions'
    });
    __logger.info('Method start.');

    const __CACHE_KEY_SUFFIX = `_${vehicleModel.id}_${year}`;

    // Check cache.
    if (this.persistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_VERSIONS.KEY + __CACHE_KEY_SUFFIX, _CACHE_CONFIG.GET_VERSIONS.AGE)) {
      const endpointURL = `${this.baseURLLegacy}/${vehicleModel.id}/year/${year}`;
      __logger.debug('Calling API.', 'Endpoint:', `GET ${endpointURL}`);

      this.cacheGetVersions[__CACHE_KEY_SUFFIX] = this.http.get<IApiCommonResponse<IVersionsGetVersionsResponseVersionItem[]>>(endpointURL, { observe: 'response' })
        .pipe(
          // Log operation.
          tap((response: HttpResponse<IApiCommonResponse<IVersionsGetVersionsResponseVersionItem[]>>) => {
            __logger.debug('API response:', response);
          }),
          // Parse response.
          map((response: HttpResponse<IApiCommonResponse<IVersionsGetVersionsResponseVersionItem[]>>) => response.body.data.map(
            vVersion => new VehicleVersionModel(vVersion.id, vVersion.ver_descripcion_s, vVersion.id_fu))),
          // Store in cache.
          tap((data: VehicleVersionModel[]) => {
            this.persistenceService.store(_CACHE_CONFIG.GET_VERSIONS.KEY + __CACHE_KEY_SUFFIX, data);
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );
    } else {
      const cacheData: VehicleVersionModel[] = this.persistenceService.retrieve(_CACHE_CONFIG.GET_VERSIONS.KEY + __CACHE_KEY_SUFFIX, _CACHE_CONFIG.GET_VERSIONS.AGE);
      this.cacheGetVersions[__CACHE_KEY_SUFFIX] = of(cacheData)
        .pipe(
          // Log operation.
          tap((data: VehicleVersionModel[]) => {
            __logger.debug('Cache response:', data);
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorsService.getAppError(error)))
        );
    }

    return this.cacheGetVersions[__CACHE_KEY_SUFFIX];
  }
}
